export const filterCodaResponse = (items, columnIndex) => {
  let res = {};
  if (items.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      if (items[i].values["c-Ewo2UKVmj7"] === columnIndex) {
        res = items[i].values;
      }
    }
  }
  return res;
};

export const filterAlternativeResponse = (items, columnIndex) => {
  let res = {};
  let paleo = {};
  if (items.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      if (items[i].values["c-MG_5CWBJaV"] == columnIndex) {
        res = items[i].values;
      }
      if (items[i].values["c-zfeNhVihTT"] == 8) {
        paleo = items[i].values;
      }
    }
  }
  return [res, paleo];
};
