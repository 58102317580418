import React from "react";
import { Doughnut } from "react-chartjs-2";


/*
   const data = {
     colors: ["#377dff", "#00c9db", "#e7eaf3"],
     legends: ["Sales", "Orders"],
     "labels": ["USD", "USD", "USD"],
     "datasets": [{
       "data": [45, 25, 30],
       "backgroundColor": ["#377dff", "#00c9db", "#e7eaf3"],
       "borderWidth": 5,
       "hoverBorderColor": "#fff"
     }]
   };
 */

const DoughnutChart = ({ data, height, half }) => {

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    "cutoutPercentage": 80,
    "tooltips": {
      "postfix": "k",
      "hasIndicator": true,
      "mode": "index",
      "intersect": false
    },
    "hover": {
      "mode": "nearest",
      "intersect": true
    }
  };

  if (half) {
    options.rotation = 1 * Math.PI;
    options.circumference = 1 * Math.PI;
  }

  return (
    <div className="chart chart-sm" style={{ height: height || "100px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
