import MiniDashboard from "./MiniDashboard";

import avatar1 from "../../assets/imgs/mockups/dp/marque-dyson-758x758.jpeg";
import avatar2 from "../../assets/imgs/mockups/dp/walmart-logo-24.jpeg";
import avatar3 from "../../assets/imgs/mockups/dp/isotipo_nike-300x300-1.png";
import avatar4 from "../../assets/imgs/mockups/dp/nestle-eps-vector-logo.png";
import avatar5 from "../../assets/imgs/mockups/dp/brand (1).gif";



const products = [
  {
    name: "Molekyl Candlelight 2",
    type: "Decoration",
    price: "$120",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/10505-13-8_Jewel_Vase_-_Glass_Amber_1194x1194.jpg?v=1629993444"
  },
  {
    name: "Ceiling Lamp Spider",
    type: "Decoration",
    price: "$656",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/ceiling-lamp-spider-5-still-angled-arms-serge-mouille_1_100x.jpg?v=1645399590"
  },
  {
    name: "Sweetly SGS",
    type: "Chair",
    price: "$434",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/Sweetly-SGS-Nero-Rosa-Stool34F_100x.jpg?v=1644525841"
  },
  {
    name: "Greta Scr Arm",
    type: "Chair",
    price: "$727.50",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/GRETAMARGHERITA_0000s_0014_greta-scr-arm_100x.jpg?v=1644525537"
  },
  {
    name: "Accento Allure S",
    type: "Chair",
    price: "$727.50",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/ALLURE_0000s_0000_Allure-S_100x.jpg?v=1644516105https://cdn.shopify.com/s/files/1/0247/4865/4643/products/510a482ab02f7c28b01dd59dd20061201c573376f123e274b2e2ad5b34ab2166a12f26380e4434f46f165ce39d85399d57d8de74004eb8d6d681323e1f68a6028f59343bc84a9568c1f144e69eba229ef2bc895c4057260c93f4783_1278x853.jpg?v=1629996355"
  }

]



const Dashboard8 = () => {
  return (
    <MiniDashboard>
      <div className="dashboard8 row mt-3 gx-2 aic position-relative">

        <div className="col-md-8">
          <div className="border p-2 rounded mx-md-auto">
            <div className="table-responsive-sm overflow-auto">
              <table className="table mb-0">
                <tbody>
                  {products.map((e, i) => <tr key={i} className="table-align-middle text-gray-600">
                    <td>
                      <div className="d-flex aic">
                        <img className="avatar avatar-sm avatar-circle me-2" src={e.image} alt="Image Description"></img>
                        <div>
                          <small className=" text-gray-400">Name</small>
                          <div className="lc2">{e.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="table-text-center">
                      <small className=" text-gray-400">Price</small>
                      <div>{e.price}</div>
                    </td>
                    <td>
                      <small className=" text-gray-400">Type</small>
                      <div className="lc1">{e.type}</div>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>

          </div>
        </div>

        {
          [0, 1].map(e => <div key={e} className="checkout-card card position-absolute border">
            <div className="d-flex aic p-2 border-bottom">
              <div className="flex-shrink-0 svg-icon svg-icon-sm">
                <i className="bi bi-x bold h5"></i>
              </div>
              <div className="flex-grow-1 ms-2">Checkout</div>
            </div>

            <div className="d-flex aic jcsb p-2">
              <div>
                <div className="fw-bold">{products[e].name}</div>
                <div className="fs-08">{products[e].type}</div>
                <div className="fs-08">{products[e].price}</div>
              </div>
              <div>
                <img className="avatar avatar-md me-2" src={products[e].image} alt="Image Description"></img>
              </div>
            </div>

            <div className="bg-primary text-white p-2 rounded text-center m-2 mt-3">Checkout</div>
          </div>)
        }
      </div>
    </MiniDashboard>
  );
};

export default Dashboard8;