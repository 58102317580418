import React from "react";

const Products = () => {
  return (
    <>
      <div style={{ height: "600px" }}>Products</div>
    </>
  );
};

export default Products;