import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";

import "swiper/css";
// import "swiper/css/navigation";


import img1 from "../../assets/SVG-Graphics/combined-order.svg";
import img2 from "../../assets/SVG-Graphics/get-paid-single-currency.svg";

const FeatureSlider = () => {
    return (<>
        <Swiper
            spaceBetween={30}
            slidesPerView={"auto"}
            loop={true}
            navigation={false}
            pagination={true}
            mousewheel={true}
            modules={[Navigation, Autoplay]}
            autoplay={{
                delay: 5500,
                disableOnInteraction: false,
            }}
            className="slider"
        >
            <SwiperSlide className="cell-600 ">
                <div className="">
                    <div className="">
                        <img className="img-fluid w-100" src={img1} />
                    </div>
                    <div className="p-3 ps-0 fs-09  mt-3 mr-3">
                        <h5 className="m-0">Subscription 1</h5>
                        <div className="mb-2">Enable your user to create flexible subscription and billing</div>
                        <a href="/test" className="fs-09 bold">Link</a>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className="cell-600">
                <div className="">
                    <div className="aspect-60-25">
                        <div>
                            <img className="img-fluid w-100" src={img2} />
                        </div>
                    </div>
                    <div className="p-3 ps-0 fs-09  mt-3">
                        <h5 className="m-0">Subscription 2</h5>
                        <div className="mb-2">Enable your user to create flexible subscription and billing</div>
                        <a href="/test" className="fs-09 bold">Link</a>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className="cell-600">
                <div className="">
                    <div className="aspect-60-25">
                        <div>
                            <img className="img-fluid w-100" src={img1} />
                        </div>
                    </div>
                    <div className="p-3 ps-0 fs-09  mt-3">
                        <h5 className="m-0">Subscription 3</h5>
                        <div className="mb-2">Enable your user to create flexible subscription and billing</div>
                        <a href="/test" className="fs-09 bold">Link</a>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className="cell-600">
                <div className="">
                    <div className="aspect-60-25">
                        <div>
                            <img className="img-fluid w-100" src={img2} />
                        </div>
                    </div>
                    <div className="p-3 ps-0 fs-09  mt-3">
                        <h5 className="m-0">Subscription 4</h5>
                        <div className="mb-2">Enable your user to create flexible subscription and billing</div>
                        <a href="/test" className="fs-09 bold">Link</a>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </>);
};

export default FeatureSlider;
