import ContentLoader from "react-content-loader";


const options = {
    speed: 2,
    className: "w-100 bg-white",
    backgroundColor: "#e0e4e9",
    foregroundColor: "#e0e4e978",
}


export const Skeleton = ({ type = "", count = 1 }) => {
    if (type === "Integration") {
        return <Integration count={"o".repeat(count).split("")} />
    }
    if (type === "TableRow") {
        return <TableRow count={"o".repeat(count).split("")} />
    }
    if (type === "AvatarName") {
        return <AvatarName count={"o".repeat(count).split("")} />
    }

    return <></>
}


export const Integration = ({ count }) => (
    <div className="row mb-5 mb-md-0">
        {count.map((e, i) => <div key={i} className="col-sm-6 col-lg-4 mb-3 mb-sm-5 mb-lg-7">
            <div className="card p-4">
                <ContentLoader
                    viewBox="0 0 100 95"
                    {...options}
                >
                    <circle cx="20" cy="17" r="11" />
                    <path d="M 8.833 35.333 h 40.333 v 7.5 H 8.833 z M 8.833 57.667 h 79.5 v 5.5 h -79.5 z M 8.833 65.834 h 69.333 v 5.5 H 8.833 z M 8.833 74.501 h 79.5 v 5.5 h -79.5 z M 8.833 83.168 h 35.833 v 5.5 H 8.833 z" />
                </ContentLoader>
            </div>
        </div>)
        }
    </div>
);



export const TableRow = ({ count }) => (
    <div className="bg-white py-2">
        {
            count.map((e, i) => (
                <div key={i}>
                    <ContentLoader
                        viewBox="0 0 100 5"
                        {...options}
                    >
                        <path d="M 26.063 0 h 22.313 v 1.65 H 26.063 z M 1.25 0 h 22.313 v 1.65 H 1.25 z M 76.063 0 h 22.688 v 1.65 H 76.063 z M 50.875 0 h 22.688 v 1.65 H 50.875 z" />
                    </ContentLoader>
                </div>
            ))
        }
    </div>
);





export const AvatarName = () => (
    <div>
        <ContentLoader
            viewBox="0 0 200 50"
            {...options}
        >
            <circle cx="23" cy="26" r="23" />
            <path d="M 56.883 11.499 h 88.503 v 14.263 H 56.883 z M 56.711 31.605 H 174.6 v 9.28 H 56.711 z" />
        </ContentLoader>
    </div>
);