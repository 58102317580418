import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard1 from "../../components/cards/Dashboard1";
import StoreExample from "../../components/cards/StoreExample";

import img8 from "../../assets/imgs/website-images/img52.jpg";

const Product = () => {
  return (
    <>
      <div className="bg-soft-info">
        <div className="container content-space-1">
          <div className="w-lg-75 mx-lg-auto">
            <div className="row align-items-md-center">
              <div className="col-md-6 mb-5 mb-md-0">
                <h1 className="h3">Online store</h1>
                <ul className="list-inline list-separator">
                  <li className="list-inline-item">
                    Start selling in minutes with your powerful, easy-to-use
                    e-commerce store.{" "}
                  </li>
                </ul>
              </div>
              {/* End Col */}
              <div className="col-md-6">
                <div className="d-md-flex justify-content-md-end">
                  <a
                    className="js-apply-tab btn btn-primary"
                    target={"_blank"}
                    href="https://accounts.paleo-store.com/auth/sign-up"
                  >
                    Get started for free
                  </a>
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>
      </div>

      <ContentMarkupSection
        childOrder="1"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 banner={img8} />
      </ContentMarkupSection>

      <div className="bg-soft-info">
        {/* Icon Blocks */}
        <div className="container content-space-1">
          <div className="row">
            <div className="col-md-6 mb-5 mb-md-0">
              <div className="me-md-6">
                <span className="svg-icon svg-icon-lg text-primary mb-3">
                  <h5>A powerful online store</h5>
                </span>
                <p>
                  Every Paleo organization can open a robust public online store
                  in minutes; no setup required. The store can be used to sell
                  B2B and B2C. 
                </p>
              </div>
            </div>
            {/* End Col */}
            <div className="col-md-6 mb-5 mb-md-0">
              <div className="me-md-6">
                <span className="svg-icon svg-icon-lg text-primary mb-3">
                  <h5>Set detailed discount programs for partners</h5>
                </span>
                <p>
                   Create detailed, flexible trade discount plans for your
                  merchant partners. 
                </p>
              </div>
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}

          <div className="row mt-5">
            <div className="col-md-6 mb-5 mb-md-0">
              <div className="me-md-6">
                <span className="svg-icon svg-icon-lg text-primary mb-3">
                  <h5>Set custom MOQ's and increments</h5>
                </span>
                <p>
                  Set custom minimum order and increment quantities for products
                  and catalogs. 
                </p>
              </div>
            </div>
            {/* End Col */}
            <div className="col-md-6 mb-5 mb-md-0">
              <div className="me-md-6">
                <span className="svg-icon svg-icon-lg text-primary mb-3">
                  <h5>Detailed product pages</h5>
                </span>
                <p>
                  Present your products in the best possible way on our clean,
                  detailed product pages and display videos, customization
                  options, detailed product information, packing information,
                  q&a, and much more.
                </p>
              </div>
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
        {/* End Icon Blocks */}
      </div>

      <ContentMarkupSection
        childOrder="0"
        bg="bg-white"
        subtitle="Start selling"
        heading="Full control over your product data"
        childOrder="1"
        description="Product data lives inside of the powerful Paleo universal database. You can create custom layouts for your product catalogs depending on your industry and products, and add your own data columns for more flexibility."
        list={[
          {
            img: <Icon1 />,
            text: "Add any data attributes to your products",
          },
          {
            img: <Icon2 />,
            text: "Easily share your catalog data with your partners",
          },
          {
            img: <Icon3 />,
            text: "Sell products B2B and B2C",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Combine and customize"
        heading="In-depth commerce analytics"
        description="Access your commerce data at all times with Paleo's rich analytical features. You can create your chart views with over 130 chart components."
        list={[
          {
            img: <Icon1 />,
            text: "Create custom analtyic dashboards",
          },
          {
            img: <Icon2 />,
            text: "Analyze data on over 130 chat components",
          },
          {
            img: <Icon3 />,
            text: "Access industry related data ",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <StoreExample />
    </>
  );
};

export default Product;
