/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import googleIcon from '../../assets/svg/brands/google-icon.svg';
import gitlabGray from '../../assets/svg/brands/gitlab-gray.svg';
import fitbitGray from '../../assets/svg/brands/fitbit-gray.svg';
import flowGray from '../../assets/svg/brands/flow-xo-gray.svg';
import layerGray from '../../assets/svg/brands/layar-gray.svg';
import { NavLink } from "react-router-dom";

const SignUpModal = () => {

    return (
        <div className="modal fade" id="signupModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-close">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-sm-5">
                        <div id="signupModalFormLogin" style={{display: "none", opacity: 0}}>
                            <div className="text-center mb-7">
                                <h4>Log in</h4>
                                <p>Don't have an account yet?
                                    <a className="js-animation-link" href="#" role="button" data-hs-show-animation-options='{ "targetSelector": "#signupModalFormSignup", "groupName": "idForm" }'>Sign up here</a>
                                </p>
                            </div>

                            <div className="d-grid gap-2">
                                <a className="btn btn-white btn-lg" href="#">
                                    <span className="d-flex justify-content-center align-items-center">
                                        <img className="avatar avatar-xss me-2" src={googleIcon} alt="Google" />
                                            Log in with Google
                                    </span>
                                </a>

                                <a className="js-animation-link btn btn-primary btn-lg" href="#" data-hs-show-animation-options='{ "targetSelector": "#signupModalFormLoginWithEmail", "groupName": "idForm" }'>Log in with Email</a>
                            </div>
                        </div>
                        <div id="signupModalFormLoginWithEmail" style={{display: "none", opacity: 0}}>
                            <div className="text-center mb-7">
                                <h4>Log in</h4>
                                <p>Don't have an account yet?
                                    <a className="js-animation-link" href="#" role="button" data-hs-show-animation-options='{ "targetSelector": "#signupModalFormSignup", "groupName": "idForm" }'>Sign up here</a>
                                </p>
                            </div>

                            <form className="js-validate needs-validation" noValidate>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormLoginEmail">Your email</label>
                                    <input type="email" className="form-control form-control-lg" autoComplete="username" name="email" id="signupModalFormLoginEmail" placeholder="email@site.com" aria-label="email@site.com" required />
                                        <span className="invalid-feedback">Please enter a valid email address.</span>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label" htmlFor="signupModalFormLoginPassword">Password</label>

                                        <a className="js-animation-link form-label-link" href="#" data-hs-show-animation-options='{ "targetSelector": "#signupModalFormResetPassword", "groupName": "idForm" }'>Forgot Password?</a>
                                    </div>

                                    <input type="password" autoComplete="new-password" className="form-control form-control-lg" name="password" id="signupModalFormLoginPassword" placeholder="8+ characters required" aria-label="8+ characters required" required minLength="8" />
                                        <span className="invalid-feedback">Please enter a valid password.</span>
                                </div>

                                <div className="d-grid mb-3">
                                    <button type="submit" className="btn btn-primary btn-lg">Log in</button>
                                </div>
                            </form>
                        </div>
                        <div id="signupModalFormSignup">
                            <div className="text-center mb-7">
                                <h4>Sign up</h4>
                                <p>Already have an account?
                                    <a className="js-animation-link" href="#" role="button" data-hs-show-animation-options='{ "targetSelector": "#signupModalFormLogin", "groupName": "idForm" }'>Log in here</a>
                                </p>
                            </div>

                            <div className="d-grid gap-3">
                                <a className="btn btn-white btn-lg" href="#">
                                    <span className="d-flex justify-content-center align-items-center">
                                        <img className="avatar avatar-xss me-2" src={googleIcon} alt="Google" />
                                            Sign up with Google
                                    </span>
                                </a>

                                <a className="js-animation-link btn btn-primary btn-lg" href="#"
                                    data-hs-show-animation-options='{
                     "targetSelector": "#signupModalFormSignupWithEmail",
                     "groupName": "idForm"
                   }'>Sign up with Email</a>

                                <div className="text-center">
                                    <p className="small mb-0">By continuing you agree to our <NavLink to="/legal/terms-of-service">Terms and Conditions</NavLink></p>
                                </div>
                            </div>
                        </div>
                        <div id="signupModalFormSignupWithEmail" style={{display: "none", opacity: 0}}>
                            <div className="text-center mb-7">
                                <h4>Sign up</h4>
                                <p>Already have an account?
                                    <a className="js-animation-link" href="#" role="button"
                                        data-hs-show-animation-options='{
                       "targetSelector": "#signupModalFormLogin",
                       "groupName": "idForm"
                     }'>Log in here</a>
                                </p>
                            </div>

                            <form className="js-validate need-validate" noValidate>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label>
                                    <input type="email" className="form-control form-control-lg" autoComplete="username" name="email" id="signupModalFormSignupEmail" placeholder="email@site.com" aria-label="email@site.com" required />
                                        <span className="invalid-feedback">Please enter a valid email address.</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="signupModalFormSignupPassword">Password</label>
                                    <input type="password" autoComplete="new-password" className="form-control form-control-lg" name="password" id="signupModalFormSignupPassword" placeholder="8+ characters required" aria-label="8+ characters required" required />
                                        <span className="invalid-feedback">Your password is invalid. Please try again.</span>
                                </div>
                                <div className="mb-3" data-hs-validation-validate-class>
                                    <label className="form-label" htmlFor="signupModalFormSignupConfirmPassword">Confirm password</label>
                                    <input type="password" autoComplete="new-password" className="form-control form-control-lg" name="confirmPassword" id="signupModalFormSignupConfirmPassword" placeholder="8+ characters required" aria-label="8+ characters required" required
                                        data-hs-validation-equal-field="#signupModalFormSignupPassword" />
                                        <span className="invalid-feedback">Password does not match the confirm password.</span>
                                </div>
                                <div className="d-grid mb-3">
                                    <button type="submit" className="btn btn-primary btn-lg">Sign up</button>
                                </div>

                                <div className="text-center">
                                    <p className="small mb-0">By continuing you agree to our <NavLink to="/legal/terms-of-service">Terms and Conditions</NavLink></p>
                                </div>
                            </form>
                        </div>
                        <div id="signupModalFormResetPassword" style={{display: "none", opacity: 0}}>
                            <div className="text-center mb-7">
                                <h4>Forgot password?</h4>
                                <p>Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
                            </div>

                            <form className="js-validate need-validate" noValidate>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label" htmlFor="signupModalFormResetPasswordEmail" tabIndex="0">Your email</label>

                                        <a className="js-animation-link form-label-link" href="#"
                                            data-hs-show-animation-options='{
                       "targetSelector": "#signupModalFormLogin",
                       "groupName": "idForm"
                     }'>
                                            <i className="bi-chevron-left small me-1"></i> Back to Log in
                                        </a>
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" className="form-control form-control-lg" autoComplete="username" name="email" id="signupModalFormResetPasswordEmail" tabIndex="1" placeholder="Enter your email address" aria-label="Enter your email address" required />
                                            <span className="invalid-feedback">Please enter a valid email address.</span>
                                    </div>
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-primary btn-lg">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer d-block text-center py-sm-5">
                        <small className="text-cap mb-4">Trusted by the world's best teams</small>

                        <div className="w-85 mx-auto">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <img className="img-fluid" src={gitlabGray} alt="Logo" />
                                </div>

                                <div className="col">
                                    <img className="img-fluid" src={fitbitGray} alt="Logo" />
                                </div>

                                <div className="col">
                                    <img className="img-fluid" src={flowGray} alt="Logo" />
                                </div>

                                <div className="col">
                                    <img className="img-fluid" src={layerGray} alt="Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SignUpModal;
