import React, { useEffect, useState } from "react";
import CodaResponse from "../services/CodaResponse";

import { Skeleton } from "../components/skeleton";

function StatusItem({ date, title, text }) {
  return (
    <li className="step-item">
      <div className="step-content-wrapper">
        <span className="step-icon step-icon-soft-dark">
          <i className="bi-calendar3"></i>
        </span>
        <div className="step-content">
          {title}
          {text}
        </div>
      </div>
    </li>
  );
}

const Status = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchQuestionStatus().then((res) => {
      setData({
        status: res[0]["c-3fIbo_RJmF"],
        description: res[0]["c-ctBYH0MDA4"],
        statuses: res[1].items,
      });
      setLoading(false);
    });
  }, []);

  return (
    <>
      {!loading && (
        <div className="bg-soft-info">
          <div className="container content-space-1">
            <div className="w-lg-75">
              <div className="mb-5"></div>
              <div className="row align-items-md-center">
                <div className="col-md-8 mb-5 mb-md-0">
                  <div className="card border p-4">
                    <h1 className="h3">{data && data.status}</h1>
                    <p>{data && data.description}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-md-flex justify-content-md-end">
                    <a
                      className="js-apply-tab btn btn-primary"
                      target={"_blank"}
                      href="https://coda.io/form/Let-us-help-you_dPHdgr4zFM1"
                    >
                      Report an issue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container content-space-1 content-space-b-md-3">
        <ul className="step">
          {loading && (
            <div style={{ maxWidth: "300px" }}>
              {[0, 1, 2, 3, 4].map((e, i) => (
                <li key={i} className="step-item mb-5">
                  <Skeleton type="AvatarName" count={5} />
                </li>
              ))}
            </div>
          )}

          {data &&
            data.statuses &&
            data.statuses.length > 0 &&
            data.statuses.map((el, i) => (
              <StatusItem
                key={i}
                date={el.values["c-0a4QV6q4gh"]}
                title={
                  <div className="mb-1">
                    <h6 className="text-primary mb-0">
                      {el.values["c-EUg0Luu1Da"]}
                    </h6>
                    <span>Status: {el.values["c-ArPTJLLMhz"]}</span>
                  </div>
                }
                text={
                  <>
                    <div className="mb-4">
                      <p className="mb-1">
                        {/* <span className="fw-semi-bold">Resolved</span>{" "} */}
                        {el.values["c-9FpKehAcwn"]}
                      </p>
                      <span className="text-muted small">
                        {el.values["c-0a4QV6q4gh"]}, {el.values["c-gca8COYKwd"]}
                      </span>
                    </div>
                  </>
                }
              />
            ))}
        </ul>
      </div>
    </>
  );
};

export default Status;
