import img1 from "../../assets/imgs/svg-logos/Group4.svg";
import img2 from "../../assets/imgs/svg-logos/Group 5.svg";
import img3 from "../../assets/imgs/svg-logos/Group 6.svg";
import img4 from "../../assets/imgs/svg-logos/Group 11.svg";

const AlternativeCta = ({bg}) => {
  return (
    <div className={bg}>
      <div className="container py-6 my-5">
      <div className="bg-white shadow shadow-lg rounded p-4 pb-sm-0 pe-sm-0 pt-sm-5 ps-sm-7">
        <div className="position-relative overflow-hidden">
          <div className="row justify-content-lg-between">
            <div className="col-sm-6 py-5 pb-sm-10">
              <div className="mb-4">
                <h4>Connect with your favorite integrations</h4>
                <p>
                  Expand your business capabilities even more with our useful
                  Integrations.
                </p>
              </div>

              <a className="btn btn-primary" href="../integration">
                View all integrations
              </a>
            </div>

            <div className="col-sm-6 col-lg-5 d-none d-sm-block">
              <div className="container-fluid">
                {/* Gallery */}
                <div className="container mb-5">
                  <div className="row align-items-end gx-2 gx-lg-3 mb-2 mb-lg-3">
                    <div
                      className="col-5 col-md-4 border rounded"
                      style={{ marginRight: "18px" }}
                    >
                      <img
                        className="img-fluid w-100"
                        src={img1}
                        alt="Image Description"
                      />
                    </div>
                    {/* End Col */}
                    <div className="col-4 col-md-4 border rounded ">
                      <img
                        className="img-fluid w-100"
                        src={img2}
                        alt="Image Description"
                      />
                    </div>
                    {/* End Col */}
                  </div>
                  {/* End Row */}
                  <div className="row gx-2 gx-lg-3">
                    <div
                      className="col-5 col-md-4 offset-2 offset-md-3 border rounded"
                      style={{ marginRight: "18px" }}
                    >
                      <img
                        className="img-fluid w-100"
                        src={img3}
                        alt="Image Description"
                      />
                    </div>
                    {/* End Col */}
                    <div className="col-5 col-md-4 border rounded">
                      <img
                        className="img-fluid w-100"
                        src={img4}
                        alt="Image Description"
                      />
                    </div>
                    {/* End Col */}
                  </div>
                  {/* End Row */}
                </div>
                {/* End Gallery */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AlternativeCta;
