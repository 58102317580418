import MiniDashboard from "./MiniDashboard";

import avatar1 from "../../assets/imgs/mockups/dp/marque-dyson-758x758.jpeg";
import avatar2 from "../../assets/imgs/mockups/dp/walmart-logo-24.jpeg";
import avatar3 from "../../assets/imgs/mockups/dp/isotipo_nike-300x300-1.png";
import avatar4 from "../../assets/imgs/mockups/dp/nestle-eps-vector-logo.png";
import avatar5 from "../../assets/imgs/mockups/dp/brand (1).gif";

const chats = [
  {
    avatar: avatar1,
    name: "Dyson",
    message: "Order 643-1435 update"
  },
  {
    avatar: avatar2,
    name: "Walmart",
    message: "Information on your new collection"
  },
  {
    avatar: avatar3,
    name: "Nike",
    message: "New fly runners"
  },
  {
    avatar: avatar4,
    name: "Nestlé",
    message: "Pricing update for 2022"
  },
  {
    avatar: avatar5,
    name: "Costco",
    message: "New trade plans"
  }
];


const messages = [
  {
    text: "Order 643-1435 update",
    left: true,
  },
  {
    text: "Last batch of fly runners went out",
    left: true,
  },
  {
    text: "When will the new season collection come out?",
    left: false,
  },
  {
    text: "April 13th!",
    left: true,
  },
  {
    text: "This collection will be part of your current trade plan",
    left: true,
  },
  {
    text: "Perfect, thank you!",
    left: false,
  },
  {
    text: "You are welcome!",
    left: true,
  }
];

const Dashboard7 = () => {
  return (
    <MiniDashboard>
      <div className="d-flex gx-2 mt-3 fs-08">
        <div className="border rounded col-3 col-lg-4 d-none d-md-block mt-2">
          <div className=" p-2 pb-0 rounded">
            <div className="bg-light py-1 px-2 rounded">
              <i className="bi-search me-2"></i>
              <span>Search chat</span>
            </div>

            {
              chats.map((e, i) => <div key={i} className={`${i > 0 && "border-top"} p-2 ps-0 d-flex aic`}>
                <img className="avatar avatar-sm border avatar-circle me-1" src={e.avatar} alt="DP"></img>
                <div>
                  <div>{e.name}</div>
                  <small className="text-gray-400 lc1">{e.message}</small>
                </div>
              </div>)
            }
          </div>
        </div>

        <div className="border d-flex flex-column jcsb rounded col-12 col-md-9 col-lg-8 mt-2 ms-md-2">
          <div className="d-flex aic jcsb p-1 px-2 border-bottom">
            <div className="d-flex aic">
              <img className="avatar avatar-xs avatar-circle me-1" src={avatar3} alt="DP"></img>
              <div>Nike</div>
            </div>
            <div className="d-flex">
              <div className="ic15 bg-gray-300 rounded-circle me-2"></div>
              <div className="ic15 bg-gray-300 rounded-circle me-2"></div>
              <div className="ic15 bg-gray-300 rounded-circle"></div>
            </div>
          </div>

          <div className=" m-2">
            {
              messages.map((e, i) => <div key={i} className={`d-flex ${e.left ? "m-1" : "justify-content-end my-2"}`}>
                <div className={`rounded p-1 px-2 ${e.left ? "" : "bg-gray-200"}`}
                  style={{
                    backgroundColor: e.left ? "#f5e7fe" : "",
                    color: e.left ? "#291237" : "",
                  }}
                >
                  {e.text}
                </div>
              </div>)
            }
          </div>

          <div className="p-2">
            <div className="bg-light py-1 px-2 rounded">
              <span>Type here...</span>
            </div>
          </div>
        </div>

      </div>
    </MiniDashboard>
  );
};

export default Dashboard7;