import React from "react";

const Product = () => {
  return (
    <>
      <h1 style={{ height: "600px" }}>Products</h1>
    </>
  );
};

export default Product;
