import React from "react";
import { NavLink } from "react-router-dom";

import ocerror from '../assets/svg/illustrations/oc-growing.svg';

const Page404 = () => {
    return (
        <>
            <div className="container text-center">
            <h5 class="text-cap">Page not found</h5>
                <h3 className="mb-4">Sorry, but the page you were looking for could not be found.</h3>
                <NavLink className="btn btn-primary" to="/">Go back home</NavLink>
            </div>
        </>
    );
};

export default Page404;