import React, { useEffect } from "react";
import Shuffle from "shufflejs";
import $ from "jquery";

import img1 from "../../assets/store-examples/cos.png";
import img2 from "../../assets/store-examples/taste-up.png";
import img3 from "../../assets/store-examples/axel-arigato.png";
import img4 from "../../assets/store-examples/mono.png";
import img5 from "../../assets/store-examples/east-side-grains.png";
import img6 from "../../assets/store-examples/pasta-co.png";
import img7 from "../../assets/store-examples/serax.png";
import img8 from "../../assets/store-examples/le-pain-quotidien.png";
import img9 from "../../assets/store-examples/baristaco.png";
import img10 from "../../assets/store-examples/bananamoon.png";
import img11 from "../../assets/store-examples/artek.png";
import img12 from "../../assets/store-examples/cawo.png";
import img13 from "../../assets/store-examples/serax.png";
import img14 from "../../assets/store-examples/le-pain-quotidien.png";

function onLoaded() {
  try {
    if (window.location.pathname.startsWith("/products/examples")) {
      var element = document.querySelector(".shuffle-container");

      var shuffleInstance = new Shuffle(element, {
        itemSelector: "div",
      });

      $(".shuffle-filter li").on("click", function (e) {
        e.preventDefault();
        $(".shuffle-filter .nav-link").removeClass("active");
        $(this).find(".nav-link").addClass("active");
        var keyword = $(this).attr("data-target");
        shuffleInstance.filter(keyword);
      });
    }
  } catch (err) {
    console.log(err);
  }
}

const types = ["All", "Food & Beverage", "Apparel", "Manufacturing", "Home & Garden", "Tools & Hardware", "Beauty"];

const stores = [
  {
    id: 1,
    name: "Cos",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://cos.paleo-store.com/public/store",
    image: img1,
    groups: "Apparel",
  },
  {
    id: 2,
    name: "Taste Up",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://taste-up.paleo-store.com/",
    image: img2,
    groups: "Food & Beverage",
  },
  {
    id: 3,
    name: "Axel Arigato",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://axel-arigato.paleo-store.com/",
    image: img3,
    groups: "Apparel",
  },
  {
    id: 4,
    name: "Mono",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://mono.paleo-store.com/",
    image: img4,
    groups: "Home & Garden",
  },
  {
    id: 5,
    name: "Easy Side Grains",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://east-side-grains.paleo-store.com/",
    image: img5,
    groups: "Food & Beverage",
  },
  {
    id: 6,
    name: "Pasta & Co",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://pasta-co.paleo-store.com/",
    image: img6,
    groups: "Food & Beverage",
  },
  {
    id: 7,
    name: "Serax",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://serax-test.paleo-store.com/",
    image: img7,
    groups: "Home & Garden",
  },
  {
    id: 8,
    name: "Le Pain Quotidien",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://le-pain-quotidien.paleo-store.com/",
    image: img8,
    groups: "Food & Beverage",
  },
  {
    id: 9,
    name: "Barista & Co",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://barista-co.paleo-store.com/",
    image: img9,
    groups: "Food & Beverage",
  },
  {
    id: 10,
    name: "Banana Moon",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://banana-moon.paleo-store.com/",
    image: img10,
    groups: "Apparel",
  },
  {
    id: 11,
    name: "Artek",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://artek.paleo-store.com/",
    image: img11,
    groups: "Home & Garden",
  },
  {
    id: 12,
    name: "Cawo",
    description:
      "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "https://cawo.paleo-store.com/",
    image: img12,
    groups: "Food & Beverage",
  },
];


const Product = () => {
  useEffect(() => {
    // for shuffle
    onLoaded();
  }, []);

  return (
    <>
      <div className="bg-soft-info">
        <div className="container content-space-1">
          <div className="w-lg-75 mx-lg-auto">
            <div className="row align-items-md-center">
              <div className="col-md-6 mb-5 mb-md-0">
                <h1 className="h3">Examples</h1>
                <ul className="list-inline list-separator">
                  <li className="list-inline-item">
                    Browse some public merchant stores by category
                  </li>
                </ul>
              </div>

              <div className="col-md-6">
                <div className="d-md-flex justify-content-md-end">
                  <a
                    className="js-apply-tab btn btn-primary"
                    target={"_blank"}
                    href="https://accounts.paleo-store.com/auth/sign-up"
                  >
                    Get started for free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container content-space-1 content-space-b-md-3">


        <div className="d-flex justify-content-center mb-4">
          <ul
            className="shuffle-filter js-filter-options nav nav-link-gray"
            id="portfolioTab"
            role="tablist"
          >
            {types.map((e) => (
              <li key={e} className="nav-item" data-target={e} role="button">
                <span className={`nav-link ${e === "All" && "active"}`}>
                  {e}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <ul className="shuffle-container row">
          {stores.map((e) => (
            <div
              className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-5 shuffle-item"
              data-groups={'["All","' + e.groups + '"]'}
            >
              <div className="card h-100 p-2">
              <a target="_blank" className="card-link" href={e.link}>
                <img style={{height: "180px"}} className="card-img" src={e.image} alt="Description" />
                <div className="card-body">
                  <h6 className="card-title">{e.name}</h6>
                  {/* <p className="card-text">{e.description}</p> */}
                  <a target="_blank" className="card-link" href={e.link}>
                    View store<i className="bi-chevron-right small ms-1"></i>
                  </a>
                </div>
                </a>
              </div>
            </div>
          ))}
        </ul>

        <div className="row mb-5 mb-sm-5">
          <div className="text-center">
            <a className="btn btn-primary" href="#">
              View more
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
