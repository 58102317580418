import React, { useState, useEffect } from "react";

import ocreview from "../assets/svg/illustrations/oc-review.svg";

import shape1 from "../assets/svg/components/shape-1.svg";
import shape7 from "../assets/svg/components/shape-7.svg";

import layarPrimary from "../assets/svg/brands/layar-primary.svg";
import forbesPrimary from "../assets/svg/brands/forbes-primary.svg";
import capsulePrimary from "../assets/svg/brands/capsule-primary.svg";
import mailchimpPrimary from "../assets/svg/brands/mailchimp-primary.svg";
import fitbitPrimary from "../assets/svg/brands/fitbit-primary.svg";
import CodaResponse from "../services/CodaResponse";

const Pricing = () => {
  const [toggler, setToggler] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const codaRes = new CodaResponse();
  codaRes.PricePlanCoda().then((res) => {
    setPaymentList(res);
  });
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchTable("grid-EQPrufVXVa", "sboeU84ivk").then((res) => {
      let temp = res.items.map((e) => ({
        title: e.values["c-e-gmDpQooH"],
        description: e.values["c-zUM7PEHryA"],
        live: e.values["c-SFEd8Im_xA"],
      }));

      temp = temp.filter((e) => e.live && e.title.length > 1);

      setFaq(temp);
    });
  }, []);
  return (
    <>
      <div className="bg-white overflow-hidden">
        <div className="container content-space-1 content-space-md-2">
          <div className="text-center mx-lg-auto mb-10 pt-5">
            <h1 className="display-6 heading-color">
              Get started for free, switch plans at any time
            </h1>
            <p className="lead text-body">
              Get started for free, no credit card required
            </p>
          </div>

          {paymentList.length === 0 && (
            <div className="text-center">
              <div className="spinner-border m-auto" role="status" />
            </div>
          )}

          {paymentList.length > 0 && (
            <div className="d-flex justify-content-center mb-5">
              <div className="form-check form-switch form-switch-between">
                <label className="form-check-label">Monthly</label>
                <input
                  className="js-toggle-switch form-check-input"
                  type="checkbox"
                  onChange={() => {
                    setToggler(!toggler);
                  }}
                  data-hs-toggle-switch-options='{ "targetSelector": "#pricingCount1, #pricingCount2, #pricingCount3, #pricingCount4, #priceCount5" }'
                />
                <label className="form-check-label form-switch-promotion">
                  Annually
                  <span className="form-switch-promotion-container">
                    <span className="form-switch-promotion-body">
                      <svg
                        className="form-switch-promotion-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 99.3 57"
                        width="48"
                      >
                        <path
                          fill="none"
                          stroke="#bdc5d1"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          d="M2,39.5l7.7,14.8c0.4,0.7,1.3,0.9,2,0.4L27.9,42"
                        ></path>
                        <path
                          fill="none"
                          stroke="#bdc5d1"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          d="M11,54.3c0,0,10.3-65.2,86.3-50"
                        ></path>
                      </svg>
                      <span className="form-switch-promotion-text">
                        <span className="badge bg-info rounded-pill ms-1">
                          Save 20%
                        </span>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          )}

          <div className="position-relative mx-lg-auto mb-7">
            <div className="row card-group-md-row card-group-md-3">
              {paymentList.map((el, index) => (
                <div className="col-md mb-3 mb-md-0">
                  <div className="card card-lg">
                    <div className="card-header text-center">
                      <div className="mb-2">
                        <span
                          id={`pricingCount${index + 1}`}
                          className="fs-4 text-dark fw-bold"
                          data-hs-toggle-switch-item-options='{
                               "min": el.values["c-FCeiE_nsd3"],
                               "max": el.values["c-4_2T6--5ll"]
                             }'
                        >
                          {toggler
                            ? el.values["c-4_2T6--5ll"]
                            : el.values["c-FCeiE_nsd3"]}
                        </span>
                        <span>{toggler ? "/yr" : "/mo"}</span>
                      </div>

                      <h6 className="card-title">
                        {el.values["c-Tfp1M9baLl"]}
                      </h6>
                    </div>

                    <div className="card-body d-flex justify-content-center h-100 py-0 pt-4">
                      <ul className="list-checked list-checked-primary">
                        <li className="list-checked-item">{`Max Partners - ${el.values["c-_53adB8nez"]}`}</li>
                        <li className="list-checked-item">
                          {`Staff Accounts - ${el.values["c-8xPuYopQ3Y"]}`}
                        </li>
                        <li className="list-checked-item">{`Emails - ${el.values["c-wiefHEEeza"]}`}</li>
                        <li className="list-checked-item">{`Reports - ${el.values["c-IRMS1kXLkP"]}`}</li>
                        <li className="list-checked-item">{`Lists - ${el.values["c-35tXNW7B2q"]}`}</li>
                      </ul>
                    </div>

                    <div className="card-footer text-center">
                      <div className="d-grid mb-2">
                        <a
                          target="_blank"
                          href="https://accounts.paleo-store.com/auth/sign-up"
                          className="btn btn-primary"
                        >
                          Get started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center">
            <p className="mb-0">Not sure which plan you need?</p>
            <a
              target={"_blank"}
              className="link"
              href="https://accounts.paleo-store.com/auth/sign-up"
            >
              Get started for free and decide later!{" "}
              <i className="bi-chevron-right small ms-1"></i>
            </a>
          </div>
        </div>
      </div>

      {/* <div id="fullListOfFeatures" className="container content-space-1 content-space-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Compare plans</h3>
        </div>
        <div className="table-responsive-sm">
          <table className="table table-lg table-striped table-borderless">
            <thead>
              <tr className="table-align-middle">
                <th scope="col"></th>
                <th scope="col" className="table-text-center h6">Basic</th>
                <th scope="col" className="table-text-center h6">Premium</th>
                <th scope="col" className="table-text-center h6">Basic</th>
                <th scope="col" className="table-text-center h6">Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="h6 fw-bold">Financial data</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Open/High/Low/Close</th>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Price-volume difference indicator</th>
                <td className="table-text-center">
                  <i className="bi-dash text-body"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row" className="h6 fw-bold">On-chain data</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Network growth</th>
                <td className="table-text-center">
                  <i className="bi-dash text-body"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Average token age consumed</th>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Exchange flow</th>
                <td className="table-text-center">
                  <i className="bi-dash text-body"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Total ERC20 exchange funds flow</th>
                <td className="table-text-center">
                  <i className="bi-dash text-body"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Transaction volume</th>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">Total circulation (beta)</th>
                <td className="table-text-center">
                  <i className="bi-dash text-body"></i>
                </td>
                <td className="table-text-center">
                  <i className="bi-check-circle text-primary"></i>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div> */}

      {/* <div className="container content-space-b-1 content-space-b-md-3">
        <div className="w-lg-65 text-center mx-lg-auto">
          <div className="mb-4">
            <h6>Thousands of world's leading companies trust Space</h6>
          </div>
          <div className="row">
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={capsulePrimary} alt="Logo" />
            </div>
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={fitbitPrimary} alt="Logo" />
            </div>
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={forbesPrimary} alt="Logo" />
            </div>
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={mailchimpPrimary} alt="Logo" />
            </div>
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={layarPrimary} alt="Logo" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="container content-space-1 content-space-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Frequently Asked Questions</h3>
        </div>

        <div className="w-lg-85 mx-lg-auto mb-7">
          <div className="accordion accordion-flush" id="accordionFAQ">
            {faq.map((e, i) => (
              <div className="accordion-item" key={i}>
                <div className="accordion-header" id={`heading${i}`}>
                  <a
                    className="accordion-button collapsed"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${i}`}
                    aria-expanded="false"
                    aria-controls={`collapse${i}`}
                  >
                    {e.title}
                  </a>
                </div>
                <div
                  id={`collapse${i}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${i}`}
                  data-bs-parent="#accordionFAQ"
                >
                  <div className="accordion-body">{e.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <p className="mb-0">Still have questions?</p>
          <a className="link" href="/contact">
            Contact our friendly support team{" "}
            <i className="bi-chevron-right small ms-1"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Pricing;
