import React, { useContext } from "react";
import parse from "html-react-parser";
import shape1softlight from "../../assets/svg/components/shape-1-soft-light.svg";
import shape7softlight from "../../assets/svg/components/shape-7-soft-light.svg";
import { codaContext } from "../../context/codaContext";
import { filterCodaResponse } from "../../utils/CodaUtils";

const Terms = () => {
  const { codadata } = useContext(codaContext);
  const data = filterCodaResponse(codadata, 2);
  return (
    <>
      <div className="bg-primary-dark overflow-hidden">
        <div className="container position-relative content-space-1 content-space-md-2">
          <h3 className="text-white">Terms of service</h3>
          <p className="text-white">Last updated: {data["c-olLGS6x036"]}</p>
        </div>
      </div>
      <div className="container content-space-1 content-space-md-3">
        <div className="row">

          <div className="col-md-12 col-lg-12">
            {data["c-7Bdun6MAYU"] ? parse(data["c-7Bdun6MAYU"]) : ""}

            <div id="stickyBlockEndPoint"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
