import React from "react";

import { ReactComponent as Ecm004 } from "../assets/vendor/duotone-icons/ecm/ecm004.svg";
import { ReactComponent as Com007 } from "../assets/vendor/duotone-icons/com/com007.svg";
import { ReactComponent as Fin008 } from "../assets/vendor/duotone-icons/fin/fin008.svg";
import { ReactComponent as Gen018 } from "../assets/vendor/duotone-icons/gen/gen018.svg";
import { ReactComponent as Elc002 } from "../assets/vendor/duotone-icons/elc/elc002.svg";
import { NavLink } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <div className="shape-container bg-soft-info">
        <div className="position-relative zi-2">
          <div className="container content-space-1 content-space-md-3">
            <div className="w-lg-50 mb-5 mb-sm-10">
              <h3 className="display-6 heading-color">
                Contact <span className="text-primary">us</span>
              </h3>
              <p className="lead">
                Get in touch and let us know how we can help.
              </p>
            </div>

            <div className="row">
              <div className="mr-1 col-md-6 mb-5 mb-md-0">
                <div className="card me-md-6 p-4">
                  <span className="svg-icon text-primary mb-3">
                    <Ecm004 />
                  </span>
                  <h5>Sales</h5>
                  <p>
                    Make your move to powerful trade with the help of our
                    advisors.
                  </p>
                  <a
                    className="btn btn-primary"
                    target="_blank"
                    href="mailto:hello@paleo.technology"
                  >
                    Contact sales{" "}
                    <i className="bi-chevron-right small ms-1"></i>
                  </a>
                </div>
              </div>

              <div className="ml-1 col-md-6 mb-5 mb-md-0">
                <div className="card me-md-6 p-4">
                  <span className="svg-icon text-primary mb-3">
                    <Com007 />
                  </span>
                  <h5>Merchant support</h5>
                  <p>
                    We are here to resolve issues or provide strategic advice.
                  </p>
                  <a
                    className="btn btn-primary"
                    target="_blank"
                    href="https://coda.io/form/Let-us-help-you_dPHdgr4zFM1"
                  >
                    Contact support{" "}
                    <i className="bi-chevron-right small ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape shape-bottom zi-1">
          <svg
            width="3000"
            height="600"
            viewBox="0 0 3000 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 600H3000V0L0 600Z" fill="#fff" />
          </svg>
        </div>
      </div>

      <div className="container content-space-1 content-space-b-md-3">
        <div className=" mb-7">
          <h3>Contact sales</h3>
          <p>
            Let us know what you have in mind!
          </p>
        </div>

        <div className="row justify-content-md-between">
          <div className="col-md-6 mb-7 mb-md-0"></div>

          <div className="mb-4">
            <h6>Registered address</h6>
          </div>

          <ul className="list-unstyled list-py-1">
            <li>
              <div className="d-flex align-items-center">
                <div
                  className="flex-shrink-0 text-center"
                  style={{ width: "1.5rem" }}
                >
                  <span className="svg-icon svg-icon-xs text-muted mt-0">
                    <Gen018 />
                  </span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span>Los Angeles, USA (HQ)</span>
                </div>
              </div>
            </li>

            <li>
              <div className="d-flex align-items-center">
                <div
                  className="flex-shrink-0 text-center"
                  style={{ width: "1.5rem" }}
                >
                  <span className="svg-icon svg-icon-xs text-muted mt-0">
                    <Gen018 />
                  </span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="d-block">Paleo Trade Inc.</span>
                  <span className="d-block">735 S Figueroa St</span>
                  <span className="d-block">CA 90017</span>
                </div>
              </div>
            </li>
          </ul>

          <div className="mt-5">
            <h6>Contact sales by email</h6>
            <p>
              If you wish to write us an email instead please use{" "}
              <a href="mailto:hello@paleo.technology">hello@paleo.technology</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
