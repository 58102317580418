import React from "react";
import { NavLink } from "react-router-dom";

import googleIcon from "../assets/svg/brands/google-icon.svg";
import capsuleprimary from "../assets/svg/brands/capsule-primary.svg";
import fitbitprimary from "../assets/svg/brands/fitbit-primary.svg";
import forbesprimary from "../assets/svg/brands/forbes-primary.svg";
import mailchimpprimary from "../assets/svg/brands/mailchimp-primary.svg";
import layarprimary from "../assets/svg/brands/layar-primary.svg";

import SideBySideContent1 from "../components/contents/SideBySideContent1";

const Beta = () => {
  return (
    <>
    
      <div className="bg-dark-blue">
        <div className="container content-space-1 content-space-t-md-3">
          <div className="text-center mb-5 mb-sm-10">
            <h1 className="display-5 text-white">
              Join the Paleo <span className="text-info">Beta</span> program
            </h1>
            <p className="text-white">
              Become a beta tester for the Paleo launch version. As a beta
              tester, you will be able to test the very first version of the
              Paleo platform to help us shape the future of trade.
            </p>
          </div>

          <div className="mx-auto" style={{ maxWidth: "40rem" }}>
            <div className="card card-lg zi-2">
              <div className="card-header text-center">
                <h4 className="card-title">Apply here</h4>
              </div>
              <div className="card-body">
                <form className="js-validate need-validate" novalidate>


                  {/* <div className="row">
                    <div className="col-sm-6 mb-4 mb-sm-0">
                      <div className="mb-4">
                        <label className="form-label" for="signupFormFirstName">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="signupFormFirstName"
                          id="signupFormFirstName"
                          placeholder="Jacob"
                          aria-label="Jacob"
                          required
                        />
                        <span className="invalid-feedback">
                          Please enter your first name.
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="mb-4">
                        <label className="form-label" for="signupFormLasttName">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="signupFormLastName"
                          id="signupFormLasttName"
                          placeholder="Williams"
                          aria-label="Williams"
                          required
                        />
                        <span className="invalid-feedback">
                          Please enter your last name.
                        </span>
                      </div>
                    </div>
                  </div> */}
{/* 
                  <div className="mb-4">
                    <label className="form-label" for="signupFormEmail">
                      Your email
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      name="signupFormEmailName"
                      id="signupFormEmail"
                      placeholder="email@site.com"
                      aria-label="email@site.com"
                      required
                    />
                    <span className="invalid-feedback">
                      Please enter a valid email address.
                    </span>
                  </div>

                  <div className="col-sm-12">
                    <div className="mb-4">
                      <label className="form-label" for="companyName">
                        The name of your company
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="companyName"
                        id="companyName"
                        placeholder="Paleo"
                        aria-label="Paleo"
                        required
                      />
                      <span className="invalid-feedback">
                        Please enter your the name of your company.
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="mb-4">
                      <label className="form-label" for="numberOfEmployees">
                        Number of employees
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="numberOfEmployees"
                        id="numberOfEmployees"
                        placeholder="20"
                        aria-label="20"
                        required
                      />
                      <span className="invalid-feedback">
                        Please enter the number of employees
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-4">
                        <label className="form-label" for="yearlyRevenue">
                          Average 12-month revenue
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          name="yearlyRevenue"
                          id="yearlyRevenue"
                          placeholder="3000000"
                          aria-label="3000000"
                          required
                        />
                        <span className="invalid-feedback">
                          Please enter your average 12-month revenue
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="mb-4">
                        <label className="form-label" for="yearlyRevenue">
                          Currency
                        </label>
                        <select
                          className="form-control form-control-lg"
                          name="yearlyRevenue"
                          id="yearlyRevenue"
                          placeholder="3000000"
                          aria-label="3000000"
                          required
                        />
                        <option></option>
   
                        <span className="invalid-feedback">
                          Please enter your last name.
                        </span>
                      </div>
                    </div>
                  </div> */}
{/* 
                  <div className="col-sm-12">
                      <div className="mb-4">
                        <label className="form-label" for="yearlyRevenue">
                        How do you currently trade with other B2b merchants?
                        </label>
                        <textarea
                          type="number"
                          className="form-control form-control-lg"
                          name="yearlyRevenue"
                          id="yearlyRevenue"
                          placeholder="Your message"
                          aria-label="Your message"
                          required
                        />
                        <span className="invalid-feedback">
                        How do you currently trade with other B2b merchants?
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="mb-4">
                        <label className="form-label" for="yearlyRevenue">
                        Why are you interested in joining our beta program?
                        </label>
                        <textarea
                          type="number"
                          className="form-control form-control-lg"
                          name="yearlyRevenue"
                          id="yearlyRevenue"
                          placeholder="Your message"
                          aria-label="Your message"
                          required
                        />
                        <span className="invalid-feedback">
                        Why are you interested in joining our beta program?
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="mb-4">
                        <label className="form-label" for="yearlyRevenue">
                        What do you expect to get out of the Paleo platform?
                        </label>
                        <textarea
                          type="number"
                          className="form-control form-control-lg"
                          name="yearlyRevenue"
                          id="yearlyRevenue"
                          placeholder="Your message"
                          aria-label="Your message"
                          required
                        />
                        <span className="invalid-feedback">
                        What do you expect to get out of the Paleo platform?
                        </span>
                      </div>
                    </div> */}

                  {/* <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="signupFormTermsCheck"
                      name="signupFormTermsCheck"
                      required
                    />
                    <label
                      className="form-check-label"
                      for="signupFormTermsCheck"
                    >
                      {" "}
                      I accept the{" "}
                      <NavLink to={"/legal/terms-of-service"}>
                        Terms and Conditions
                      </NavLink>
                    </label>
                    <span className="invalid-feedback">
                      Please accept our Terms and Conditions.
                    </span>
                  </div> */}

                  <div className="d-grid mb-4">
                    {/* <button type="submit" className="btn btn-primary btn-lg">
                      Sign up
                    </button> */}

                    <a href="https://coda.io/form/Paleo-beta-user-form_dR4zbLpfga-" target="_blank" className="btn btn-primary">Sign up</a>
                  </div>
                
                </form>
                


              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* <div className="shape-container">
        <div className="shape shape-bottom zi-1">
          <svg
            viewBox="0 0 3000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 1000V583.723L3000 0V1000H0Z" fill="#fff" />
          </svg>
        </div>
      </div> */}
      

      {/* <div className="container content-space-b-1 content-space-b-md-3">
        <div className="w-lg-65 text-center mx-lg-auto">
          <div className="mb-4">
            <h5>Thousands of world's leading companies trust Space</h5>
          </div>

          <div className="row">
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={capsuleprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={fitbitprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={forbesprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={mailchimpprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={layarprimary} alt="Logo" />
            </div>
          </div>
        </div>
      </div> 
      
      */}
    </>
  );
};

export default Beta;
