import React from "react";

import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import Cta from "../../components/contents/Cta";
import ContentWithInvoice from "../../components/contents/ContentWithInvoice";
import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";
import AlternativeCta from "../../components/cards/AlternativeCta";
import UseCaseHeader from "../../components/cards/UseCaseHeader";
import { Icon1, Icon2, Icon3 } from "../../components/icons";
import FeatureDetails from "../../components/contents/FeatureDetails";
import FourFeaturesBlocks from "../../components/contents/FourFeaturesBlocks";

import img33 from "../../assets/imgs/website-images/img64.jpg";

const Professionals = () => {
  return (
    <>
      <UseCaseHeader
        title="Paleo for Professionals"
        subtitle="Usecase"
        text={
          <p className="lead">
            We help professionals by making trade more accessible, faster, more
            effective, and more secure worldwide. Our core focus is to provide
            the most effective trade infrastructure for businesses of all sizes.
 

          </p>
        }
        img={img33}
      />

<FourFeaturesBlocks></FourFeaturesBlocks>

    
<ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Professional trade"
        heading="Request professional discounts instantly"
        childOrder="1"
        description="With Paleo, you can request professional discounts from merchants instantly while keeping all your orders and purchase requests in one place."
        list={[
          {
            img: <Icon1 />,
            text: "Request professional discounts",
          },
          {
            img: <Icon2 />,
            text: "Create purchase requests",
          },
          {
            img: <Icon3 />,
            text: "Save products in custom lists for later purchasing",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder="1"
        
        subtitle="UNIFIED PLATFORM"
        heading="Your entire commerce business in one place"
        description="Paleo is the most powerful platform for merchants. With Paleo, you can purchase, sell, chat with your customers and suppliers, access powerful analytics on your business, sell b2c and b2b, keep track of inventory, create discount trade plans and keep track of your inventory all one single place. "
        list={[
          {
            img: <Icon1 />,
            text: "Safely work with suppliers and manufacturers all over the world",
          },
          {
            img: <Icon2 />,
            text: "Keep your retailers updated, at all times",
          },
          {
            img: <Icon3 />,
            text: "One catalog for all your sales channels",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentWithInvoice bg="bg-soft-info" />

      <ContentMarkupSection
        childOrder={0}
  
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>



     


      <AlternativeCta />

    </>
  );
};

export default Professionals;
