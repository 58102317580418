import { useEffect, useContext } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./utils/routes";
import { codaContext } from "./context/codaContext";
import { alternativeContext } from "./context/alternativeContext";
import CodaResponse from "./services/CodaResponse";

function App() {
  const { dispatchCodaEvent } = useContext(codaContext);
  const { dispatchAlternativeEvent } = useContext(alternativeContext);
  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes
      .CodaDataContent(4)
      .then((res) => {
        dispatchCodaEvent("ADD_DATA", { data: res.items });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    codaRes
      .alternativeOrg()
      .then((res) => {
        dispatchAlternativeEvent("ADD_DATA", { data: res });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const content = useRoutes(routes);

  return content;
}

export default App;
