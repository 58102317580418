import React, { useEffect, useState } from "react";

import MiniDashboard from "./MiniDashboard";


const avatar = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Lululemon_Athletica_logo.svg/2048px-Lululemon_Athletica_logo.svg.png"
import banner from "../../assets/imgs/website-images/mr-lee-f4RBYsY2hxA-unsplash.jpg";

const purchases = [
  ["Nike", "$249.744", "$14.85"],
  ["Peloton", "$2.860", "$1.048,04"],
  ["Walmart", "$560.528", "$60.83"],
  ["Saturn Gmbh", "$0", "$4.754,82"],
  ["Dyson Inc.", "$7.704", "$9,45"],
  ["Nestlé", "$17.564", "$11.93"],
  ["Lululemon", "$5.500", "$8,57"],
]

const details = {
  name: "Lululemon",
  type: "Brand",
  employee: "200",
  bio: "Organisation bio of Lululemon...",
  more: [
    ["Total sales", "$35,900"],
    ["Total purchases", "$25,900"],
    ["Total orders", "$45,900"],
    ["Tax no.", "EU483652654"],
  ]
}



const Dashboard4 = () => {
  const [tab, setTab] = useState("1");

  useEffect(() => {
    let timer = setInterval(() => setTab(tab === "1" ? "2" : "1"), 8000)

    return () => {
      clearInterval(timer)
      timer = null;
    }
  })

  return (
    <MiniDashboard>
      <div className="mt-3">
        <ul className="nav nav-pills" id="pills-tab" role="tablist" style={{ margin: "3px 0 10px 0" }}>
          <li className="nav-item " role="presentation">
            <button className={`p-1 px-3 btn btn-sm ${tab === "1" && "btn-primary"}`} type="button" role="tab" aria-selected={tab === "1"} onClick={() => setTab("1")}>Partner</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`p-1 px-3 btn btn-sm ${tab === "2" && "btn-primary"}`} type="button" role="tab" aria-selected={tab === "2"} onClick={() => setTab("2")}>All Partners</button>
          </li>
        </ul>

        <div className="tab-content position-relative" id="pills-tabContent" active={tab}>
          <div className="tab-pane p-1 fade show active sl-1" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab1-tab">
            <div className="bg-primary p-4 py-5 rounded bg-img-start bg-img-center"
              style={{ backgroundImage: `url(${banner})` }}>
              <div className="d-flex aic " style={{ transform: "translateY(48px)" }}>
                <img className="avatar avatar-lg avatar-circle me-2" src={avatar} alt="Image Description"></img>
                <div className="fw-bold h6 text-white">Lululemon</div>
              </div>
            </div>
            <div className="p-2"></div>


            <div className="p-3 mt-3 border rounded">
              <div className="text-gray-500 fs-09">Organization details</div>
              <div className="row gx-2">
                <div className="col-6 col-md-4 mt-2">
                  <div className="bg-light p-2 rounded">
                    {details.name}
                  </div>
                </div>
                <div className="col-6 col-md-4 mt-2">
                  <div className="bg-light p-2 rounded d-flex jcsb aic">
                    <div className="text-gray-400">Type</div>
                    <div>{details.type}</div>
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div className="bg-light p-2 rounded d-flex jcsb aic">
                    <div className="text-gray-400">Employees</div>
                    <div>{details.employee}</div>
                  </div>
                </div>

                <div className="col mt-2">
                  <div className="bg-light p-2 pb-4 rounded">
                    {details.bio}
                  </div>
                </div>

              </div>
            </div>


            <div className="p-3 mt-2 border rounded">
              <div className="text-gray-500 fs-09">Details</div>
              <div className="row gx-2">
                {
                  details.more.map((e, i) => <div key={i} className="col-md-6 mt-2">
                    <div className="bg-light p-2 rounded d-flex jcsb aic">
                      <div className="text-gray-400">{e[0]}</div>
                      <div>{e[1]}</div>
                    </div>
                  </div>)
                }
              </div>
            </div>
          </div>



          <div className="tab-pane fade show active sl-2 w-100 position-absolute top-0 left-0" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2-tab">
            <div className="table-responsive-sm">
              <table className="table table-lg">
                <thead>
                  <tr className="table-align-middle">
                    <th scope="col">Name</th>
                    <th scope="col">Sales</th>
                    <th scope="col">Purchases</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  {
                    purchases.map((e, i) => <tr key={i} className="js-form-check-row-select form-check-row-select table-align-middle">
                      <td>{e[0]}</td>
                      <td>{e[1]}</td>
                      <td>{e[2]}</td>
                      <td className="text-end">
                        <button className="btn p-1 px-2 fs-08 btn-primary">View partner</button>
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </MiniDashboard >
  );
};

export default Dashboard4;