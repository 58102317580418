
const Cta = ({
    title = "",
    text = "",
    link = {
        href: "",
        link: "",
        target: "",
    },
}) => {
    return (
        <div className="bg-soft-info">
            <div className="container content-space-1">
                <div className="w-lg-65  mx-lg-auto">
                    <h3>{title}</h3>
                    <p className="fs-6">{text}</p>
                    <a
                        className="btn btn-primary"
                        target={link.target}
                        href={link.href}
                    >
                        {link.text}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Cta;
