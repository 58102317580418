
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
} from "../../components/icons";



export default function Test() {
  return (
    <div className="container content-space-1 content-space-md-3">
      <div className="row">
        <div className="col-4 col-md-3 col-xl-1">
          <Icon1 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon2 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon3 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon4 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon5 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon6 />
        </div>
        <div className="col-4 col-md-3 col-xl-1">
          <Icon7 />
        </div>

      </div>
    </div>
  );
}
