import React from "react";

// layouts
import DefaultLayout from "../layout/DefaultLayout";
import EmptyLayout from "../layout/EmptyLayout";

import Home from "../pages/Home";
import About from "../pages/About";
import ApiPage from "../pages/ApiPage";
import Beta from "../pages/Beta";
import Links from "../pages/Links";
import Careers from "../pages/Careers";
import CareersOverview from "../pages/CareersOverview";
import Contact from "../pages/Contact";
import Integration from "../pages/Integration";
import Investors from "../pages/Investors";
import Pricing from "../pages/Pricing";
import Alternatives from "../pages/alternatives";
import AlternativesDeatils from "../pages/alternatives/details";

import Product from "../pages/products/index.js";

import Products from "../pages/Products";
import ProductsOnlineStore from "../pages/products/online-store";
import ProductsSell from "../pages/products/sell";
import ProductsPurchase from "../pages/products/purchase";
import ProductsB2b from "../pages/products/b2b";
import ProductsB2c from "../pages/products/b2c";
import ProductsMessaging from "../pages/products/messaging";
import ProductsCatalogs from "../pages/products/catalogs";
import Examples from "../pages/products/examples";

import Status from "../pages/Status";

// legal
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import Terms from "../pages/legal/Terms";

// use-cases
import Brands from "../pages/use-cases/Brands";
import Distributors from "../pages/use-cases/Distributors";
import Manufacturers from "../pages/use-cases/Manufacturers";
import Professionals from "../pages/use-cases/Professionals";
import Retailers from "../pages/use-cases/Retailers";

// tests
import Test from "../pages/_test/";
import TestCharts from "../pages/_test/charts";
import Icons from "../pages/_test/icons";
import HomeTest from "../pages/_test/HomeTest";

import Page404 from "../pages/Page404";

const routes = [
  {
    path: "",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "investors",
        element: <Investors />,
      },
      {
        path: "beta",
        element: <Beta />,
      },
      {
        path: "links",
        element: <Links />,
      },
      {
        path: "careers",
        element: <Careers />,
      },
      {
        path: "careers/:id",
        element: <CareersOverview />,
      },
      {
        path: "/api",
        element: <ApiPage />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/integration",
        element: <Integration />,
      },

      {
        path: "/alternatives",
        element: <Alternatives />,
      },
      {
        path: "/alternatives/:alternativeId",
        element: <AlternativesDeatils />,
      },
      {
        path: "/product",
        element: <Product />,
      },
      {
        path: "/status",
        element: <Status />,
      },
    ],
  },
  {
    path: "/products",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: <Products />,
      },
      {
        path: "online-store",
        element: <ProductsOnlineStore />,
      },
      {
        path: "sell",
        element: <ProductsSell />,
      },
      {
        path: "purchase",
        element: <ProductsPurchase />,
      },
      {
        path: "b2b",
        element: <ProductsB2b />,
      },
      {
        path: "b2c",
        element: <ProductsB2c />,
      },
      {
        path: "messaging",
        element: <ProductsMessaging />,
      },
      {
        path: "catalogs",
        element: <ProductsCatalogs />,
      },
      {
        path: "examples",
        element: <Examples />,
      },
    ],
  },
  {
    path: "use-cases",
    element: <DefaultLayout />,
    children: [
      {
        path: "for-brands",
        element: <Brands />,
      },
      {
        path: "for-retailers",
        element: <Retailers />,
      },
      {
        path: "for-manufacturers",
        element: <Manufacturers />,
      },
      {
        path: "for-professionals",
        element: <Professionals />,
      },
      {
        path: "for-distributors",
        element: <Distributors />,
      },
    ],
  },
  {
    path: "legal",
    element: <DefaultLayout />,
    children: [
      {
        path: "terms-of-service",
        element: <Terms />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "test",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: <Test />,
      },
      {
        path: "charts",
        element: <TestCharts />,
      },
      {
        path: "icons",
        element: <Icons />,
      },
      {
        path: "home",
        element: <HomeTest />,
      },
    ],
  },
  {
    path: "*",
    element: <EmptyLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
