import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { Skeleton } from "../../components/skeleton";
import { alternativeContext } from "../../context/alternativeContext";

const AlternativesDeatils = () => {
    const { alternativedata } = useContext(alternativeContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(alternativedata.map(e => e.values));
    }, [alternativedata])



    return (
        <>

<div className="bg-soft-info">
  <div className="container content-space-1">
    <div className="w-lg-75 mx-lg-auto">

      <div className="row align-items-md-center">
        <div className="col-md-6 mb-5 mb-md-0">
          <h1 className="h3">Alternatives</h1>
          <ul className="list-inline list-separator">
            <li className="list-inline-item">See how Paleo compares to similar e-commerce platforms.</li>
          </ul>
        </div>
        {/* End Col */}
        <div className="col-md-6">
          <div className="d-md-flex justify-content-md-end">
            <a className="js-apply-tab btn btn-primary" target={"_blank"} href="https://accounts.paleo-store.com/auth/sign-up">Try Paleo for free</a>
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
  </div>
</div>

            <div className="container mb-10">
                <div className="container content-space-t-1">
                    {data.length === 0 && <Skeleton type="Integration" count={9} />}

                    {data.length > 0 && Object.keys(data[0]).length > 0 && <>
                        <div className="row mb-5 mb-md-0">
                            {
                                data.map((e, i) => <div key={i} className="col-sm-6 col-lg-4 mb-3 mb-sm-5 mb-lg-7">
                                    <Link to={`/alternatives/${e["c-MG_5CWBJaV"]}`}>
                                        <div className="card border card-lg h-100">
                                            <div className="card-header bg-soft-info position-relative overflow-hidden">
                                                <img
                                                    className="border bg-white rounded-circle avatar avatar-lg mb-3"
                                                    src={e["c-04UWVV2AKR"]}
                                                    alt="Image"
                                                />
                                                <div className="d-flex align-items-center mb-2">
                                                    <h5 className="card-title mb-0">
                                                        {e["c-uaMJw36pzK"]}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className="card-text">{e["c-UvurpxX9j9"]}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>)
                            }
                        </div>
                    </>
                    }
                </div>
            </div>




            {/* CTA */}
            <div className="container content-space-1">
                {/* Heading */}
                <div className="w-lg-65 text-center mx-lg-auto mb-7">
                    <h3>Become a Paleo merchant</h3>
                    <p className="fs-6">
                        Try Paleo for free, and explore all the tools and services you
                        need to start, run, and grow your commerce business.
                    </p>
                </div>
                {/* End Heading */}
                <div className="text-center">
                    <a className="btn btn-primary" href="../page-login.html">
                        Start free trial
                    </a>
                </div>
            </div>
            {/* End CTA */}


        </>
    );
};

export default AlternativesDeatils;
