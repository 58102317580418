import { useState } from "react";
import { THEME_PALETTE_LIGHT } from "../settings/constants";


const usePalette = () => {
  const [palette] = useState(THEME_PALETTE_LIGHT);
  return palette;
};

export default usePalette;
