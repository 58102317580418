import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import ContentWithInvoice from "../../components/contents/ContentWithInvoice";

const Product = () => {
  return (
    <>
      <>
        <div className="bg-soft-info">
          <div className="container content-space-1">
            <div className="w-lg-75 mx-lg-auto">
              <div className="row align-items-md-center">
                <div className="col-md-6 mb-5 mb-md-0">
                  <h1 className="h3">Purchase</h1>
                  <ul className="list-inline list-separator">
                    <li className="list-inline-item">
                      Paleo offers powerful purchasing options that let you
                      instantly purchase from multiple suppliers at once.
                    </li>
                  </ul>
                </div>
                {/* End Col */}
                <div className="col-md-6">
                  <div className="d-md-flex justify-content-md-end">
                    <a
                      className="js-apply-tab btn btn-primary"
                      target={"_blank"}
                      href="https://accounts.paleo-store.com/auth/sign-up"
                    >
                      Get started for free
                    </a>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
          </div>
        </div>

        <ContentMarkupSection
          childOrder={0}
          subtitle="Start selling"
          heading="The most efficient way to purchase"
          childOrder="0"
          description="Paleo offers powerful purchasing options that let you instantly purchase from multiple suppliers at once."
          list={[
            {
              img: <Icon1 />,
              text: "Place multiple orders in seconds",
            },
            {
              img: <Icon2 />,
              text: "Pay all your suppliers in one go",
            },
            {
              img: <Icon3 />,
              text: "Always pay in your local currency",
            },
          ]}
        >
          <div>
            <img
              className="device-browser-img"
              src={"../../SVG-Graphics/combined-order.svg"}
              alt="Image Description"
            />
          </div>
        </ContentMarkupSection>

        <div className="bg-soft-info">
          {/* Icon Blocks */}
          <div className="container content-space-1">
            <div className="row">
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Combined orders</h5>
                  </span>
                  <p>
                    Order from all your suppliers in one go. Paleo offers
                    powerful purchasing options that let you instantly purchase
                    from multiple suppliers at once.
                  </p>
                </div>
              </div>
              {/* End Col */}
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Create purchase requests</h5>
                  </span>
                  <p>
                    Quickly send purchase requests to merchants for custom items
                    or non-catalog items. You can manage all your pending
                    purchase orders in one place.
                  </p>
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}

            <div className="row mt-5">
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Purchase in your local currency</h5>
                  </span>
                  <p>
                    Always purchase in your local currency using a single
                    payment account. No matter how many suppliers you have or
                    where they are located.
                  </p>
                </div>
              </div>
              {/* End Col */}
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Review purchases at all times</h5>
                  </span>
                  <p>
                    Review and analyze your purchase orders at all times, and
                    know exactly how much you've spent with each partner,
                    analyze profitability from purchases, review average
                    purchase prices, and much more.
                  </p>
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Icon Blocks */}
        </div>

        <ContentMarkupSection
          childOrder="1"
          subtitle="Start purchasing"
          heading="Always pay in your own currency"
          description="Pay for multiple orders at once, using a single payment account in your local currency. Each supplier is then paid out in their currency."
          list={[
            {
              img: <Icon1 />,
              text: "Use a single account for all your purchases",
            },
            {
              img: <Icon2 />,
              text: "Always pay in your local currency",
            },
            {
              img: <Icon3 />,
              text: "Pay with credit cards, Paypal or wire transfers powered by Paleo Payments",
            },
          ]}
        >
          <div>
            <img
              className="device-browser-img"
              src={"../../SVG-Graphics/pay-suppliers-in-currency.svg"}
              alt="Image Description"
            />
          </div>
        </ContentMarkupSection>

        <ContentWithInvoice bg="bg-soft-info" subHeader="Global scale" />
      </>
    </>
  );
};

export default Product;
