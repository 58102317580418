import React from "react";
import { Bar } from "react-chartjs-2";

import usePalette from "../../hooks/usePalette";

/*
    const data = {
      "labels": ["May 1", "May 2", "May 3", "May 4", "May 5", "May 6", "May 7", "May 8", "May 9", "May 10"],
      datasets: [{
        "data": [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
        "backgroundColor": "#377dff",
        "hoverBackgroundColor": "#377dff",
        "borderColor": "#377dff"
      }]
    };
  */


const BarSingle = ({ data, title, subTitle, showLegends }) => {
  const palette = usePalette();

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    "cornerRadius": 0,
    "tooltips": {
      "prefix": "$",
      "hasIndicator": true,
      "mode": "index",
      "intersect": false
    },
    "hover": {
      "mode": "nearest",
      "intersect": true
    },
    scales: {
      "yAxes": [{
        "gridLines": {
          "color": palette["gray-200"],
          "drawBorder": false,
          "zeroLineColor": palette["gray-200"]
        },
        "ticks": {
          "beginAtZero": true,
          "stepSize": 100,
          "fontSize": 12,
          "fontFamily": "Open Sans, sans-serif",
          "padding": 10,
          "postfix": "$"
        }
      }],
      "xAxes": [{
        "gridLines": {
          "display": false,
          "drawBorder": false
        },
        "ticks": {
          "fontSize": 12,
          "fontColor": palette["gray-600"],
          "padding": 5
        },
        "categoryPercentage": 0.5,
        "maxBarThickness": "10"
      }]
    },
  };

  return (
    <div>
      <div className="card-body">
        <div className="chart">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default BarSingle;
