import React, { useEffect, useState } from "react";

import MiniDashboard from "./MiniDashboard";
import chartImg from "../../assets/svg/magicpattern-svg-chart-1646605155304.svg";

const sales = [
    [3433, 240],
    [3454, 1567],
    [3562, 170],
    [3578, 2546],
];
const purchases = [
    [153, 1567],
    [133, 240],
    [175, 2546],
    [161, 170],
];

const Dashboard1 = ({ mini = false }) => {
    const [tab, setTab] = useState("1");

    useEffect(() => {
        let timer = setInterval(() => setTab(tab === "1" ? "2" : "1"), 6000)

        return () => {
            clearInterval(timer)
            timer = null;
        }
    })


    return (
        <MiniDashboard>
            <div className="row mt-3 gx-2">

                {/*  left side panel */}
                <div className="col-md-6">
                    <div className="border p-2 rounded">
                        <div className="text-gray-500 fs-09 fw-bold">TOTAL SALES</div>
                        <div className={`fs-5 fw-bold d-flex aic jcsb mt-2  ${mini ? "" : "border-bottom  pb-2"}`}>
                            <span>$26,045</span>
                            <img loading="lazy" className="w-30" src="https://uploads-ssl.webflow.com/614436c6038eda138779a324/62253639d33c10db95253b52_magicpattern-svg-chart-1646605877467.svg" />
                        </div>
                        {
                            !mini && <div className="d-flex aic jcsb mt-2">
                                <span className=" text-gray-500">142 Total orders</span>
                                <span className="badge text-white bg-primary-dark">View report</span>
                            </div>
                        }
                    </div>
                    <div className="border p-2 rounded mt-2">
                        <div className="text-gray-500 fs-09 fw-bold">TOTAL ORDERS</div>
                        <div className={`fs-5 fw-bold d-flex aic jcsb mt-2  ${mini ? "" : "border-bottom  pb-2"}`}>
                            <span>142</span>
                            <img loading="lazy" className="w-30" src="https://uploads-ssl.webflow.com/614436c6038eda138779a324/6225361cdd39afa2aab9223d_magicpattern-svg-chart-1646605844943.svg" />
                        </div>
                        {
                            !mini && <div className="d-flex aic jcsb mt-2">
                                <span className=" text-gray-500">$26,045 Total sales</span>
                                <span className="badge text-white bg-primary-dark">View report</span>
                            </div>
                        }
                    </div>

                </div>
                {/* END left side panel */}

                {/* right side panel */}
                <div className="col-md-6 mt-2 mt-md-0">
                    <div className="border p-2 rounded">
                        {
                            !mini && <ul className="nav nav-pills" id="pills-tab" role="tablist" style={{ margin: "3px 0 10px 0" }}>
                                <li className="nav-item " role="presentation">
                                    <button className={`p-1 px-3 btn btn-sm ${tab == "1" && "btn-primary"}`} onClick={() => setTab("1")} aria-selected={tab === "1"} type="button" role="tab">Sales</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`p-1 px-3 btn btn-sm  ${tab == "2" && "btn-primary"}`} onClick={() => setTab("2")} aria-selected={tab === "2"} type="button" role="tab">Purchases</button>
                                </li>
                            </ul>
                        }
                        <div className="tab-content position-relative" id="pills-tabContent" active={tab}>
                            <div className={`tab-pane fade show active sl-1`} id="pills-sales" role="tabpanel" aria-labelledby="pills-sales-tab">
                                {
                                    sales.map((e, i) => <div key={i} className={`d-flex jcsb aic ${mini ? "p-2 fs-09" : "p-3"} ${(!mini || (mini && i > 0)) && "border-top"}`}>
                                        <div className="d-flex aic">

                                            <span>#{e[0]}</span>
                                        </div>
                                        <span className="fw-bold">${e[1]}</span>
                                    </div>)
                                }
                            </div>

                            <div className={`tab-pane fade w-100 show active sl-2 position-absolute top-0 left-0`} id="pills-purchase" role="tabpanel" aria-labelledby="pills-purchase-tab">
                                {
                                    purchases.map((e, i) => <div key={i} className="d-flex jcsb aic p-2 border-top">
                                        <div className="d-flex aic">

                                            <span>#{e[0]}</span>
                                        </div>
                                        <span className="fw-bold">${e[1]}</span>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* right side panel */}

            </div>
        </MiniDashboard >
    );
};

export default Dashboard1;
