
const SideBySideContent = ({
    title,
    text,
    img,
    dark = false,
    list
}) => {
    return (
        <div className={dark ? "bg-dark-blue" : "bg-soft-info"}>
            <div className="container content-space-t-1 content-space-md-3">
                <div className="row align-items-md-center">
                    <div className="col-md-6 order-md-2 d-md-block mb-10 mb-md-0">
                        <div className="row">
                            <div className="">
                                <img
                                    className="img-fluid shadow rounded-2"
                                    src={img}
                                    alt="Image Description"
                                    style={{ marginBottom: "-3rem" }}
                                />
                            </div>
                        </div>
                        {/* End Row */}
                    </div>
                    {/* End Col */}
                    <div className="col-md-6">
                        <div className="pe-lg-5">
                            <div className="mb-7">
                          
                                <h3 className={dark ? "text-white" : ""}>{title}</h3>
                                <p className={`fs-6 ${dark && "text-grey"}`}>{text}</p>
                            </div>
                            <div className="d-grid gap-3">
                                {
                                    list.map(e => <div key={e.text} className="d-flex">
                                        <div className="flex-shrink-0">
                                            <span className="svg-icon svg-icon-sm text-primary">
                                                <i className={`bi bi-check2-circle bold ${dark && "text-white"} h5`}></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className={dark ? "text-white" : ""}>{e.title}</h6>
                                            <p className={dark ? "text-grey" : ""}>{e.text}</p>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
            </div>
        </div>
    );
};

export default SideBySideContent;
