import MiniDashboard from "./MiniDashboard";

import zapier from "../../assets/imgs/svg-logos/Group 5.svg";
import wooCommerce from "../../assets/imgs/svg-logos/Group 7.svg";
import shopify from "../../assets/imgs/svg-logos/Group4.svg";


const products = [
  {
    name: "Molekyl Candlelight 2",
    type: "Decoration",
    price: "$120",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/10505-13-8_Jewel_Vase_-_Glass_Amber_1194x1194.jpg?v=1629993444"
  },
  {
    name: "Ceiling Lamp Spider",
    type: "Decoration",
    price: "$6.56",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/ceiling-lamp-spider-5-still-angled-arms-serge-mouille_1_100x.jpg?v=1645399590"
  },
  {
    name: "Sweetly SGS",
    type: "Chair",
    price: "$434",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/Sweetly-SGS-Nero-Rosa-Stool34F_100x.jpg?v=1644525841"
  },
  {
    name: "Greta Scr Arm",
    type: "Chair",
    price: "$727.50",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/GRETAMARGHERITA_0000s_0014_greta-scr-arm_100x.jpg?v=1644525537"
  },
  {
    name: "Accento Allure S",
    type: "Chair",
    price: "$727.50",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/510a482ab02f7c28b01dd59dd20061201c573376f123e274b2e2ad5b34ab2166a12f26380e4434f46f165ce39d85399d57d8de74004eb8d6d681323e1f68a6028f59343bc84a9568c1f144e69eba229ef2bc895c4057260c93f4783_1278x853.jpg?v=1629996355"
  }

]

const connections = [
  {
    avatar: shopify,
    text: "Shopify",
  },
  {
    avatar: wooCommerce,
    text: "WooCommerce",
  },
  {
    avatar: zapier,
    text: "Zapier",
  }
];

const Dashboard5 = () => {


  return (
    <MiniDashboard>
      <div className="row mt-3 gx-2 aic">

        {/*  left side panel */}
        <div className="col-md-8">
          <div className="border p-2 rounded mx-md-auto">

            <div className="table-responsive-sm overflow-auto">
              <table className="table mb-0">
                <tbody>
                  {
                    products.map((e, i) => <tr key={i} className="table-align-middle text-gray-600">
                      <td>
                        <div className="d-flex aic">
                          <img className="avatar avatar-sm avatar-circle me-2" src={e.image} alt="Image Description"></img>
                          <div>
                            <small className=" text-gray-400">Name</small>
                            <div className="lc2">{e.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="table-text-center">
                        <small className=" text-gray-400">Price</small>
                        <div>{e.price}</div>
                      </td>
                      <td>
                        <small className=" text-gray-400">Type</small>
                        <div className="lc1">{e.type}</div>
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>

          </div>

        </div>
        {/* END left side panel */}

        {/* right side panel */}
        <div className="col-md-4 mt-2 mt-md-0">
          <div className="rounded">

            <div className="table-responsive-sm">
              <table className=" table-sm mb-0 text-gray-600">
                <tbody>
                  {
                    connections.map((e, i) => <tr key={i}>
                      <td className="d-flex aic">
                        <img className="border shadow avatar avatar-lg avatar-circle me-2" src={e.avatar} alt="Image Description"></img>
                        <div>
                          <small className="fw-bold text-gray-400">Name</small>
                          <div className="lc1">{e.text}</div>
                        </div>
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>

          </div>
        </div>
        {/* right side panel */}

      </div>
    </MiniDashboard>
  );
};

export default Dashboard5;
