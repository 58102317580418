import logo from "../../assets/imgs/paleo-logo-purple.svg";

function Logo({ height = "20px", className = "" }) {
  return (
    <>
      <img src={logo} height={height} alt="Paleo logo" className={className} />
    </>
  );
}

export default Logo;
