import React, { useEffect, useState } from "react";

import MiniDashboard from "./MiniDashboard";
import img1 from "../../assets/imgs/mockups/dp/1.jpg";
import img2 from "../../assets/imgs/mockups/dp/2.jpg";
import img3 from "../../assets/imgs/mockups/dp/3.jpg";
import img4 from "../../assets/imgs/mockups/dp/4.jpg";
import img5 from "../../assets/imgs/mockups/dp/5.jpg";

const sales = [
  [img1, "Product 1 name", 240],
  [img2, "Product 2 name", 1567],
  [img3, "Product 3 name", 170],
  [img4, "Product 4 name", 2546],
];
const purchases = [
  [img3, "List 2 Product 3", 2546],
  [img4, "List 2 Product 4", 170],
  [img1, "List 2 Product 1", 1567],
  [img2, "List 2 Product 2", 240],
];

const Dashboard9 = () => {
  const [tab, setTab] = useState("1");

  useEffect(() => {
    let timer = setInterval(() => setTab(tab === "1" ? "2" : "1"), 6000)

    return () => {
      clearInterval(timer)
      timer = null;
    }
  })


  return (
    <MiniDashboard>
      <div className="row mt-3 gx-2">

        {/*  left side panel */}
        <div className="col-md-6">
          <div className="row gx-2">

            {
              [0, 1, 2, 3].map((e, i) => <div key={i} className="col-3 col-md-6 p-1">
                <div className="border p-2 rounded">
                  <div className="fs-5 my-5 fw-bold text-center">List {i + 1}</div>
                </div>
              </div>)
            }
          </div>

        </div>
        {/* END left side panel */}

        {/* right side panel */}
        <div className="col-md-6 mt-2 mt-md-0">
          <div className="border p-2 rounded">
            {
              <ul className="nav nav-pills" id="pills-tab" role="tablist" style={{ margin: "3px 0 10px 0" }}>
                <li className="nav-item " role="presentation">
                  <button className={`p-1 px-3 btn btn-sm ${tab == "1" && "btn-primary"}`} onClick={() => setTab("1")} aria-selected={tab === "1"} type="button" role="tab">List 1</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`p-1 px-3 btn btn-sm  ${tab == "2" && "btn-primary"}`} onClick={() => setTab("2")} aria-selected={tab === "2"} type="button" role="tab">List 2</button>
                </li>
              </ul>
            }
            <div className="tab-content position-relative" id="pills-tabContent" active={tab}>
              <div className={`tab-pane fade show active sl-1`} id="pills-sales" role="tabpanel" aria-labelledby="pills-sales-tab">
                {
                  sales.map((e, i) => <div key={i} className={`d-flex jcsb aic p-3 ${i > 0 && "border-top"}`}>
                    <div className="d-flex aic">
                      <img class="border shadow avatar avatar-xs avatar-circle me-2" src={e[0]} />
                      <span>{e[1]}</span>
                    </div>
                    <span className="fw-bold">${e[2]}</span>
                  </div>)
                }
              </div>

              <div className={`tab-pane fade w-100 show active sl-2 position-absolute top-0 left-0`} id="pills-purchase" role="tabpanel" aria-labelledby="pills-purchase-tab">
                {
                  purchases.map((e, i) => <div key={i} className="d-flex jcsb aic p-3 border-top">
                    <div className="d-flex aic">
                      <img class="border shadow avatar avatar-xs avatar-circle me-2" src={e[0]} />
                      <span>{e[1]}</span>
                    </div>
                    <span className="fw-bold">${e[2]}</span>
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
        {/* right side panel */}

      </div>
    </MiniDashboard >
  );
};

export default Dashboard9;
