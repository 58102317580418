
import img1 from "../../assets/store-examples/le-pain-quotidien.png";
import img2 from "../../assets/store-examples/bananamoon.png";
import img3 from "../../assets/store-examples/baristaco.png";
import img4 from "../../assets/store-examples/artek.png";
import img5 from "../../assets/store-examples/east-side-grains.png";
import img6 from "../../assets/store-examples/pasta-co.png";
import img7 from "../../assets/store-examples/serax.png";
import img8 from "../../assets/store-examples/le-pain-quotidien.png";
import img9 from "../../assets/store-examples/baristaco.png";
import img10 from "../../assets/store-examples/bananamoon.png";
import img11 from "../../assets/store-examples/artek.png";
import img12 from "../../assets/store-examples/cawo.png";
import img13 from "../../assets/store-examples/serax.png";
import img14 from "../../assets/store-examples/le-pain-quotidien.png";


const stores = [
  {
    id: 1,
    name: "Le Pain Quotidien",
    description: "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "/products/examples",
    image: img1,
  },
  {
    id: 2,
    name: "Banana Moon",
    description: "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "/products/examples",
    image: img2,
  },
  {
    id: 3,
    name: "Barista & Co",
    description: "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "/products/examples",
    image: img3,
  },
  {
    id: 4,
    name: "Artek",
    description: "This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.",
    link: "/products/examples",
    image: img4,
  },
];

const StoreExample = () => {

  return (
    <>
      <div className="container content-space-1 content-space-b-md-3">
        <div className="w-lg-50 text-center mx-lg-auto mb-7">
          <h3>Start selling in minutes</h3>
          <p>Open a public ecommerce store in a matter of minutes, for free!</p>
        </div>

        <div className="row">
          {
            stores.map(e => <div
              className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-5 shuffle-item"
            >
              <div className="card h-100 p-2">
                <img className="card-img" src={e.image} alt="Description" />
                <div className="card-body">
                  <h6 className="card-title">{e.name}</h6>
                
                  {/* <a className="card-link" href={e.link}>View store<i className="bi-chevron-right small ms-1"></i></a> */}
                </div>
              </div>
            </div>)
          }
        </div>
        <div className="w-lg-50 text-center mx-lg-auto mb-7">
          <span><a className="btn btn-primary" href="/products/examples">View more</a></span>
        </div>
      </div>
    </>
  );
};

export default StoreExample;
