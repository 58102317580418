import React, { useState } from "react";
import { VectorMap } from "react-jvectormap";

import { countries } from "./countries";

function World({ mapData, height }) {
  const [countryList, setCountryList] = useState(countries);
  const markerList = [];
  const showData = [];
  const options = {
    map: "world_mill",
    tipCentered: true,
    focusOn: {
      x: 0.5,
      y: 0.5,
      scale: 1.2,
    },
    regionStyle: {
      initial: {
        fill: "#bdc5d1",
      },
      hover: {
        fill: "#77838f",
      },
    },
    backgroundColor: "#fff",
    markerStyle: {
      initial: {
        "stroke-width": 2,
        fill: "#377dff",
        stroke: "#fff",
        r: 6,
      },
      hover: {
        "stroke-width": 0,
        fill: "#377dff",
        stroke: "#fff",
      },
    },
    markers: markerList,
    series: {
      markers: [
        {
          attribute: "fill",
          scale: "#FEE5D9",
        },
      ],
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    zoomOnScroll: true,

    onMarkerTipShow(event, label, index) {
      let htmlText = `
        <section style="background-color: white;border-radius: 5px;padding: 5px 8px;margin: -5px;color: #333;">
          <div style="font-weight: 600;display: inline-block;">
            ${showData[index][Object.keys(showData[0])[0]]}
          </div>
          <br />
          <hr style="margin-top: 5px;margin-bottom: 5px;" />`;

      // eslint-disable-next-line no-plusplus
      for (let j = 1; j < Object.keys(showData[0]).length; j++) {
        htmlText += `
          <div class="p-1">
            <div style="display: inline;margin-right: 8px">
              ${Object.keys(showData[0])[j]}:
            </div> 
            <span style="font-weight: 600;float: right;margin-right: 5px;"> 
              ${showData[index][Object.keys(showData[0])[j]]}
            </span>
            <br />
          </div>
        `;
      }
      htmlText += "</section>";

      label.html(htmlText);
    },
  };


  if (mapData && Object.keys(mapData).length > 0 && countryList) {
    const mapKeys = Object.keys(mapData[Object.keys(mapData)[0]]);

    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < countryList.length; k++) {
      if (mapData[countryList[k][0]]) {
        markerList.push([countryList[k][1], countryList[k][2]]);
        const tempData = { Name: countryList[k][3] };

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < mapKeys.length; j++) {
          tempData[mapKeys[j]] = mapData[countryList[k][0]][mapKeys[j]];
        }
        showData.push(tempData);
      }
    }
  }

  return (
    <div className="bmap-container position-relative rounded" style={{ height: height || "300px" }}>
      <VectorMap {...options} />
    </div>
  );
}

export default World;
