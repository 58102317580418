import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import Dashboard1 from "../cards/Dashboard1";
import Dashboard2 from "../cards/Dashboard2";
import Dashboard3 from "../cards/Dashboard3";

const FeatureGeneral = () => {
    const [active, setActive] = useState(1)
    const slideCount = window.innerWidth > 768 ? 3 : 1;

    return (
        <div className="bg-primary-dark">
            <div className="container content-space-1">
                <div className="w-lg-65 text-center mx-lg-auto mb-7">
                    <h3 className="text-white">More ways to build</h3>
                    <p className="text-white-70">Space's feature-rich designed demo pages help you create the best possible product.</p>
                </div>
                {/* <!-- End Heading --> */}

                {/* <!-- Swiper Main Slider --> */}
                <div className="js-swiper-main swiper">
                    <div className="swiper-wrapper">
                        {
                            active === 1 && <div className="swiper-slide">
                                <div className="devices devices-shadow-none">
                                    <figure className="device-browser">
                                        <div className="device-browser-header">
                                            <div className="device-browser-header-btn-list">
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                            </div>
                                            <div className="device-browser-header-browser-bar">www.paleo.technology</div>
                                        </div>

                                        <div className="device-browser-frame">
                                            <Dashboard1 />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        }


                        {
                            active === 2 && <div className="swiper-slide">
                                <div className="devices devices-shadow-none">
                                    <figure className="device-browser">
                                        <div className="device-browser-header">
                                            <div className="device-browser-header-btn-list">
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                            </div>
                                            <div className="device-browser-header-browser-bar">www.paleo.technology</div>
                                        </div>

                                        <div className="device-browser-frame">
                                            <Dashboard2 />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        }


                        {
                            active === 3 && <div className="swiper-slide">
                                <div className="devices devices-shadow-none">
                                    <figure className="device-browser">
                                        <div className="device-browser-header">
                                            <div className="device-browser-header-btn-list">
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                            </div>
                                            <div className="device-browser-header-browser-bar">www.paleo.technology</div>
                                        </div>

                                        <div className="device-browser-frame">
                                            <Dashboard3 />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        }

                    </div>
                </div>


                <Swiper
                    spaceBetween={15}
                    slidesPerView={slideCount}
                >
                    <SwiperSlide>
                        <div className={`${active === 1 && "swiper-slide-thumb-active"} swiper-slide swiper-pagination-progress swiper-pagination-progress-light`} onClick={() => setActive(1)}>
                            <div className="swiper-pagination-progress-body mb-4">
                                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper transition-duration-3s"></div>
                            </div>
                            <span className="text-white">Discover how to build and maintain coding systems using our documentation.</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${active === 2 && "swiper-slide-thumb-active"} swiper-slide swiper-pagination-progress swiper-pagination-progress-light`} onClick={() => setActive(2)}>
                            <div className="swiper-pagination-progress-body mb-4">
                                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper transition-duration-3s"></div>
                            </div>
                            <span className="text-white">Start browsing our snippets pages with copy-to-clipboard snippets to match Bootstrap's level of quality.</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${active === 3 && "swiper-slide-thumb-active"} swiper-slide swiper-pagination-progress swiper-pagination-progress-light`} onClick={() => setActive(3)}>
                            <div className="swiper-pagination-progress-body mb-4">
                                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper transition-duration-3s"></div>
                            </div>
                            <span className="text-white">Apart from 70+ HTML-pages, the theme comes with 3 ready-to-use and stand-alone demo options.</span>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>
        </div>
    );
};

export default FeatureGeneral;
