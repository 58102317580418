import React from "react";
import { NavLink, Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <li className="hs-has-sub-menu nav-item">
        <a
          id="useCaseMegaMenu"
          className="hs-mega-menu-invoker nav-link dropdown-toggle"
          href="#"
          role="button"
          aria-expanded="false"
        >
          Use cases
        </a>

        <div
          className="hs-sub-menu dropdown-menu"
          aria-labelledby="useCaseMegaMenu"
          style={{ minWidth: "12rem" }}
        >
          <a
            href={"/use-cases/for-brands"}
            title="Brands"
            id="brandsPage"
            className="dropdown-item"
          >
            For Brands
          </a>
          <a
            href={"/use-cases/for-retailers"}
            title="Retailers"
            id="retailersPage"
            className="dropdown-item"
          >
            For Retailers
          </a>
          <a
            href={"/use-cases/for-manufacturers"}
            title="Manufacturers"
            id="manufacturersPage"
            className="dropdown-item"
          >
            For Manufacturers
          </a>
          <a
            href={"/use-cases/for-professionals"}
            title="Professionals"
            id="professionalsPage"
            className="dropdown-item"
          >
            For Professionals
          </a>
          <a
            href={"/use-cases/for-distributors"}
            title="Distributors"
            id="distributorsPage"
            className="dropdown-item"
          >
            For Distributors
          </a>
        </div>
      </li>

      <li className="hs-has-sub-menu nav-item">
        <a
          id="productMegaMenu"
          className="hs-mega-menu-invoker nav-link dropdown-toggle"
          href="#"
          role="button"
          aria-expanded="false"
        >
          Product
        </a>

        <div
          className="hs-sub-menu dropdown-menu"
          aria-labelledby="productMegaMenu"
          style={{ minWidth: "12rem" }}
        >
          <a
            href={"/products/online-store"}
            title="Brands"
            id="brandsPage"
            className="dropdown-item"
          >
            Online store
          </a>
          <a
            href={"/products/sell"}
            title="Brands"
            id="brandsPage"
            className="dropdown-item"
          >
            Sell
          </a>
          <a
            href={"/products/purchase"}
            title="Retailers"
            id="retailersPage"
            className="dropdown-item"
          >
            Purchase
          </a>
          <a
            href={"/products/b2b"}
            title="Manufacturers"
            id="manufacturersPage"
            className="dropdown-item"
          >
            B2B
          </a>
          <a
            href={"/products/b2c"}
            title="Brands"
            id="brandsPage"
            className="dropdown-item"
          >
            B2C
          </a>
          <a
            href={"/products/messaging"}
            title="Retailers"
            id="retailersPage"
            className="dropdown-item"
          >
            Messaging
          </a>
          <a
            href={"/products/catalogs"}
            title="Manufacturers"
            id="manufacturersPage"
            className="dropdown-item"
          >
            Catalogs / Data source
          </a>

          <a className="dropdown-item" href="/alternatives">
            Alternatives{" "}
          </a>

          <a className="dropdown-item" href="/products/examples">
          Examples{" "}
          </a>

        </div>
      </li>

      <li className="nav-item">
        <a
          id="useCaseMegaMenu"
          className="hs-mega-menu-invoker nav-link"
          href="/pricing"
          aria-expanded="false"
        >
          Pricing
        </a>
      </li>

      <li className="hs-has-sub-menu nav-item">
        <a
          id="paleoMegaMenu"
          className="hs-mega-menu-invoker nav-link dropdown-toggle"
          href="#"
          role="button"
          aria-expanded="false"
        >
          Company
        </a>

        <div
          className="hs-sub-menu dropdown-menu"
          aria-labelledby="paleoMegaMenu"
          style={{ minWidth: "12rem" }}
        >
          <a
            href={"/contact"}
            title="Contact"
            id="contactPage"
            className="dropdown-item"
          >
            Contact Us
          </a>

          <a
            href={"/beta"}
            title="Beta"
            id="betaPage"
            className="dropdown-item"
          >
            Beta
          </a>
          <a
            href={"/careers"}
            title="Careers"
            id="careersPage"
            className="dropdown-item"
          >
            Careers
          </a>
          <a href={"/api"} title="API" id="apiPage" className="dropdown-item">
            API
          </a>

          <a
            href={"/integration"}
            title="Integrations"
            id="integrationPage"
            className="dropdown-item"
            aria-current="page"
          >
            Integrations
          </a>

          <a
            href={"/investors"}
            title="Investors"
            id="investorsPage"
            className="dropdown-item"
          >
            Investors
          </a>

          <a
            target="_blank"
            className="dropdown-item"
            href="https://github.com/orgs/Paleo-Inc/repositories"
          >
            Open source <i className="bi-box-arrow-up-right ms-1"></i>
          </a>

          <a
            id="PaleoPayPage"
            className="dropdown-item"
            target={"_blank"}
            href="https://www.paleopay.com/"
            role="button"
          >
            Paleo Pay <i className="bi-box-arrow-up-right ms-1"></i>
          </a>
        </div>
      </li>
    </>
  );
};

export default Navbar;
