import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Skeleton } from "../components/skeleton";

import { ReactComponent as Abs028 } from "../assets/vendor/duotone-icons/abs/abs028.svg";
import { ReactComponent as Abs039 } from "../assets/vendor/duotone-icons/abs/abs039.svg";
import { ReactComponent as Abs037 } from "../assets/vendor/duotone-icons/abs/abs037.svg";
import { ReactComponent as Cod009 } from "../assets/vendor/duotone-icons/cod/cod009.svg";
import { ReactComponent as Art007 } from "../assets/vendor/duotone-icons/art/art007.svg";
import { ReactComponent as Gen005 } from "../assets/vendor/duotone-icons/gen/gen005.svg";
import { ReactComponent as Gen022 } from "../assets/vendor/duotone-icons/gen/gen022.svg";
import { ReactComponent as Cod011 } from "../assets/vendor/duotone-icons/cod/cod011.svg";
import { ReactComponent as Com007 } from "../assets/vendor/duotone-icons/com/com007.svg";
import { ReactComponent as Arr060 } from "../assets/vendor/duotone-icons/arr/arr060.svg";
import { ReactComponent as Arr034 } from "../assets/vendor/duotone-icons/arr/arr034.svg";

import img1 from "../assets/imgs/600x400/img1.jpg";
import img2 from "../assets/imgs/900x700/img2.jpg";
import img2_1 from "../assets/imgs/600x400/img2.jpg";
import img3 from "../assets/imgs/900x700/img3.jpg";
import img3_1 from "../assets/imgs/600x400/img3.jpg";
import img6 from "../assets/imgs/1920x1080/img6.jpg";
import img7 from "../assets/imgs/1920x1080/img7.jpg";
import img8 from "../assets/imgs/1920x1080/img8.jpg";
import ocmoneyprofits from "../assets/svg/illustrations/oc-money-profits.svg";
import forbesPrimary from "../assets/svg/brands/forbes-primary.svg";
import CodaResponse from "../services/CodaResponse";

const Integration = () => {

  const [loading, setLoading] = useState(true);

  const [allIntegrations, setAllIntegrations] = useState([]);
  const [integrationList, setIntegrations] = useState([]);
  const [statusValue, setStatusValue] = useState([]);

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchIntegration().then(async (res) => {

      // process the data
      let allListTemp = await res[0].map(e => ({
        name: e.values["c-VY0uFJvoPd"],
        status: e.values["c-nWQzIBlREy"],
        description: e.values["c-IoELCmvG5K"],
        logo: e.values["c-w1EF0CZLKS"],
      }));

      //sort according to status
      allListTemp.sort((a, b) => a.status > b.status ? 1 : -1);

      setIntegrations(allListTemp);
      setAllIntegrations(allListTemp);
      setStatusValue(res[1]);
      setLoading(false);
    });
  }, []);

  const handleSearch = (s) => {
    if (s === "") { return setIntegrations(allIntegrations); }

    const temp = [...allIntegrations].filter((e) => e.name.search(new RegExp(s.split(" ").join("|"), "ig")) > -1);
    setIntegrations(temp);
  };

  return (
    <>
      {/* Hero */}
      <div className="bg-soft-info position-relative overflow-hidden">
        <div className="container content-space-t-3 content-space-t-md-4 content-space-b-1 content-space-b-md-3">
          <div className="row mb-5">
            <div className="col-md-7">
              {/* Heading */}
              <div className="pe-lg-3">
                <h1 className="display-6 text-primary">
                  Expand the Paleo infrastructure through integrations
                </h1>
              </div>
              {/* End Heading */}
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
          <div className="row mb-5">
            <div className="col-md-9">
              <form>
                {/* Input Card */}
                <div className="input-card input-card-sm">
                  <div className="input-card-form">
                    {/* Input Group */}
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Search for integrations"
                        aria-label="Your name"
                        aria-describedby="heroNameAddOn"
                        onChange={(event) => handleSearch(event.target.value)}
                      />
                    </div>
                    {/* End Input Group */}
                  </div>
                </div>
                {/* End Input Card */}
              </form>
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                {statusValue.map((el, i) => (
                  <div key={i} className="col-sm mb-3 mb-sm-0">
                    <span className="h3 d-block text-primary">
                      {el["c-bE639BlS2b"]}
                    </span>
                    <p>{el["c-Jc9LqC9jbS"]}</p>
                  </div>
                ))}
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
      </div>
      {/* End Hero */}

      {/* Cards */}
      <div className="container content-space-1">
        {/* Heading */}
        <div className="mb-7">
          <h3>Integration list</h3>
          <p className="fs-6">
            Connect Paleo with these integrations to extend your commerce business
          </p>
        </div>
        {/* End Heading */}

        {loading && <Skeleton type="Integration" count={9} />}

        <div className="row mb-5 mb-md-0">
          {integrationList.map((el, i) => (
            <div key={i} className="col-sm-6 col-lg-4 mb-3 mb-sm-5 mb-lg-7">
              <div className="card border card-lg h-100">
                <div className="card-header bg-soft-info position-relative overflow-hidden">
                  <img
                    className="border bg-white rounded-circle avatar avatar-lg mb-3"
                    src={el.logo}
                    alt="Image"
                  />
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="card-title mb-0">
                      {el.name}
                    </h5>
                    <span className="badge bg-info rounded-pill ms-2">
                      {el.status}
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <p className="card-text">{el.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* End Row */}
        {/* Info */}
        <div className="text-center">
          <p className="mb-0">Didn't find what you where looking for?</p>
          <a
            target={"_blank"}
            className="link"
            href="https://coda.io/form/Suggest-a-new-Paleo-integration_d2RSrcWHosz"
          >
            Suggest an integration
            <i className="bi-chevron-right small ms-1" />
          </a>
        </div>
        {/* End Info */}
      </div>
      {/* End Cards */}
    </>
  );
};
export default Integration;
