import React, { useEffect, useState } from "react";

import CodaResponse from "../services/CodaResponse";

import { ReactComponent as Com012 } from "../assets/vendor/duotone-icons/com/com012.svg";
import { ReactComponent as Com013 } from "../assets/vendor/duotone-icons/com/com013.svg";
import { ReactComponent as Abs028 } from "../assets/vendor/duotone-icons/abs/abs028.svg";
import { ReactComponent as Abs026 } from "../assets/vendor/duotone-icons/abs/abs026.svg";
import { ReactComponent as Abs027 } from "../assets/vendor/duotone-icons/abs/abs027.svg";
import img from "../assets/imgs/website-images/img37.jpg";
import img4 from "../assets/imgs/240x480/img4.jpg";
import img2 from "../assets/imgs/website-images/analytics-dark.svg";
import img1 from "../assets/imgs/800x500/img1.jpg";
import { ReactComponent as Abs029 } from "../assets/vendor/duotone-icons/abs/abs029.svg";
import { ReactComponent as Gra010 } from "../assets/vendor/duotone-icons/gra/gra010.svg";
import { ReactComponent as Lay001 } from "../assets/vendor/duotone-icons/lay/lay001.svg";
import { ReactComponent as Teh004 } from "../assets/vendor/duotone-icons/teh/teh004.svg";
import { ReactComponent as Abs037 } from "../assets/vendor/duotone-icons/abs/abs037.svg";
import { ReactComponent as Cod011 } from "../assets/vendor/duotone-icons/cod/cod011.svg";

import SideBySideContent from "../components/contents/SideBySideContent";

import { ReactComponent as Slip } from "../assets/vendor/duotone-icons/ecm/ecm008.svg";
import { ReactComponent as Transaction } from "../assets/vendor/duotone-icons/fil/fil024.svg";
import { ReactComponent as Split } from "../assets/vendor/duotone-icons/gen/gen009.svg";
import { ReactComponent as Dollar } from "../assets/vendor/duotone-icons/fin/fin010.svg";

import ImageBrowserBlock from "../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../components/sections/ContentMarkupSection";

import ImageFourFeatures from "../components/contents/ImageFourFeatures";

import Dashboard1 from "../components/cards/Dashboard1";
import Dashboard2 from "../components/cards/Dashboard2";
import Dashboard3 from "../components/cards/Dashboard3";
import Dashboard4 from "../components/cards/Dashboard4";
import Dashboard5 from "../components/cards/Dashboard5";
import Dashboard6 from "../components/cards/Dashboard6";
import Dashboard7 from "../components/cards/Dashboard7";

import FourDashboards from "../components/cards/FourDashboards";

import img8 from "../assets/imgs/website-images/img52.jpg";

import { Icon1, Icon2, Icon3 } from "../components/icons";
import ImageWithText from "../components/contents/ImageWithText";
import img7 from "../assets/imgs/950x950/img7.jpg";
import imgiwt2 from "../assets/imgs/950x950/img2.jpg";

const sidebyside1 = {
  subtitle: "Seamless experience",
  title: "We are building a new era for commerce ",
  text: "Work with partner merchants all over the world, hassle-free. Paleo lets you manage global commerce from a single place.",
  id: "stepFeatures",
  link: {
    href: "#none",
    text: "Learn more",
  },
  list: [
    {
      title: "Sell and purchase globally",

      img: img2,
    },
    {
      title: "Manage everything in one place",

      img: img,
    },
    {
      title: "Sell online in minutes",

      img: img2,
    },
  ],
};

const Home = ({ children }) => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchTable("grid-EQPrufVXVa", "sboeU84ivk").then((res) => {
      let temp = res.items.map((e) => ({
        title: e.values["c-e-gmDpQooH"],
        description: e.values["c-zUM7PEHryA"],
        live: e.values["c-SFEd8Im_xA"],
      }));

      temp = temp.filter((e) => e.live && e.title.length > 1);

      setFaq(temp);
    });
  }, []);

  return (
    <>
      <div>
        {/* Hero */}
        <div className="bg-dark-blue">
          <div className="container position-relative zi-2 content-space-b-1 pt-5">
            <div className="row justify-content-center align-items-md-center mb-7">
              <div className="col-md-6 py-3  mb-md-0">
                {/* Heading */}
                <div>
                  <a href="/beta"><span style={{ fontSize: "0.8em" }} className="badge bg-info rounded-pill">Sign up to try Paleo in public beta</span></a>

                </div>
                <div className="pe-lg-3 mt-3">
                  <h1 className="display-5 text-white mb-2 mb-md-2">
                    Start sellling B2B in minutes
                  </h1>
                  <p className="lead text-white-70">
                    Built for ambitious merchants dealing with consumer goods,
                    Paleo makes commerce easy, reliable and secure.
                  </p>
                </div>
                {/* End Heading */}
                <div className="d-grid d-sm-flex gap-3">
                  <a
                    target="_blank"
                    className="btn text-white border-white"
                    href="https://accounts.paleo-store.com/auth/sign-up"
                  >
                    Try for free <i className="bi-chevron-right small ms-1" />
                  </a>
                  {/* Video button. Remove commenting once we have a product video. -Max */}
                  {/* <a
                    className="btn btn-light"
                    href="https://www.youtube.com/watch?v=0qisGSwZym4"
                    role="button"
                    data-fslightbox="youtube-video"
                  >
                    Watch a demo <i className="bi-play-circle-fill ms-1" />
                  </a> */}
                  {/* Video button. Remove commenting once we have a product video. -Max */}
                </div>
              </div>
              {/* End Col */}
              <div className="col-sm-12 mt-3 mt-md-0 col-md-6">
                <Dashboard6 />
              </div>
              {/* End Col */}
            </div>
          </div>
          <div className="lines lines-light">
            <div className="lines-container">
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
            </div>
          </div>
        </div>
        {/* End Hero */}
        {/* Shape */}
        <div className="shape-container">
          <div className="shape shape-bottom zi-1">
            <svg
              viewBox="0 0 3000 600"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 600V350.234L3000 0V600H0Z" fill="#fff" />
            </svg>
          </div>
        </div>
        {/* End Shape */}
      </div>

      <div className="container">
        <FourDashboards />
      </div>

      <ContentMarkupSection
        childOrder={1}
        bg="bg-soft-info"
        subtitle="Unified platform"
        heading="Keep track of all your purchase orders in one place"
        description="Sell your products efficiently anywhere in the world. Sell B2B and B2C at any time, accept custom order requests, set up detailed discount programs, etc. "
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <ImageBrowserBlock imgUrl={"../SVG-dashboard/sales-white.svg"} />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Commerce powertool"
        heading="Your partner network, in one place"
        description="Paleo allows merchants to manage their whole commerce business in one place. You can work with partner merchants, whether they are on Paleo or not, keep track of partnership trade data, communicate with partners and share product data."
        list={[
          {
            img: <Icon1 />,
            text: "Overview merchant partnerships at all times",
          },
          {
            img: <Icon2 />,
            text: "Create and assign flexible trade programs",
          },
          {
            img: <Icon3 />,
            text: "Share product data with partners instantly",
          },
        ]}
      >
        <Dashboard4 />
      </ContentMarkupSection>

      <SideBySideContent {...sidebyside1} dark={true} img={img2} />

      {/* 
      <ImageWithText
        image={img}
        title="The world’s most powerful and easy to use platform for commerce"
        subtitle="Unified platform"
        text="Built for ambitious merchants dealing with consumer goods, Paleo makes trade easy, reliable and secure. 

        Paleo allows you to keep trade of your product data in one place, manage your inventory, send purchase orders, create sales orders, create analytic dashboards, create “combined orders” to order from all your suppliers at once, create discount trade plans, what and share files with suppliers and customers instantly, and much more…"
      ></ImageWithText> */}

      <ContentMarkupSection
        childOrder="1"
        subtitle="Start selling"
        bg="bg-soft-info"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 banner={img8} />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels and share files and images. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder="1"
        bg="bg-soft-info"
        subtitle="Powerful data"
        heading="Commerce data like you haven't seen before"
        description="Understand your business better through powerful visual analytics. With Paleo, you can instantly get a sense of your commerce performance without spending time on data analysis. Additionally, you can create custom statistic views with over 130 chart components. "
        list={[
          {
            img: <Icon1 />,
            text: "Visualize your commerce data",
          },
          {
            img: <Icon2 />,
            text: "Create custom statistic views ",
          },
          {
            img: <Icon3 />,
            text: "Access powerful industry data",
          },
        ]}
      >
        <Dashboard2 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Purchasing"
        heading="Combined purchasing"
        description="Paleo offers powerful purchasing options that let you instantly purchase from multiple suppliers at once."
        list={[
          {
            img: <Icon1 />,
            text: "Place multiple orders in seconds",
          },
          {
            img: <Icon2 />,
            text: "Pay all your suppliers in one go",
          },
          {
            img: <Icon3 />,
            text: "Always pay in your local currency",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/combined-order.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <div className="bg-soft-info">
        <div className="container content-space-1 content-space-md-3">
          <div className="row">
            <div className="col-sm-6 col-lg-4 mb-5">
              <span className="svg-icon text-primary mb-3">
                <Abs029 />
              </span>

              <h6>Sell</h6>
              <p>
                Sell your products to customers worldwide in a matter of
                minutes, no matter if they are on Paleo or not.
              </p>
            </div>

            <div className="col-sm-6 col-lg-4 mb-5">
              <span className="svg-icon text-primary mb-3">
                <Gra010 />
              </span>

              <h6>Purchase</h6>
              <p>
                Purchase directly from partner merchants, make purchase
                requests, order from multiple merchants at once, and much more.
              </p>
            </div>

            <div className="col-sm-6 col-lg-4 mb-5">
              <span className="svg-icon text-primary mb-3">
                <Lay001 />
              </span>

              <h6>Offer trade discounts and bulk purchase options</h6>
              <p>
                Create discount trade plans and assign them to your partners for
                trade and bulk discounting.
              </p>
            </div>

            <div className="col-sm-6 col-lg-4 mb-5 mb-lg-0">
              <span className="svg-icon text-primary mb-3">
                <Teh004 />
              </span>

              <h6>Powerful e-commerce store</h6>
              <p>
                Set up your e-commerce store and start selling products with
                powerful features like increment ordering, bulk purchasing,
                combined order placing, saving to dedicated product lists,
                offering product customization, displaying product videos, and
                much more...
              </p>
            </div>

            <div className="col-sm-6 col-lg-4 mb-5 mb-sm-0">
              <span className="svg-icon text-primary mb-3">
                <Abs037 />
              </span>

              <h6>Centralized data</h6>
              <p>
                Upload your product data catalog and use it to feed e-commerce
                platforms and marketplaces, automatically alert partners when
                product information and prices change and control inventory from a
                single source.
              </p>
            </div>

            <div className="col-sm-6 col-lg-4">
              <span className="svg-icon text-primary mb-3">
                <Cod011 />
              </span>

              <h6>In depth analytics</h6>
              <p>
                Analyze data on trade, partnerships, products and more in real
                time.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className="container content-space-1 content-space-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>All about Space</h3>
          <p className="fs-6">Explore all Space guides and resources.</p>
        </div>

        <div className="row mb-5 mb-sm-5">
          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">Data insights</span>
                </div>
                <h4 className="card-title text-white mb-5">
                  State of product analytics report
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">
                    Ebooks &amp; reports
                  </span>
                </div>
                <h4 className="card-title text-white mb-5">
                  Space 2020 system architecture
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">
                    Ebooks &amp; reports
                  </span>
                </div>
                <h4 className="card-title text-white mb-5">
                  A guide to Space product materials
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>
        </div>

        <div className="text-center">
          <p className="mb-0">Want to read more?</p>
          <a className="link" href="/">
            Explore all Space resources{" "}
            <i className="bi-chevron-right small ms-1"></i>
          </a>
        </div>
      </div> */}

      <div className="container content-space-t-1 content-space-t-md-3">
        <div className="w-lg-50 text-center mx-lg-auto mb-7">
          <h3>Frequently Asked Questions</h3>
        </div>

        <div className="w-md-75 w-lg-65 mx-md-auto">
          <div className="card card-lg">
            <div className="card-body px-1 py-3 p-md-3">
              <div className="accordion accordion-flush" id="accordionFAQ">
                {faq.map((e, i) => (
                  <div key={i} className="accordion-item">
                    <div className="accordion-header" id={`heading-${i}`}>
                      <a
                        className="accordion-button collapsed px-2 py-3 p-md-3"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${i}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${i}`}
                      >
                        {e.title}
                      </a>
                    </div>
                    <div
                      id={`collapse-${i}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading-${i}`}
                      data-bs-parent="#accordionFAQ"
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: e.description }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="card-footer bg-soft-info text-center">
              <p className="mb-0">Still have questions?</p>
              <a className="link" href="/contact">
                Contact our friendly support team{" "}
                <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="shape-container">
        <div className="shape shape-bottom text-dark-blue">
          <svg
            width="3000"
            height="400"
            viewBox="0 0 3000 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 400H3000V0L0 400Z" fill="#fff" />
          </svg>
        </div>
      </div>

      {/* <div className="bg-primary-dark">
        <div className="container content-space-b-1 content-space-t-2">
          <div className="text-center mb-4">
            <h5 className="text-white">
              Thousands of world’s leading companies trust Space
            </h5>
          </div>

          <div className="row">
            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={vidadoswhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={fitbitwhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={forbeswhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={mailchimpwhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={layarwhite}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
