import React from "react";
import { Line } from "react-chartjs-2";

import usePalette from "../../hooks/usePalette";

/*
sample data

  const data = {
    colors: ["#377dff"],
    legends: ["Sales"],
    "labels": ["6:00 PM", "6:00 PM", "6:01 PM", "6:01 PM", "6:02 PM", "6:02 PM", "6:03 PM", "6:03 PM", "6:04 PM", "6:04 PM", "6:05 PM", "6:05 PM", "6:06 PM", "6:06 PM", "6:07 PM", "6:07 PM", "6:08 PM", "6:08 PM", "6:09 PM", "6:09 PM", "6:10 PM", "6:10 PM", "6:11 PM", "6:11 PM", "6:12 PM", "6:12 PM", "6:13 PM", "6:13 PM", "6:14 PM", "6:14 PM", "6:15 PM", "6:15 PM", "6:16 PM", "6:16 PM", "6:17 PM", "6:17 PM", "6:18 PM", "6:18 PM", "6:19 PM", "6:19 PM", "6:20 PM", "6:20 PM", "6:21 PM", "6:21 PM", "6:22 PM", "6:22 PM", "6:23 PM", "6:23 PM", "6:24 PM", "6:24 PM", "6:25 PM", "6:25 PM", "6:26 PM", "6:26 PM", "6:27 PM", "6:27 PM", "6:28 PM", "6:28 PM", "6:29 PM", "6:29 PM", "6:30 PM", "6:30 PM", "6:31 PM", "6:31 PM", "6:32 PM", "6:32 PM", "6:33 PM", "6:33 PM", "6:34 PM", "6:34 PM", "6:35 PM", "6:35 PM", "6:36 PM", "6:36 PM", "6:37 PM", "6:37 PM", "6:38 PM", "6:38 PM", "6:39 PM", "6:39 PM", "6:40 PM", "6:40 PM", "6:41 PM", "6:41 PM", "6:42 PM", "6:42 PM", "6:43 PM", "6:43 PM", "6:44 PM", "6:44 PM", "6:45 PM", "6:45 PM", "6:46 PM", "6:46 PM", "6:47 PM", "6:47 PM", "6:48 PM", "6:48 PM", "6:49 PM", "6:49 PM", "6:50 PM", "6:50 PM", "6:51 PM", "6:51 PM", "6:52 PM", "6:52 PM", "6:53 PM", "6:53 PM", "6:54 PM", "6:54 PM", "6:55 PM", "6:55 PM", "6:56 PM", "6:56 PM", "6:57 PM", "6:57 PM", "6:58 PM", "6:58 PM", "6:59 PM", "6:59 PM"],
    datasets: [
      {
        label: "Sales",
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#377dff",
        "borderWidth": 1.5,
        "pointRadius": 0,
        "lineTension": 0,
        data: [8516, 8520, 8547, 8575, 8588, 8604, 8601, 8595, 8617, 8612, 8607, 8632, 8636, 8641, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8662, 8670, 8667, 8658, 8660, 8657, 8648, 8645, 8647, 8652, 8637, 8647, 8640, 8667, 8658, 8660, 8713, 8703, 8705, 8747, 8620, 8635, 8680, 8675, 8667, 8620, 8645, 8645, 8625, 8635, 8645, 8665, 8660, 8670, 8640, 8650, 8640, 8635, 8645, 8695, 8703, 8705, 8640, 8650, 8640, 8620, 8610, 8640, 8630, 8610, 8620, 8600, 8590, 8610, 8620, 8600, 8610, 8605, 8610, 8660, 8665, 8664, 8640, 8645, 8650, 8610, 8620, 8630, 8670, 8675, 8674, 8660, 8665, 8650, 8610, 8612, 8607, 8632, 8636, 8641, 8638, 8641, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8662, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8632, 8636],
      }
    ],
  };
*/


const LineStrong = ({ data, height, mini }) => {

  const palette = usePalette();

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    scales: {
      "xAxes": [{
        "gridLines": {
          "display": false,
          "drawBorder": false
        },

        "ticks": {
          "labelOffset": 40,
          "maxTicksLimit": 6,
          "padding": 20,
          "maxRotation": 0,
          "minRotation": 0,
          "fontSize": 12,
          "fontColor": palette["gray-500"],
        }
      }],
      "yAxes": [{
        "display": false,
      }],
    },
  };

  if (mini) {
    options.scales.xAxes[0].ticks.display = false;
    options.scales.yAxes[0].display = false;
    options.tooltips.enabled = false;
  }

  return (
    <div className="chart" style={{ height: height || "200px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineStrong;