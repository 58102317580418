import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { alternativeContext } from "../../context/alternativeContext";
import { filterAlternativeResponse } from "../../utils/CodaUtils";
import Page404 from "../Page404";

const AlternativesDeatils = () => {
  const params = useParams();
  const { alternativedata } = useContext(alternativeContext);
  const response = filterAlternativeResponse(
    alternativedata,
    params.alternativeId
  );
  console.log("Response ", response);
  return (
    <>
      {response && Object.keys(response[0]).length > 0 ? (
        <>
          <div className="bg-soft-info">
            <div className="container content-space-1">
              <div className="w-lg-90 mx-lg-auto">
                <div className="mb-5">
                  <Link to="/alternatives">
                    <i className="bi-chevron-left small me-1"></i> Back to
                    Listings
                  </Link>
                </div>
                <div className="row align-items-md-center">
                  <div className="col-md-8 mb-5 mb-md-0">
                    <h1 className="h3">
                      {response && response[0]["c-uaMJw36pzK"]}
                    </h1>
                    <ul className="list-inline list-separator">
                      <li className="list-inline-item">
                        {response && response[0]["c-uu1imoI2Fz"]}
                      </li>
                      <li className="list-inline-item">
                        Focus: {response && response[0]["c-zipW87P_Jz"]}
                      </li>
                    </ul>
                    <div>{response && response[0]["c-UvurpxX9j9"]}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mb-10">
            <div className="container content-space-t-1">
              <div className="row mb-5 mb-md-0">
                <div className="col-sm-6 col-lg-6 mb-3 mb-sm-5 mb-lg-7">
                  {/* Card */}
                  <div className="card border card-lg h-100">
                    <div className="card-header bg-soft-info position-relative overflow-hidden">
                      <img
                        className="border bg-white rounded-circle avatar avatar-lg mb-3"
                        src={response && response[0]["c-04UWVV2AKR"]}
                        alt="Image"
                      />
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="card-title mb-0">
                          {response && response[0]["c-uaMJw36pzK"]}
                        </h5>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        {response && response[0]["c-UvurpxX9j9"]}
                      </p>
                    </div>
                  </div>
                  {/* End Card */}
                </div>
                {/* End Col */}
                <div className="col-sm-6 col-lg-6 mb-3 mb-sm-5 mb-lg-7">
                  {/* Card */}
                  <div className="card border card-lg h-100">
                    <div className="card-header bg-soft-info position-relative overflow-hidden">
                      <img
                        className="border bg-white rounded-circle avatar avatar-lg mb-3"
                        src={
                          "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/q4xyulepiupnnx6tdkxo"
                        }
                        alt="Image"
                      />
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="card-title mb-0">Paleo</h5>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        Paleo is an e-commerce technology and marketplace.
                      </p>
                    </div>
                  </div>
                  {/* End Card */}
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>

            <div class="table-responsive-sm mt-10">
              <table class="table table-lg table-striped table-borderless">
                <thead>
                  <tr class="table-align-middle">
                    <th scope="col"></th>
                    <th scope="col" class="table-text-center h5">
                      {response && response[0]["c-uaMJw36pzK"]}
                    </th>
                    <th scope="col" class="table-text-center h5">
                      Paleo
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th scope="row" class="">
                      Discount plans
                    </th>
                    {response && response[0]["c-nIBNLVMyvr"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}

                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Instant messaging and file sharing</th>
                    {response && response[0]["c-MF0HeaoWCQ"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Create shareable product lists</th>
                    {response && response[0]["c-749_w-SaCe"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" class="">
                      Online store
                    </th>
                    {response && response[0]["c-4aOJnCiMzA"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center"><i class="bi-check-circle text-primary"></i></td>
                  </tr>

                  <tr>
                    <th scope="row">View partnership relations</th>
                    {response && response[0]["c-Zl8ZW1yTl_"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      Auto import product data from other merchants
                    </th>
                    {response && response[0]["c-Xv4Zv0X8JT"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" class="">
                      Create purchase requests
                    </th>
                    {response && response[0]["c-r0HOl_XYan"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                     <td class="table-text-center"><i class="bi-check-circle text-primary"></i></td>
                  </tr>

                  <tr>
                    <th scope="row">Create sales requests</th>
                    {response && response[0]["c-zV_vmOSP-9"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Combined purchasing</th>
                    {response && response[0]["c-1enEBrXjgS"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" class="">
                      Custom statistic views
                    </th>
                    {response && response[0]["c-ST6PuxOR__"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center"><i class="bi-check-circle text-primary"></i></td>
                  </tr>

                  <tr>
                    <th scope="row">Auto verify merchant information</th>
                    {response && response[0]["c-7GsLltrZrx"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" class="">
                      Keep track of share product data
                    </th>
                    {response && response[0]["c-dRWbJvdGom"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                     <td class="table-text-center"><i class="bi-check-circle text-primary"></i></td>
                  </tr>

                  <tr>
                    <th scope="row">
                      Order data accessible by forwarding and customs agencies
                    </th>
                    {response && response[0]["c--pLuVfq6M1"] ? (
                      <td class="table-text-center">
                        <i class="bi-check-circle text-primary"></i>
                      </td>
                    ) : (
                      <td class="table-text-center">
                        <i class="bi-dash text-body"></i>
                      </td>
                    )}
                    <td class="table-text-center">
                      <i class="bi-check-circle text-primary"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* CTA */}
          <div className="container content-space-1">
            {/* Heading */}
            <div className="w-lg-65 text-center mx-lg-auto mb-7">
              <h3>Become a Paleo merchant</h3>
              <p className="fs-6">
                Try Space for free, and explore all the tools and services you
                need to start, run, and grow your business.
              </p>
            </div>
            {/* End Heading */}
            <div className="text-center">
              <a className="btn btn-primary" href="../page-login.html">
                Start free trial
              </a>
            </div>
          </div>
          {/* End CTA */}
        </>
      ) : (
        <div style={{ paddingTop: "2.5em", marginBottom: "2.5em" }}>
          <Page404 />
        </div>
      )}
    </>
  );
};

export default AlternativesDeatils;
