
import Dashboard1 from "./Dashboard1";
import Dashboard2 from "./Dashboard2";
import Dashboard3 from "./Dashboard3";
import Dashboard6 from "./Dashboard6";

import img from "../../assets/imgs/website-images/karsten-winegeart-0Wra5YYVQJE-unsplash.jpg";


const FourDashboards = () => {

  return (
    <>
      <div className="py-5 d-xs-none">
        <span className="text-cap">Commerce powertool</span>
        <h3 className="col-lg-6">We are building the commerce infrastructure of the world.</h3>
        <div className="row">
          <div className="col-lg-6">The most powerful platform that lets global merchants manage their whole commerce business in one place. </div>
          <div className="col-lg-6">Paleo allows you to keep trade of your product data in one place, manage your inventory, send purchase orders, create sales orders, create analytic dashboards, create combined orders to order from all your suppliers at once, create discount trade plans and share files with suppliers and customers instantly.</div>
        </div>
        <div className="mt-4">
          <a className="link" href="http://me.paleo-store.com/auth/sign-in" target="_blank">Get started now <i className="bi-chevron-right small ms-1"></i></a>
        </div>





        <div className="four-dashboards d-md-flex pt-5 .d-lg-none .d-xl-block">
          {/* for mobile  */}
          <div className="w-100 d-flex d-md-none">
            <div className="b1-1 img3 pb-3 d-none d-lg-flex">
              <div className="bg d-none d-sm-block">

                <Dashboard6  />
              </div>
            </div>
            <div className="b1-2">
              <div className="img1">
                <div className="bg ">
                  <Dashboard1 />
                </div>
              </div>
              <div className="img2 mt-3">
                <div className="bg d-none d-md-flex">
                  <Dashboard2 />
                </div>
              </div>
            </div>
          </div>
          {/* for mobile  */}

          {/* for above tablet  */}
          <div className="b1 d-none d-md-block">
            <div className="img1">
              <div className="bg">

                <Dashboard1 mini={true} />
              </div>
            </div>
            <div className="img3 mt-5">
              <div className="bg">
                <Dashboard2 mini={true} />
              </div>
            </div>
          </div>
          {/* for above tablet  */}


          <div className="b2 d-md-block ps-md-5">
            <div className="img2 d-none d-md-block">
              <div className="bg">
                <Dashboard3 height="250px" />
              </div>
            </div>
            <div className="img4 mt-md-5">
              <div className="bg">
                <Dashboard6  banner={img} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourDashboards;
