
const UseCaseHeader = ({
    title,
    subtitle,
    text,
    img,
}) => {
    return (
        <div className="shape-container bg-soft-info">
            <div className="container content-space-1 content-space-md-2">
                <div className="row justify-content-center align-items-lg-center mb-10">
                    <div className="col-md-6 mb-7 mb-md-0">
                        <div className="mb-7">
                            <h6 className="text-cap">{subtitle}</h6>
                            <h1 className="display-6 heading-color">{title}</h1>
                            {text}
                        </div>
                        <a
                            target="_blank"
                            className="btn btn-primary"
                            href="https://accounts.paleo-store.com/auth/sign-up"
                        >
                            Get started for free{" "}
                            <i className="bi-chevron-right small ms-1"></i>
                        </a>
                    </div>

                    <div className="col-md-5 col-md-6">
                        <img className="img-fluid rounded" src={img} alt="Paleo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseCaseHeader;
