import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const DefaultLayout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <main id="content" role="main">
        {children}
        <Outlet />
      </main>
      <Footer />
    </Fragment>
  );
};

export default DefaultLayout;
