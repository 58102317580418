import React from "react";

import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import SideBySideContent from "../../components/contents/SideBySideContent";
import ContentWithInvoice from "../../components/contents/ContentWithInvoice";
import UseCaseHeader from "../../components/cards/UseCaseHeader";
import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard3 from "../../components/cards/Dashboard3";
import Dashboard4 from "../../components/cards/Dashboard4";
import Dashboard5 from "../../components/cards/Dashboard5";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";
import AlternativeCta from "../../components/cards/AlternativeCta";
import FourFeaturesBlocks from "../../components/contents/FourFeaturesBlocks";
import { Icon1, Icon2, Icon3 } from "../../components/icons";
import FeatureDetails from "../../components/contents/FeatureDetails";

import img33 from "../../assets/imgs/website-images/img6.jpg";
import img from "../../assets/imgs/website-images/img33.jpg";
import img2 from "../../assets/imgs/website-images/analytics-dark.svg";

const sidebyside1 = {
  subtitle: "Seamless experience",
  title: "A Powertool for Retailers",
  text: "Paleo allows you to sell your products efficiently anywhere in the world. Sell B2B and B2C at any time, accept custom order requests, set up detailed discount programs, etc. Paleo allows you to sell your products efficiently anywhere in the world. Sell B2B and B2C at any time, accept custom order requests, set up detailed discount programs, and much more.",
  id: "stepFeatures",
  link: {
    href: "#none",
    text: "Learn more",
  },
  list: [
    {
      title: "Start selling B2C and B2B in seconds",
      img: img2,
    },
    {
      title: "A powerful, no-setup e-commerce store",
      img: img,
    },
    {
      title: "Accept orders and payments instantly",
      img: img2,
    },
  ],
};

const Retailers = () => {
  return (
    <>
      <UseCaseHeader
        title="Paleo for Retailers"
        subtitle="Usecase"
        text={
          <p className="lead">
            We help retailers by making trade more accessible, faster, more
            effective, and more secure worldwide. Our core focus is to provide
            the most effective trade infrastructure for businesses of all sizes.

          </p>
        }
        img={img33}
      />

      <FourFeaturesBlocks
        title={"All your purchases in one place"}
      />

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        bg="bg-soft-info"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="1"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>


      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Keep track of all your purchase orders in one place"
        description="Paleo is the most powerful platform for merchants. With Paleo, you can purchase, sell, chat with your customers and suppliers, access powerful analytics on your business, sell b2c and b2b, keep track of inventory, create discount trade plans and keep track of your inventory all one single place. "
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Advanced purchasing"
        heading="Combined orders"
        bg="bg-soft-info"

        childOrder="1"
        description="Paleo offers powerful purchasing options that let you instantly purchase from multiple suppliers at once."
        list={[
          {
            img: <Icon1 />,
            text: "Place multiple orders in seconds",
          },
          {
            img: <Icon2 />,
            text: "Pay all your suppliers in one go",
          },
          {
            img: <Icon3 />,
            text: "Always pay in your local currency",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/combined-order.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>


      <ContentMarkupSection
        childOrder={1}
        childOrder="1"
        bg="bg-soft-info"
        subtitle="ONE DATA SOURCE"
        heading="Manage retail channels from a single data source"
        description="Paleo allows you to feed product and inventory data to multiple e-commerce and other applications."
        list={[
          {
            img: <Icon1 />,
            text: "Manage all your stores from a single catalog",
          },
          {
            img: <Icon2 />,
            text: "Track your inventory in one place",
          },
          {
            img: <Icon3 />,
            text: "Update your retailers instantly",
          },
        ]}
      >
        <Dashboard5 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="COMMERCE POWERTOOL"
        heading="Your partner network, in one place"
        description="Paleo allows merchants to manage their whole commerce business in one place. You can work with partner merchants, whether they are on Paleo or not, keep track of partnership trade data, communicate with partners, share product data, and much more."
        list={[
          {
            img: <Icon1 />,
            text: "Overview merchant partnerships at all times",
          },
          {
            img: <Icon2 />,
            text: "Create and assign flexible trade programs",
          },
          {
            img: <Icon3 />,
            text: "Share product data with partners instantly",
          },
        ]}
      >
        <Dashboard4 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        childOrder="1"
        subtitle="GLOBAL COMMERCE"
        heading="Global commerce made easy"
        description="Work with partner merchants all over the world, hassle-free. Paleo brings lets you manage global commerce from a single place."
        list={[
          {
            img: <Icon1 />,
            text: "Sell and purchase globally",
          },
          {
            img: <Icon2 />,
            text: "Manage everything in one place",
          },
          {
            img: <Icon3 />,
            text: "Sell online in minutes",
          },
        ]}
      >
        <Dashboard3 />
      </ContentMarkupSection>

      {/* <div>
        <FeatureDetails
          title="Less overhead, more collaboration"
          lists={[
            {
              title: "Feature 1",
              list: [
                "Corporate-based Business",
                "Creative Services",
                "New Business Innovation",
                "Online E-commerce",
                "Real Estate",
              ],
            },
            {
              title: "Feature 2",
              list: [
                "Online E-commerce",
                "Real Estate",
                "Corporate-based Business",
                "Creative Services",
                "New Business Innovation",
              ],
            },
            {
              title: "Feature 3",
              list: [
                "Creative Services",
                "New Business Innovation",
                "Online E-commerce",
                "Real Estate",
                "Corporate-based Business",
              ],
            },
            {
              title: "Feature 4",
              list: [
                "New Business Innovation",
                "Online E-commerce",
                "Real Estate",
                "Corporate-based Business",
                "Creative Services",
              ],
            },
          ]}
        />
      </div> */}

<ContentWithInvoice />

      <SideBySideContent img={img2} dark={true} {...sidebyside1} />

   

      <AlternativeCta />
    </>
  );
};

export default Retailers;
