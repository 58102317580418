import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";

import ContentWithInvoice from "../../components/contents/ContentWithInvoice";

const Product = () => {
  return (
    <>
    <>
     <div className="bg-soft-info">
  <div className="container content-space-1">
    <div className="w-lg-75 mx-lg-auto">

      <div className="row align-items-md-center">
        <div className="col-md-6 mb-5 mb-md-0">
          <h1 className="h3">B2B</h1>
          <ul className="list-inline list-separator">
            <li className="list-inline-item">Start selling in minutes with your powerful, easy-to-use e-commerce store.</li>
          </ul>
        </div>
        {/* End Col */}
        <div className="col-md-6">
          <div className="d-md-flex justify-content-md-end">
            <a className="js-apply-tab btn btn-primary" target={"_blank"} href="https://accounts.paleo-store.com/auth/sign-up">Get started for free</a>
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
  </div>
</div>

<ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Create detailed discount programs"
        childOrder="1"
        description="Create flexible, detailed trade discount programs for your partners based on order quantity, minimum cart value, and much more..."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <div className="bg-soft-info">
        {/* Icon Blocks */}
<div className="container content-space-1">

  <div className="row">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Set custom MOQ's and increments</h5>
        </span>
        <p>Set custom minimum order and increment quantities for products and catalogs. </p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Set detailed discount programs for partners</h5>
        </span>
        <p> Create detailed, flexible trade discount and wholesale plans for your merchant partners.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

  <div className="row mt-5">
    
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Communication channels for all orders</h5>
        </span>
        <p>Every order comes with a dedicated order chat. You can send a message to your partner merchant directly from the order page. This way, you don't have to go through previous confirmations to get updates on orders.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Get paid in your local currency</h5>
        </span>
        <p>Allow customers to pay in their currency and get paid in yours—no matter where they are.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

</div>
{/* End Icon Blocks */}
      </div>


      <ContentMarkupSection
        childOrder="1"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>
     

      <ContentMarkupSection
        childOrder="0"
        bg="bg-soft-info"
        subtitle="B2B"
        heading="Full control over your product data"
        childOrder="1"
        description="Product data lives inside of the powerful Paleo universal database. You can create custom layouts for your product catalogs depending on your industry and products, and add your own data columns for more flexibility."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

     

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        bg="bg-soft-info"
        heading="Get paid in your local currency"
        childOrder="0"
        description="Allow customers to pay in their currency and get paid in yours—no matter where they are."
        list={[
          {
            img: <Icon1 />,
            text: "Allow customers to pay in their currency",
          },
          {
            img: <Icon2 />,
            text: "Get paid in your local currency",
          },
          {
            img: <Icon3 />,
            text: "All your payouts, in one place",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/get-paid-single-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentWithInvoice />


    </>

    </>
  );
};

export default Product;
