import MiniDashboard from "./MiniDashboard";


const avatar = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Lululemon_Athletica_logo.svg/2048px-Lululemon_Athletica_logo.svg.png"
import defaultBanner from "../../assets/imgs/boxed-water.jpg";

const products = [
  {
    name: "Candle light stand",
    type: "Decoration",
    price: "$120.00",
    rating: "3.4",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/10505-13-8_Jewel_Vase_-_Glass_Amber_1194x1194.jpg?v=1629993444"
  },
  {
    name: "Ceiling Lamp Spider",
    type: "Decoration",
    price: "$6.56",
    rating: "4.5",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/Cocoon-Hang-Chair_Sand1-1-800x800_692x692.jpg?v=1648484177"
  },
  {
    name: "Sweetly pink chair",
    type: "Chair",
    price: "$434.00",
    rating: "3.8",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/Sweetly-SGS-Nero-Rosa-Stool34F_100x.jpg?v=1644525841"
  },
  {
    name: "Greta Armature chair",
    type: "Chair",
    price: "$727.50",
    rating: "4.6",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/GRETAMARGHERITA_0000s_0014_greta-scr-arm_100x.jpg?v=1644525537"
  },
  {
    name: "Accento Semi-soft",
    type: "Chair",
    price: "$727.50",
    rating: "4.1",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/510a482ab02f7c28b01dd59dd20061201c573376f123e274b2e2ad5b34ab2166a12f26380e4434f46f165ce39d85399d57d8de74004eb8d6d681323e1f68a6028f59343bc84a9568c1f144e69eba229ef2bc895c4057260c93f4783_1278x853.jpg?v=1629996355"
  },
  {
    name: "Ceiling Lamp",
    type: "Decoration",
    price: "$8.56",
    rating: "4.2",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/ceiling-lamp-spider-5-still-angled-arms-serge-mouille_1_100x.jpg?v=1645399590"
  },
  {
    name: "Sweetly pink chair",
    type: "Chair",
    price: "$434.00",
    rating: "3.8",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/LeroiChairsideWS_800x_ef786987-6c70-4ad7-b2a7-d25c1824724e_692x692.png?v=1648064952"
  },
  {
    name: "Greta Armature chair",
    type: "Chair",
    price: "$727.50",
    rating: "4.6",
    image: "https://cdn.shopify.com/s/files/1/0247/4865/4643/products/NORR11Riff_Setup_7_Left_Arm_OttomanBarnum_Boucle_Col_3_692x692.jpg?v=1647962419"
  },
]




function random(min = 0, max = 100) {
  return Math.random() * (max - min + 1) + min;
}

function Filter() {
  return <div className="d-flex aic mt-1">
    <div className="ic10 bg-gray-300 me-2"></div>
    <div className="bg-gray-300" style={{
      height: "8px",
      width: `calc(100% - ${random(20, 80)}px)`
    }}></div>
  </div>
}

const Dashboard6 = ({ banner }) => {
  return (
    <MiniDashboard>
      <div className="mt-3 bg-white">
        <div className="bg-primary p-4 py-5 rounded bg-img-start bg-img-center"
          style={{ backgroundImage: `url(${banner || defaultBanner})` }}>
          <div className="d-flex aic " style={{ transform: "translateY(48px)" }}>
            <img className="avatar avatar-lg avatar-circle me-2" src={avatar} alt="Image Description"></img>
            <div className="fw-bold h6 text-white">Lululemon</div>
          </div>
        </div>
        <div className="p-2"></div>


        <div className="row gx-2">
          <div className="col-3 d-none d-md-block mt-2">
            <div className="border p-2 rounded">
              <div className="text-gray-400 fs-09 fw-bold">FILTERS</div>

              <div className="text-gray-500 fs-09 mt-2">Categories</div>
              {
                [0, 1, 2, 3, 4].map(e => <Filter key={e} />)
              }

              <div className="text-gray-500 fs-09 mt-2">Price</div>
              {
                [0, 1, 2, 3].map(e => <Filter key={e} />)
              }

              <div className="text-gray-500 fs-09 mt-2">Discount</div>
              {
                [0, 1, 2, 3, 4].map(e => <Filter key={e} />)
              }

              <div className="text-gray-500 fs-09 mt-2">Tags</div>
              <div className="d-flex flex-wrap">
                {
                  [0, 1, 3, 4].map(e => <div key={e} className="bg-gray-300 me-1 mb-1" style={{
                    height: "8px",
                    width: `${random(20, 50)}px`
                  }}></div>)
                }
              </div>
            </div>

          </div>

          <div className="col-12 col-md-9 mt-2">
            <div className="rounded">

              <div className="row gx-2">
                {
                  products.map((e, i) => <div key={i} className={`col-6 col-md-4 col-xl-3 mb-2 fs-08 ${i > 3 && "d-none d-md-block"}`}>
                    <div className="border p-2 rounded">
                      <div className="text-center">
                        <img className="avatar avatar-lg me-2" src={e.image} alt="" />
                      </div>
                      <div className="text-gray-700 fw-bold lc2">{e.name}</div>
                      <div className="text-gray-400"><small>{e.type}</small></div>
                      <div className="mt-1">Price {e.price}</div>


                      <button className="btn btn-primary w-100 p-1 mt-1 fs-08">Add</button>
                    </div>
                  </div>)
                }
              </div>

            </div>
          </div>

        </div>
      </div>
    </MiniDashboard >
  );
};

export default Dashboard6;