import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import SignUpModal from "../components/modals/Signup";
import GoTo from "../components/GoTo";
import logo from "../assets/imgs/paleo-logo-white.svg";
import "../assets/js/theme-custom";
import CodaResponse from "../services/CodaResponse";

const Footer = () => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchQuestionStatus().then((res) => {
      setStatus(res[0]["c-3fIbo_RJmF"]);
    });
  }, []);

  return (
    <>
      <footer className="bg-dark-blue border-white-10">
        <div className="container">
          <div className="row content-space-1">
            <div className="col-lg-3 mb-md-7 mb-lg-0">
              <div className="mb-5">
                <a className="navbar-brand" href="/" aria-label="Paleo">
                  <img
                    height="22px"
                    className="navbar-brand-logo"
                    src={logo}
                    alt="Paleo"
                  />
                </a>
              </div>
            </div>

            <div className="col-sm mb-7 mb-sm-0">
              <span className="text-cap text-primary-light">Product</span>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <a
                    className="link-sm link-light"
                    href="/products/online-store"
                  >
                    Online store
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/products/sell">
                    Sell
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/products/b2b">
                    B2B
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/products/b2c">
                    B2C
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/products/messaging">
                    Messaging
                  </a>
                </li>

                <li>
                  <a className="link-sm link-light" href="/products/catalogs">
                    Catalogs / Data source
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm mb-7 mb-sm-0">
              <span className="text-cap text-primary-light">Use cases</span>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <a
                    className="link-sm link-light"
                    href="/use-cases/for-brands"
                  >
                    For Brands
                  </a>
                </li>
                <li>
                  <a
                    className="link-sm link-light"
                    href="/use-cases/for-retailers"
                  >
                    For Retailers
                  </a>
                </li>
                <li>
                  <a
                    className="link-sm link-light"
                    href="/use-cases/for-manufacturers"
                  >
                    For Manufacturers
                  </a>
                </li>
                <li>
                  <a
                    className="link-sm link-light"
                    href="/use-cases/for-distributors"
                  >
                    For Distributors
                  </a>
                </li>
                <li>
                  <a
                    className="link-sm link-light"
                    href="/use-cases/for-professionals"
                  >
                    For Professionals
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm mb-7 mb-sm-0">
              <span className="text-cap text-primary-light">Company</span>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <a className="link-sm link-light" href={"/contact"}>
                    Contact
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/investors">
                    Investors
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/careers">
                    Careers{" "}
                    <span className="badge bg-info rounded-pill ms-2">
                      We're hiring
                    </span>
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/beta">
                    Beta
                  </a>
                </li>

                <li>
                  <a className="link-sm link-light" href="/api">
                    API
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    className="link-sm link-light"
                    href="https://www.paleopay.com/"
                  >
                    Paleo Pay <i className="bi-box-arrow-up-right ms-1"></i>
                  </a>
                </li>

                <li>
                  <a
                    className="link-sm link-light"
                    target="_blank"
                    href="http://3.93.194.245/"
                  >
                    Paleo order directory{" "}
                    <i className="bi-box-arrow-up-right ms-1"></i>
                  </a>
                </li>

                <li>
                  <a
                    className="link-sm link-light"
                    target="_blank"
                    href="https://github.com/orgs/Paleo-Inc/repositories"
                  >
                    Open source <i className="bi-box-arrow-up-right ms-1"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm">
              <span className="text-cap text-primary-light">Platform</span>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <a
                    className="link-sm link-light"
                    target="_blank"
                    href="http://my.paleo-store.com/auth/sign-in"
                  >
                    Web <i className="bi-box-arrow-up-right ms-1"></i>
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/status">
                    Status{" "}
                    {status && (
                      <span className="badge bg-info rounded-pill ms-2">
                        {status}
                      </span>
                    )}
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/alternatives">
                    Alternatives{" "}
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/integration">
                    Integrations{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm">
              <span className="text-cap text-primary-light">Legal</span>

              <ul className="list-unstyled list-py-1 mb-5">
                <li>
                  <a
                    className="link-sm link-light"
                    href="/legal/terms-of-service"
                  >
                    Terms of service
                  </a>
                </li>
                <li>
                  <a
                    className="link-sm link-light"
                    href="/legal/privacy-policy"
                  >
                    Privacy policy
                  </a>
                </li>
              </ul>

              <span className="text-cap text-primary-light">Resources</span>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <a className="link-sm link-light" href="/">
                    Help center
                  </a>
                </li>
                <li>
                  <a className="link-sm link-light" href="/">
                    Paleo learn
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-md-end py-5">
            <div className="col-md mb-3 mb-md-0">
              <small className="text-white mb-0">
                © Paleo {new Date().getFullYear()} - All rights
                reserved.
              </small>
            </div>

            <div className="col-md d-md-flex justify-content-md-end">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                    target="_blank"
                    href="https://www.linkedin.com/company/paleo1"
                  >
                    <i className="bi-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                    target="_blank"
                    href="https://medium.com/paleo-trade"
                  >
                    <i className="bi-medium"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                    target="_blank"
                    href="https://github.com/orgs/Paleo-Inc/repositories"
                  >
                    <i className="bi-github"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                    target="_blank"
                    href="/"
                  >
                    <i className="bi-slack"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                    target="_blank"
                    href="https://www.instagram.com/paleo_platform/?hl=en"
                  >
                    <i className="bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <SignUpModal />
      <GoTo />
    </>
  );
};

export default Footer;
