import React from "react";

const GoTo = () => {

    return (
        <button className="border-0 js-go-to go-to position-fixed" style={{visibility: 'hidden'}} data-hs-go-to-options='{ "offsetTop": 700, "position": { "init": { "right": "2rem" }, "show": { "bottom": "2rem" }, "hide": { "bottom": "-2rem" } } }'>
            <i className="bi-chevron-up"></i>
        </button>
    );
};

export default GoTo;
