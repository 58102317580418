
const FeatureDetails = ({
    title = "",
    lists
}) => {
    return (
        <div className="container content-space-1 content-space-md-3">
            <div className="w-lg-50 mb-7">
                <h3>{title}</h3>
            </div>
            <div className="row">
                {
                    lists.map((list, i) => <div key={i} className="col-sm-12 col-md-6 col-lg-3">
                        <ul className="list-checked list-checked-primary mb-0">
                            <h6>{list.title}</h6>
                            {list.list.map((e, j) => <div key={j} className="d-flex">
                                <div className="flex-shrink-0 text-primary svg-icon svg-icon-sm">
                                    <i className="bi bi-check2-circle bold h5"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">{e}</div>
                            </div>
                            )}
                        </ul>
                    </div>)
                }
            </div>
        </div>
    );
};

export default FeatureDetails;
