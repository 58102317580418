import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

import { Skeleton } from "../components/skeleton";
import img2 from "../assets/imgs/website-images/img47.jpg";
import img6 from "../assets/imgs/website-images/img47.jpg";

import { ReactComponent as Hom004 } from "../assets/vendor/duotone-icons/hom/hom004.svg";
import { ReactComponent as Hom003 } from "../assets/vendor/duotone-icons/hom/hom003.svg";
import { ReactComponent as Hom002 } from "../assets/vendor/duotone-icons/hom/hom002.svg";
import { ReactComponent as Hom001 } from "../assets/vendor/duotone-icons/hom/hom001.svg";
import { ReactComponent as Abs026 } from "../assets/vendor/duotone-icons/abs/abs026.svg";
import { ReactComponent as Abs049 } from "../assets/vendor/duotone-icons/abs/abs049.svg";
import { ReactComponent as Abs050 } from "../assets/vendor/duotone-icons/abs/abs050.svg";
import { ReactComponent as Gen013 } from "../assets/vendor/duotone-icons/gen/gen013.svg";
import { ReactComponent as Gen020 } from "../assets/vendor/duotone-icons/gen/gen020.svg";
import { ReactComponent as Gen023 } from "../assets/vendor/duotone-icons/gen/gen023.svg";
import { ReactComponent as Gen024 } from "../assets/vendor/duotone-icons/gen/gen024.svg";
import { ReactComponent as Elc007 } from "../assets/vendor/duotone-icons/elc/elc007.svg";
import { ReactComponent as Fin003 } from "../assets/vendor/duotone-icons/fin/fin003.svg";
import { ReactComponent as Med001 } from "../assets/vendor/duotone-icons/med/med001.svg";
import CodaResponse from "../services/CodaResponse";

const Careers = () => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  const codaRes = new CodaResponse();
  codaRes.fetchJobsPosition().then((res) => {
    setDataList(res);
    setLoading(false);
  });

  return (
    <>
      <div className="bg-soft-info position-relative pt-3 pt-md-0">
        <div className="container">
          <div
            className="d-none d-md-block col-md-6 position-absolute top-0 end-0 bg-img-center h-100"
            style={{ backgroundImage: `url(${img6})` }}
          ></div>
          <div className="d-md-none mb-5 mb-md-0">
            <img className="img-fluid" src={img2} alt="Paleo" />
          </div>

          <div className="row align-items-lg-center content-space-b-1 content-space-md-3">
            <div className="col-md-8 col-lg-6">
              <div className="pe-lg-3 mb-7">
                <p className="lead">
                  {" "}
                  <span>{dataList.length}</span> open positions
                </p>
                <h1 className="display-6 heading-color">
                  Help us shape the{" "}
                 future of commerce
                </h1>
              </div>

              <a className="btn btn-primary" href="#openingPositions">
                Explore roles <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container content-space-1 content-space-t-md-4 content-space-b-md-3">
        <div className="row">
          <div className="col-md-12 mb-3 mb-md-0">
            <div className="pe-md-4">
              <h4 className="mb-3">Pave the way for a new world of commerce</h4>
              <p>
                We help merchants by making trade more accessible, faster, more
                effective, and more secure worldwide. Our core focus is to
                provide the most effective trade infrastructure for businesses
                of all sizes.
              </p>
              <p>
                Paleo is committed to a diverse and inclusive workforce. Paleo
                is an equal opportunity employer and does not discriminate based
                on race, ethnicity, gender, gender identity, sexual orientation,
                protected veteran status, disability, age, or another legally
                protected status.
              </p>

              <a className="link" href="#openingPositions">
                Checkout our open positions{" "}
                <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>
          </div>

          {/* <div className="col-md-4 pt-3 pt-md-0">
            <div className="ps-md-4">
              <span className="h3 text-primary">45,000+</span>
              <p>Organizations trust Space</p>

              <hr className="my-4" />

              <span className="h3 text-primary">1,390</span>
              <p>Pieces of Feedback Shared</p>

              <hr className="my-4" />
              <span className="h3 text-primary">12,500+</span>
              <p>Resources for Humans Community Members</p>
            </div>
          </div> */}

        </div>
      </div>

      {/* <div className="container">

        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>The perks</h3>
          <p>Feel at home with a private room to call your own and access to great communal spaces, events, and facilities.</p>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-4">
            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Hom004 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Fully Furnished Studios</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Abs026 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Co-Working Spaces</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Gen013 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Community Events</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-4">
            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Hom002 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>High-End Kitchens</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Abs050 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Freshly Renovated</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Elc007 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>High-Speed Wireless</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-4">
            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Hom003 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Comfortable Lounges</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Hom001 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>On-Site Laundry</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Fin003 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <span>Simple All-Inclusive Billing</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      {/* <div className="container content-space-b-1 content-space-t-3 content-space-b-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Live your best life</h3>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-4 mb-5">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Gen013 />
              </span>

              <h5>Work-life balance</h5>
              <p>
                Flexible working and plenty of time off to unwind. We'll even
                give you your birthday off.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-5">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Gen020 />
              </span>

              <h5>Recognition</h5>
              <p>
                Peer recognition program and quarterly Hero Awards. We celebrate
                success together.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-5">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Gen023 />
              </span>

              <h5>Career development</h5>
              <p>
                Tailored career development and learning pathways. We'll help
                you unlock your potential.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-5 mb-md-0">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Med001 />
              </span>

              <h5>Staying healthy</h5>
              <p>
                A global wellness programme and private health insurance. We
                support your wellbeing.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 mb-5 mb-sm-0">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Gen024 />
              </span>

              <h5>Security for the future</h5>
              <p>
                Comprehensive health plans for you and your loved ones. We'll
                take care of you.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-4">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3">
                <Abs049 />
              </span>

              <h5>Giving back</h5>
              <p>
                Employee-led community outreach, payroll giving and a
                volunteering day.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="openingPositions"
        className="container content-space-b-1 content-space-b-md-3"
      >
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Current open positions</h3>
          <p>Help us shape the future of commerce</p>
        </div>

        {/* <form>
          <div className="row gx-2 gx-md-3 mb-7">
            <div className="col-md-4 mb-2 mb-md-0">
              <label className="form-label visually-hidden" for="searchJobCareers">
                Search job
              </label>
              <div className="input-group input-group-merge">
                <span className="input-group-prepend input-group-text">
                  <i className="bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="searchJobCareers"
                  placeholder="Search job"
                  aria-label="Search job"
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 mb-2 mb-sm-0">
              <label
                className="form-label visually-hidden"
                for="locationsJobCareers"
              >
                Select location
              </label>
              <select
                className="form-select"
                id="locationsJobCareers"
                aria-label="Select location"
              >
                <option selected>All locations</option>
                <option value="1">London</option>
                <option value="2">San Francisco</option>
                <option value="3">Others</option>
              </select>
            </div>

            <div className="col-sm-6 col-md-4">
              <label
                className="form-label visually-hidden"
                for="departmentsJobCareers"
              >
                Select department
              </label>
              <select
                className="form-select"
                id="departmentsJobCareers"
                aria-label="Select department"
              >
                <option selected>All departments</option>
                <option value="1">Software Development</option>
                <option value="2">Sales</option>
                <option value="3">Business strategy</option>
                <option value="4">Design</option>
              </select>
            </div>
          </div>
        </form> */}

        {!loading && (
          <div className="bg-soft-white d-none d-sm-block p-4">
            <div className="row">
              <div className="col-sm-4">
                <h6>Job Title</h6>
              </div>
              <div className="col-sm-2">
                <h6>Location</h6>
              </div>
              <div className="col-sm-4">
                <h6>Department</h6>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        )}

        {loading && <Skeleton type="TableRow" count={5} />}

        <div
          className="accordion accordion-flush list-group list-group-lg list-group-flush list-group-striped"
          id="accordion-career"
        >
          {dataList.map((e, index) => (
            <div key={index} className="accordion-item">
              <div className="accordion-header" id={`heading${index}`}>
                <a
                  className="accordion-button collapsed"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  data-bs-target={`#collapse${index}`}
                  aria-controls={`collapse${index}`}
                >
                  <div className="row w-100">
                    <div className="col-sm-4 mb-2 mb-sm-0">
                      <span className="h6">{e["c-mhUyyGvLg1"]}</span>
                    </div>
                    <div className="col-sm-2 mb-2 mb-sm-0 font-weight-normal">
                      {e["c-Um_0Rx6ra7"]}
                    </div>
                    <div className="col-sm-4 mb-2 mb-sm-0 font-weight-normal">
                      {e["c-FOr9fIJl8f"]}
                    </div>
                  </div>
                </a>
              </div>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordion-career"
              >
                <div className="accordion-body pt-2">
                  <div>{e["c-pa5vUxB2u0"] ? parse(e["c-pa5vUxB2u0"]) : ""}</div>

                  <div className="mt-3">
                    <span>Required kills: {e["c-QynecMpwrT"]}</span>
                  </div>

                  <div className="mt-4">
                    {e["c-y0FhRuounQ"] ? parse(e["c-y0FhRuounQ"]) : ""}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="w-lg-65 text-center mx-lg-auto mt-7">
          <p>
            Don't worry if you don't see any roles you want to apply for now.
            Register your interest to be notified of any roles that come along
            that meet your criteria.
          </p>
          <NavLink className="btn btn-primary" to="/contact">
            Register your interest
          </NavLink>
        </div> */}
      </div>
    </>
  );
};

export default Careers;
