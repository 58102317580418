import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import AlternativeCta from "../../components/cards/AlternativeCta";

import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard5 from "../../components/cards/Dashboard5";

const Product = () => {
  return (
    <>
    <>
     <div className="bg-soft-info">
  <div className="container content-space-1">
    <div className="w-lg-75 mx-lg-auto">
      
      <div className="row align-items-md-center">
        <div className="col-md-6 mb-5 mb-md-0">
          <h1 className="h3">B2C</h1>
          <ul className="list-inline list-separator">
            <li className="list-inline-item">A clean, easy to use online store with powerful features.</li>
          </ul>
        </div>
        {/* End Col */}
        <div className="col-md-6">
          <div className="d-md-flex justify-content-md-end">
          <a className="js-apply-tab btn btn-primary" target={"_blank"} href="https://accounts.paleo-store.com/auth/sign-up">Get started for free</a>
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
  </div>
</div>



<ContentMarkupSection
        childOrder="1"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>

      <div className="bg-soft-info">
        {/* Icon Blocks */}
<div className="container content-space-1">

  <div className="row">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Start an online store in minutes</h5>
        </span>
        <p>Open a robust, feature-rich power store in minutes; no set-up required.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Accepts payments instantly</h5>
        </span>
        <p>Start accepting payments for orders instantly. </p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

  <div className="row mt-5">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Rich product pages</h5>
        </span>
        <p>Display your products in all their glory with feature-rich product pages</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Always connected</h5>
        </span>
        <p>Update your product data instantly when your suppliers change it. </p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

</div>
{/* End Icon Blocks */}
      </div>


      <ContentMarkupSection
        subtitle="Start selling"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="0"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={1}
        childOrder="1"
        bg="bg-soft-info"
        subtitle="ONE DATA SOURCE"
        heading="Manage retail channels from a single data source"
        description="Paleo allows you to feed product and inventory data to multiple e-commerce and other applications."
        list={[
          {
            img: <Icon1 />,
            text: "Manage all your stores from a single catalog",
          },
          {
            img: <Icon2 />,
            text: "Track your inventory in one place",
          },
          {
            img: <Icon3 />,
            text: "Update your retailers instantly",
          },
        ]}
      >
        <Dashboard5 />
      </ContentMarkupSection>

      <AlternativeCta />
    </>

    </>
  );
};

export default Product;
