import bootstrapBundleMin from "../vendor/bootstrap/bootstrap.bundle.min.js";
import HSGoTo from "../vendor/hs-go-to/dist/hs-go-to.min.js";
import HSMegaMenu from "../vendor/hs-mega-menu/dist/hs-mega-menu.min.js";
import HsNavScroller from "../vendor/hs-nav-scroller/dist/hs-nav-scroller.min.js";
import HSShowAnimation from "../vendor/hs-show-animation/dist/hs-show-animation.min.js";
import HSStickyBlock from "../vendor/hs-sticky-block/dist/hs-sticky-block.min.js";
import HSToggleSwitch from "../vendor/hs-toggle-switch/dist/hs-toggle-switch.min.js";
import HSFileAttach from "../vendor/hs-file-attach/dist/hs-file-attach.min.js";
import HSHeader from "../vendor/hs-header/dist/hs-header.min.js";
import "../js/hs.typed";
import HSCore from "../js/hs.core";
import HSBsValidation from "./hs.bs-validation.js";

// File for your custom JavaScript
// INITIALIZATION OF MEGA MENU
// =======================================================
try {
  window.onload = function () {
    if (document.getElementsByClassName("js-mega-menu").length > 0) {
      const megaMenu = new HSMegaMenu(".js-mega-menu", {
        desktop: {
          position: "left",
        },
      });
    }

    // INITIALIZATION OF SHOW ANIMATIONS
    // =======================================================
    if (document.getElementsByClassName("js-animation-link").length > 0) {
      new HSShowAnimation(".js-animation-link");
    }

    // INITIALIZATION OF HEADER
    // =======================================================
    if (document.getElementById("header") !== null) {
      new HSHeader("#header").init();
    }
    // INITIALIZATION OF GO TO
    // =======================================================
    if (document.getElementsByClassName("js-go-to").length > 0) {
      new HSGoTo(".js-go-to");
    }
    // INITIALIZATION OF NAV SCROLLER
    // =======================================================
    if (document.getElementsByClassName("js-nav-scroller").length > 0) {
      new HsNavScroller(".js-nav-scroller");
    }
    // INITIALIZATION OF TEXT ANIMATION (TYPING)
    // =======================================================
    if (document.getElementsByClassName("js-typedjs").length > 0) {
      const typed = HSCore.components.HSTyped.init(".js-typedjs");
    }
    // INITIALIZATION OF BOOTSTRAP VALIDATION
    // =======================================================
    if (document.getElementsByClassName("js-validate").length > 0) {
      HSBsValidation.init(".js-validate", {
        onSubmit: (data) => {
          data.event.preventDefault();
          alert("Submited");
        },
      });
    }

    // INITIALIZATION OF STICKY BLOCKS
    // =======================================================
    if (document.getElementsByClassName("js-sticky-block").length > 0) {
      new HSStickyBlock(".js-sticky-block", {
        targetSelector: document
          .getElementById("header")
          .classList.contains("navbar-fixed")
          ? "#header"
          : null,
      });
    }

    // SCROLLSPY
    // =======================================================
    if (document.getElementById("navbarSettings") !== null) {
      new bootstrapBundleMin.ScrollSpy(document.body, {
        target: "#navbarSettings",
        offset: 10,
      });
    }
    // INITIALIZATION OF TOGGLE SWITCH
    // =======================================================
    if (document.getElementsByClassName("js-toggle-switch").length > 0) {
      new HSToggleSwitch(".js-toggle-switch");
    }

    // INITIALIZATION OF CUSTOM FILE
    // =======================================================
    if (document.getElementsByClassName("js-file-attach").length > 0) {
      new HSFileAttach(".js-file-attach");
    }

    // INITIALIZATION OF APPLY TAB
    // =======================================================
    if (document.getElementsByClassName("js-apply-tab").length > 0) {
      document.querySelectorAll(".js-apply-tab").forEach(($el) => {
        $el.addEventListener("click", () => {
          const $tabLink = document.querySelector("#two-tab");
          new bootstrapBundleMin.Tab($tabLink).show();

          if (window.pageYOffset > $tabLink.offsetTop) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }
        });
      });
    }
  };
} catch (ex) {
  console.log(ex);
}
