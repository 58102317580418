import React from "react";
import { NavLink } from "react-router-dom";

const SimpleFooter = () => {
  return (
    <div className="position-absolute bottom-0 start-0 end-0">
      <footer className="container py-4">
        <div className="row align-items-md-center text-center text-md-start">

            
          <div className="col-md mb-3 mb-md-0">
            <small className="text-primary mb-0">
              © Paleo. &copy; {new Date().getFullYear()} - All rights reserved.
            </small>
          </div>

          <div className="col-md d-md-flex justify-content-md-end">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a
                  className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                  target="_blank"
                  href="https://www.linkedin.com/company/paleo1"
                >
                  <i className="bi-linkedin"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                  target="_blank"
                  href="https://medium.com/paleo-trade"
                >
                  <i className="bi-medium"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                  target="_blank"
                  href="https://github.com/orgs/Paleo-Inc/repositories"
                >
                  <i className="bi-github"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                  target="_blank"
                  href="/"
                >
                  <i className="bi-slack"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn btn-icon btn-sm btn-soft-light rounded-circle"
                  target="_blank"
                  href="https://www.instagram.com/paleo_platform/?hl=en"
                >
                  <i className="bi-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SimpleFooter;
