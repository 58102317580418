import { Icon1, Icon2, Icon3 } from "../../components/icons";
import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";
import { ReactComponent as Ecm001 } from "../../assets/vendor/duotone-icons/ecm/ecm001.svg";
import { ReactComponent as Cod007 } from "../../assets/vendor/duotone-icons/cod/cod007.svg";
import { ReactComponent as Com012 } from "../../assets/vendor/duotone-icons/com/com012.svg";
// import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";
// import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";

import img from "../../assets/imgs/950x950/img7.jpg";

const ImageWithText = ({ image, title, subtitle, text }) => {
  return (

    <div className="">
      <div className="container">
        <div className="">
          <div className="row align-items-md-center">
            <div className="col-md-6 order-md-2">
              <div className="p-5 p-md-7">
                <div className="mb-7">
                  <span className="text-cap">{subtitle}</span>
                  <h3>{title}</h3>
                  {text}
                </div>
              </div>
            </div>
            <div className="col-md-6 content-space-md-1">
              <div className="w-85 w-md-100 mx-auto me-md-auto">
                <img className="img-fluid rounded shadow" src={image} alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ImageWithText;