import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Logo from "../components/logo/Logo";

const Header = () => {
  return (
    <header id="header" className="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-show-hide bg-white navbar-light" data-hs-header-options='{ "fixMoment": 500, "fixEffect": "slide" }'>
      <div className="container">
        <nav className="js-mega-menu navbar-nav-wrap">
          <Link className="navbar-brand" to="/" aria-label="">
            <Logo height="22px" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-default">
              <i className="bi-list"></i>
            </span>
            <span className="navbar-toggler-toggled">
              <i className="bi-x"></i>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
           
            <ul className="navbar-nav ">
              <Navbar />
         <br></br>
              <li className="nav-item d-flex flex-column-reverse">
                <a
                  href="http://me.paleo-store.com/auth/sign-in"
                  target={"_blank"}
                  className="btn btn-ghost-secondary btn-no-focus me-2 me-lg-0"
                // data-bs-toggle="modal"
                // data-bs-target="#signupModal"
                // data-hs-show-animation-options='{ "targetSelector": "#signupModalFormLogin", "groupName": "idForm" }'
                >
                  Log in
                </a>
                <a
                  href="https://accounts.paleo-store.com/auth/sign-up"
                  target={"_blank"}
                  className="d-lg-none btn btn-primary"
                // data-bs-toggle="modal"
                // data-bs-target="#signupModal"
                // data-hs-show-animation-options='{ "targetSelector": "#signupModalFormSignup", "groupName": "idForm" }'
                >
                  {" "}
                  Get started for free{" "}
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="https://accounts.paleo-store.com/auth/sign-up"
                  target={"_blank"}
                  className="d-none d-lg-inline-block btn btn-primary"
                // data-bs-toggle="modal"
                // data-bs-target="#signupModal"
                // data-hs-show-animation-options='{ "targetSelector": "#signupModalFormSignup", "groupName": "idForm" }'
                >
                  {" "}
                  Get started for free{" "}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
