import React, { useState } from "react";
import img1_350 from '../assets/imgs/350x350/img1.jpg';
import img2_350 from '../assets/imgs/350x350/img2.jpg';
import img3 from '../assets/imgs/350x350/img3.jpg';
import img4 from '../assets/imgs/350x350/img4.jpg';
import img4_2 from '../assets/imgs/350x350/img4.jpg';
import img6 from '../assets/imgs/350x350/img6.jpg';

import { ReactComponent as Ecm004 } from '../assets/vendor/duotone-icons/ecm/ecm004.svg';
import { ReactComponent as Com006 } from '../assets/vendor/duotone-icons/com/com006.svg';
import { ReactComponent as Fil008 } from '../assets/vendor/duotone-icons/fil/fil008.svg';
import { NavLink } from "react-router-dom";

const About = () => {
    const [toggler, setToggler] = useState(false);
    const team = [
        {
            name: "Christina Kray",
            image: img1_350,
            designation: "Founder / CEO",
            description: "Loves to chat about typography, you have been warned."
        },
        {
            name: "Jeff Fisher",
            image: img2_350,
            designation: "Project Manager",
            description: "Multidisciplinary. Drinks too much coffee. Likes sentence fragments."
        },
        {
            name: "Sophia Harrington",
            image: img3,
            designation: "Project Manager",
            description: "Loves to chat about typography, you have been warned."
        },
        {
            name: "David Forren",
            image: img4,
            designation: "Support Consultant",
            description: "Behind every great project is a great Google Slides deck."
        },
        {
            name: "Amil Evara",
            image: img4_2,
            designation: "UI/UX Designer",
            description: "Design swashbuckler. Trader of stonks. Will work for snacks."
        },
        {
            name: "Tom Lowry",
            image: img6,
            designation: "UI/UX Designer",
            description: "Loves to chat about typography, you have been warned."
        },
    ]

    return (
        <>
            <div className="overflow-hidden">
                <div className="container content-space-1 content-space-md-2">
                    <div className="row justify-content-sm-center content-space-t-2">
                        <div className="col-sm-10 col-md-8 col-lg-7">
                            <div className="text-center mb-7">
                                <h1 className="display-4 text-primary">Our mission is to make work <span className="text-info">meaningful</span></h1>
                                <p className="lead">We cut through complexity, empowering businesses to challenge the status quo, create unlimited opportunities – and change the world.</p>
                            </div>
                        </div>
                    </div>


                    <div className="text-center">
                        <NavLink className="btn btn-outline-primary" to="/careers">
                            Join us | Explore roles <i className="bi-chevron-right small ms-1"></i>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="container content-space-b-1 content-space-b-md-3">
                <div className="row justify-content-sm-center">
                    <div className="col-sm-10 col-md-8 col-lg-7">

                        <div className="mb-7">
                            <h3>Our vision</h3>
                            <p>For as long as there have been cities, the public square has been a fundamental part of the urban landscape - an open, approachable space to meet and engage with friends and neighbours. Space aims to capture this spirit of bringing people together in an exciting, welcoming environment.</p>
                        </div>

                        <div className="d-grid gap-5">
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <span className="svg-icon svg-icon-sm text-primary">
                                        <Ecm004 />
                                    </span>
                                </div>

                                <div className="flex-grow-1 ms-4">
                                    <h6>High quality Co-Living spaces</h6>
                                    <p>Our fully furnished spaces are designed and purpose-built with Co-Living in mind, featuring high-end finishes and amenities that go far beyond traditional apartment buildings.</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <span className="svg-icon svg-icon-sm text-primary">
                                        <Com006 />
                                    </span>
                                </div>

                                <div className="flex-grow-1 ms-4">
                                    <h6>Fostering vibrant communities</h6>
                                    <p>Our passion is bringing people together. Beyond creating beautiful spaces, we provide shared experiences.</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <span className="svg-icon svg-icon-sm text-primary">
                                        <Fil008 />
                                    </span>
                                </div>

                                <div className="flex-grow-1 ms-4">
                                    <h6>Simple and all-inclusive</h6>
                                    <p>We worry about the details so that our residents don't have to. From our online application process to simple, all-inclusive billing we aim to make the living experience as effortless as possible.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;