
const ImageFourFeatures = ({ image, list }) => {
  return (
    <div className="bg-soft-info p-2">
      <div className="position-relative">
        <div className="container content-space-1 content-space-md-3">
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                {
                  list.map((e, i) => <div key={i} className="col-sm-6 mb-3">
                    <div className="pe-md-3">
                      <span className="svg-icon text-primary mb-3">
                        {e.icon}
                      </span>
                      <h5>{e.title}</h5>
                      <p>{e.text}</p>
                    </div>
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="d-none d-md-block col-md-5 position-absolute top-0 end-0 bg-img-center h-100" style={{ backgroundImage: `url(${image})` }} />
        <div className="d-md-none">
          <img className="img-fluid" src={image} alt="Image Description" />
        </div>
        {/* End Image */}
      </div>
    </div>
  );
};

export default ImageFourFeatures;