import React from "react";

const ContentMarkupSection = ({ children, childOrder = 0, subtitle = "", heading = "", description = "", bg = "bg-white", list = [] }) => {

  return (
    <div className={`overflow-hidden content-space-1 ${bg}`}>
      < div className="position-relative" >
        <div className="container">
          <div className="row align-items-md-center">
            <div className={`col-md-7 order-md-${childOrder} mb-7 mb-md-0`}>
              {children}
            </div>

            <div className="col-md-5">
              <div className={`p${childOrder > 0 ? "e" : "s"}-md-5 py-md-5 p${childOrder > 0 ? "e" : "s"}-lg-10 py-lg-10`}>
                <div className="mb-7">
                  <span className="text-cap">{subtitle}</span>
                  <h3>
                    {heading}
                  </h3>
                  <p>
                    {description}
                  </p>
                </div>

                <div className="d-grid gap-3 mb-5">
                  {
                    list.map((x, index) => (
                      <div key={`list_${index.toString()}`} className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <span className="svg-icon svg-icon-sm text-primary">
                            {x?.img}
                          </span>
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <h6 className="mb-0">{x?.text}</h6>
 
                        </div>
                      </div>
                    ))
                  }
                </div>

                <a className="link" href="http://me.paleo-store.com/auth/sign-in" target="_blank">
                  Get started for free <i className="bi-chevron-right small ms-1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default ContentMarkupSection;
