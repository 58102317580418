import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import googleIcon from "../assets/svg/brands/google-icon.svg";
import capsuleprimary from "../assets/svg/brands/capsule-primary.svg";
import fitbitprimary from "../assets/svg/brands/fitbit-primary.svg";
import forbesprimary from "../assets/svg/brands/forbes-primary.svg";
import mailchimpprimary from "../assets/svg/brands/mailchimp-primary.svg";
import layarprimary from "../assets/svg/brands/layar-primary.svg";

import SideBySideContent1 from "../components/contents/SideBySideContent1";
import CodaResponse from "../services/CodaResponse";

const Beta = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchTable("grid-m3fnpc05pP", "SMefTOc0NJ").then((res) => {
      const temp = res.items.map((e) => ({
        href: e.values["c-Pe8uB5EeOj"],
        text: e.values["c-qK6fvdf2p2"],
      }));
      console.log("Temp ", temp);
      setData(temp);
    });
  }, []);

  return (
    <>
      <div className="bg-dark-blue">
        <div className="container content-space-1 content-space-t-md-3">
          <div className="mx-auto" style={{ maxWidth: "40rem" }}>
            <div className="card card-lg zi-2">
              <div className="card-body">
                <form className="js-validate need-validate" novalidate>
                  <div className="d-grid mb-4">
                    {data.map((e, i) => (
                      <a
                        href={e.href}
                        key={i}
                        target="_blank"
                        className="btn btn-primary mb-2"
                      >
                        {e.text}
                      </a>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="shape-container">
        <div className="shape shape-bottom zi-1">
          <svg
            viewBox="0 0 3000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 1000V583.723L3000 0V1000H0Z" fill="#fff" />
          </svg>
        </div>
      </div> */}

      {/* <div className="container content-space-b-1 content-space-b-md-3">
        <div className="w-lg-65 text-center mx-lg-auto">
          <div className="mb-4">
            <h5>Thousands of world's leading companies trust Space</h5>
          </div>

          <div className="row">
            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={capsuleprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={fitbitprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={forbesprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={mailchimpprimary} alt="Logo" />
            </div>

            <div className="col text-center py-3">
              <img className="avatar avatar-lg avatar-4x3" src={layarprimary} alt="Logo" />
            </div>
          </div>
        </div>
      </div> 
      
      */}
    </>
  );
};

export default Beta;
