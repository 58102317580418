import MiniDashboard from "./MiniDashboard";
import World from "../charts/World";

const PartnerMapData = {
  IN: { Partners: 2 },
  DE: { Partners: 3 },
  FR: { Partners: 9 },
  US: { Partners: 2 },
  ES: { Partners: 4 },
  IT: { Partners: 2 },
  AT: { Partners: 274 },
  BE: { Partners: 12 },
  MX: { Partners: 42 },
  BR: { Partners: 1 },
  TZ: { Partners: 2 },
  MR: { Partners: 7 },
  CA: { Partners: 4 },
  JP: { Partners: 87 },
  AU: { Partners: 2 },
  CN: { Partners: 132 },
  NO: { Partners: 4 },
  PT: { Partners: 2 },
  TR: { Partners: 23 },
  AU: { Partners: 7 }
};

const Dashboard3 = ({ height = "250px" }) => {
  return (
    <MiniDashboard>
      <div className="mt-3 border rounded z-3">
        <World mapData={PartnerMapData} height={height} />
      </div>
    </MiniDashboard>
  );
};

export default Dashboard3;
