import GoTo from "../../components/GoTo";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";



import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard2 from "../../components/cards/Dashboard2";
import Dashboard3 from "../../components/cards/Dashboard3";
import Dashboard4 from "../../components/cards/Dashboard4";
import Dashboard5 from "../../components/cards/Dashboard5";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";
import Dashboard8 from "../../components/cards/Dashboard8";
import Dashboard9 from "../../components/cards/Dashboard9";
// import StripeClone from "../../components/cards/StripeClone";
import FourDashboards from "../../components/cards/FourDashboards";
import AlternativeCta from "../../components/cards/AlternativeCta";
import FeatureGeneral from "../../components/contents/FeatureGeneral";
import FeatureDetails from "../../components/contents/FeatureDetails";
import FeatureSlider from "../../components/sliders/FeatureSlider";


import MiniInvoice from "../../components/cards/MiniInvoice";

export default function Test() {
  return (
    <div className="container content-space-1 content-space-md-3">
      <div className="row">
        <div className="col-12 col-lg-4 py-5">
          <MiniInvoice />

        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard1 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard2 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard3 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard4 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard5 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard6
            banner="https://mir-s3-cdn-cf.behance.net/project_modules/fs/68f37563114549.5aa6a34ab89b8.jpg"
          />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard7 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Dashboard8 />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <h5>Dashboard9</h5>
          <Dashboard9 />
        </div>

        <div className="col-12 col-lg-12">{/* <StripeClone /> */}</div>

        <div>
          <FourDashboards />
        </div>

        <div>
          <AlternativeCta />
        </div>

        <div>
          <FeatureGeneral />
        </div>

        <div className="my-5 mt-6">
          <FeatureSlider />
        </div>

        <div>
          <FeatureDetails
            title="Less overhead, more collaboration"
            lists={[
              {
                title: "Feature 1",
                list: [
                  "Corporate-based Business",
                  "Creative Services",
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                ],
              },
              {
                title: "Feature 2",
                list: [
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                  "Creative Services",
                  "New Business Innovation",
                ],
              },
              {
                title: "Feature 3",
                list: [
                  "Creative Services",
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                ],
              },
              {
                title: "Feature 4",
                list: [
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                  "Creative Services",
                ],
              },
            ]}
          />
        </div>
      </div>

      <GoTo />

      <ContentMarkupSection
        childOrder={1}
        subtitle="Combine and customize"
        heading="Create an on-site experience your customers will love"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M15.6 5.59998L20.9 10.9C21.3 11.3 21.3 11.9 20.9 12.3L17.6 15.6L11.6 9.59998L15.6 5.59998ZM2.3 12.3L7.59999 17.6L11.6 13.6L5.59999 7.59998L2.3 10.9C1.9 11.3 1.9 11.9 2.3 12.3Z"
                  fill="#035A4B"
                ></path>{" "}
                <path
                  opacity="0.3"
                  d="M17.6 15.6L12.3 20.9C11.9 21.3 11.3 21.3 10.9 20.9L7.59998 17.6L13.6 11.6L17.6 15.6ZM10.9 2.3L5.59998 7.6L9.59998 11.6L15.6 5.6L12.3 2.3C11.9 1.9 11.3 1.9 10.9 2.3Z"
                  fill="#035A4B"
                ></path>{" "}
              </svg>
            ),
            text: "All-in-one",
          },
          {
            img: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M18.4 17H7C5.9 17 5 16.1 5 15C5 13.9 5.9 13 7 13H17C19.2 13 21 11.2 21 9C21 6.8 19.2 5 17 5H5.60001V7H17C18.1 7 19 7.9 19 9C19 10.1 18.1 11 17 11H7C4.8 11 3 12.8 3 15C3 17.2 4.8 19 7 19H18.4V17Z"
                  fill="#035A4B"
                ></path>{" "}
                <path
                  opacity="0.3"
                  d="M18.4 22V14L21.7 17.3C22.1 17.7 22.1 18.3 21.7 18.7L18.4 22ZM5.6 10V2L2.3 5.29999C1.9 5.69999 1.9 6.30001 2.3 6.70001L5.6 10Z"
                  fill="#035A4B"
                ></path>{" "}
              </svg>
            ),
            text: "Less routine – more creativity",
          },
          {
            img: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  opacity="0.3"
                  d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z"
                  fill="#035A4B"
                ></path>{" "}
                <path
                  d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z"
                  fill="#035A4B"
                ></path>{" "}
              </svg>
            ),
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <ImageBrowserBlock imgUrl={"../analyticdashboard.svg"} />
      </ContentMarkupSection>
    </div>
  );
}
