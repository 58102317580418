import { Icon1, Icon2, Icon3 } from "../../components/icons";
import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";
import { ReactComponent as Ecm001 } from "../../assets/vendor/duotone-icons/ecm/ecm001.svg";
import { ReactComponent as Cod007 } from "../../assets/vendor/duotone-icons/cod/cod007.svg";
import { ReactComponent as Com012 } from "../../assets/vendor/duotone-icons/com/com012.svg";
// import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";
// import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";


const FourFeaturesBlocks = ({ title }) => {
  return (

    <div className="">
      <div className="container content-space-1">
        <div className="row">
          <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
            <span className="svg-icon text-primary mb-3">
              <Abs028 width={50} height={50}></Abs028>
            </span>

            <h6>A powerful e-commerce store</h6>
            <p>Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups.</p>
        
          </div>
          {/* End Col */}
          <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
            <span className="svg-icon text-primary mb-3">

              <Ecm001 width={50} height={50}></Ecm001>
            </span>
            <h6>Manage e-commerce stores from a single data source</h6>
            <p>Paleo allows you to feed product and inventory data to multiple e-commerce and other applications.</p>
       
          </div>
          {/* End Col */}
          <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
            <span className="svg-icon text-primary mb-3">

              <Cod007 width={50} height={50}></Cod007>
            </span>
            <h6>Always stay up to date with catalog data from your suppliers</h6>
            <p>Use and update product data from your partners instantly without sharing data files.
            </p>
       
          </div>
          {/* End Col */}
          <div className="col-sm-6 col-lg-3">
            <span className="svg-icon text-primary mb-3">

              <Com012 width={50} height={50}></Com012>
            </span>
            <h6>Powerful direct messaging and file sharing</h6>
            <p>Communicate with suppliers directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to other organizations in group channels, share files and images, and much more.
            </p>
           
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
    </div>


  );
};

export default FourFeaturesBlocks;