import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import Dashboard8 from "../../components/cards/Dashboard8";
import Dashboard5 from "../../components/cards/Dashboard5";

const Product = () => {
  return (
    <>
      <>
        <div className="bg-soft-info">
          <div className="container content-space-1">
            <div className="w-lg-75 mx-lg-auto">

              <div className="row align-items-md-center">
                <div className="col-md-6 mb-5 mb-md-0">
                  <h1 className="h3">Catalogs</h1>
                  <ul className="list-inline list-separator">
                    <li className="list-inline-item">
                      A clean, easy to use online store with powerful features.
                    </li>
                  </ul>
                </div>
                {/* End Col */}
                <div className="col-md-6">
                  <div className="d-md-flex justify-content-md-end">
                    <a
                      className="js-apply-tab btn btn-primary"
                      target={"_blank"}
                      href="https://accounts.paleo-store.com/auth/sign-up"
                    >
                      Get started for free
                    </a>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
          </div>
        </div>

        <ContentMarkupSection
          childOrder={0}
          subtitle="Catalogs"
          heading="Scalable, flexible product data"
          childOrder="1"
          description="Product data lives inside of the powerful Paleo universal database. You can create custom layouts for your product catalogs depending on your industry and products, and add your own data columns for more flexibility.

          "
          list={[
            {
              img: <Icon1 />,
              text: "Add any data attributes to your products",
            },
            {
              img: <Icon2 />,
              text: "Easily share your catalog data with your partners",
            },
            {
              img: <Icon3 />,
              text: "Sell products B2B and B2C",
            },
          ]}
        >
          <Dashboard8 />
        </ContentMarkupSection>

        <div className="bg-soft-info">
          {/* Icon Blocks */}
          <div className="container content-space-1">
            <div className="row">
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Share catalog data with other merchants</h5>
                  </span>
                  <p>
                    Share your product data with your partners instantly, and
                    alert them whenever changes happen.
                  </p>
                </div>
              </div>
              {/* End Col */}
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Easily import product data from other merchants</h5>
                  </span>
                  <p>
                    You can easily import product data from a partner merchant
                    to your catalog without the need to update data files.
                  </p>
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}

            <div className="row mt-5">
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Manage your catalog in one place</h5>
                  </span>
                  <p>
                    Manage your product data directly from your dashboard
                    without the need to export and import data files.
                  </p>
                </div>
              </div>
              {/* End Col */}
              <div className="col-md-6 mb-5 mb-md-0">
                <div className="me-md-6">
                  <span className="svg-icon svg-icon-lg text-primary mb-3">
                    <h5>Keep track of partner data</h5>
                  </span>
                  <p>
                    Paleo allows you to share item data with partners directly
                    and offers the possibility to keep track of how this data is
                    used. For example, brands can keep track of the price at
                    which their retailers sell a product.
                  </p>
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Icon Blocks */}
        </div>

        <ContentMarkupSection
          childOrder="0"
          subtitle="Combine and customize"
          heading="Manage retail channels from a single data source"
          description="Paleo allows you to feed product and inventory data to multiple e-commerce and other applications."
          list={[
            {
              img: <Icon1 />,
              text: "Manage all your stores from a single catalog",
            },
            {
              img: <Icon2 />,
              text: "Track your inventory in one place",
            },
            {
              img: <Icon3 />,
              text: "Sell products B2B and B2C",
            },
          ]}
        >
          <Dashboard5 />
        </ContentMarkupSection>

        <ContentMarkupSection
          childOrder={0}
          subtitle="Stay in sync"
          bg="bg-soft-info"
          heading="Always stay up to date with catalog data from your suppliers"
          childOrder="1"
          description="Share and update product catalog data with your partners instantly to create a unified catalog network."
          list={[
            {
              img: <Icon1 />,
              text: "Automatically share product data with partners",
            },
            {
              img: <Icon2 />,
              text: "Alert partners on product data changes",
            },
            {
              img: <Icon3 />,
              text: "Keep track of how your products are sold by merchants",
            },
          ]}
        >
          <div>
            <img
              className="device-browser-img"
              src={"../../SVG-Graphics/data-up-to-date.svg"}
              alt="Image Description"
            />
          </div>
        </ContentMarkupSection>
      </>
    </>
  );
};

export default Product;
