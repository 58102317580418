import Logo from "../logo/Logo";

const MiniDashboard = ({ children }) => {
    return (
        <div className="p-2 p-md-4 w-100 device-browser fs-08 bg-white border rounded shadow">
            {/*  header */}
            <div className="d-flex aic jcsb">
                <Logo height={16} />

                <div className="bg-light p-2 w-50 w-md-30 rounded">
                    <i className="bi-search me-2"></i>
                    <span>Search</span>
                </div>

                <div className="d-md-flex line-height-1 aic d-none">
   
                    <div className="">
                        <div className="fw-bold">John Doe</div>
                        <small className="text-gray-500 figure-caption">My organization</small>
                    </div>
                </div>
            </div>
            {/*  END header */}


            {children}
        </div>
    );
};

export default MiniDashboard;
