import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import Dashboard7 from "../../components/cards/Dashboard7";

const Product = () => {
  return (
    <>
    <>
     <div className="bg-soft-info">
  <div className="container content-space-1">
    <div className="w-lg-75 mx-lg-auto">

      <div className="row align-items-md-center">
        <div className="col-md-6 mb-5 mb-md-0">
          <h1 className="h3">Messaging</h1>
          <ul className="list-inline list-separator">
            <li className="list-inline-item">Communicate with partners and merchants directly from your dashboard.</li>
          </ul>
        </div>
        {/* End Col */}
        <div className="col-md-6">
          <div className="d-md-flex justify-content-md-end">
          <a className="js-apply-tab btn btn-primary" target={"_blank"} href="https://accounts.paleo-store.com/auth/sign-up">Get started for free</a>
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
  </div>
</div>

<ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <div className="bg-soft-info">
        {/* Icon Blocks */}
<div className="container content-space-1">

  <div className="row">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Instant communication and file sharing</h5>
        </span>
        <p>Communicate and share files with your partner merchants in real-time.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Communication channels with all your partners</h5>
        </span>
        <p>Paleo creates a dedicated group chat channel that includes all members of both organizations for efficient communication.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

  <div className="row mt-5">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Communication channels for all orders</h5>
        </span>
        <p>Every order comes with a dedicated order chat. You can send a message to your partner merchant directly from the order page. This way, you don't have to go through previous confirmations to get updates on orders.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Forget email</h5>
        </span>
        <p>Stop going through countless emails to find information on orders or partners. Paleo keeps all your conversations in one place.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

</div>
{/* End Icon Blocks */}
      </div>


    </>

    </>
  );
};

export default Product;
