

import React from "react";

const ImageBrowserBlock = ({ imgUrl }) => {

    return (
        <div className="position-md-absolute top-md-50 start-md-50 translate-middle-md-y">
            <figure className="device-browser">
                <div className="device-browser-header">
                    <div className="device-browser-header-btn-list">
                        <span className="device-browser-header-btn-list-btn"></span>
                        <span className="device-browser-header-btn-list-btn"></span>
                        <span className="device-browser-header-btn-list-btn"></span>
                    </div>
                    <div className="device-browser-header-browser-bar">
                        www.my.paleo-store.com
                    </div>
                </div>

                <div className="">
                    <img
                        className="device-browser-img"
                        src={imgUrl}
                        alt="Image Description"
                    />
                </div>
            </figure>
        </div>
    );
};

export default ImageBrowserBlock;
