import { Line } from 'react-chartjs-2';
import usePalette from "../../hooks/usePalette";

const LineChart = ({ data, height, mini }) => {

  const palette = usePalette();

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          "gridLines": {
            "display": false,
            "drawBorder": false
          },
          "ticks": {
            "maxTicksLimit": 3,
          }
        },
      ],
      yAxes: [
        {
          "gridLines": {
            "display": false,
            "color": "#ddd",
            "drawBorder": false,
            "zeroLineColor": "#ddd"
          },
        },
      ],
    },
  };

  if (mini) {
    options.scales.xAxes[0].ticks.display = false;
    options.scales.yAxes[0].display = false;
    options.tooltips.enabled = false;
  }

  return (
    <div className="chart" style={{ height: height || "200px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;