import React, { useEffect, useState } from "react";

import CodaResponse from "../../services/CodaResponse";

import { ReactComponent as Com012 } from "../../assets/vendor/duotone-icons/com/com012.svg";
import { ReactComponent as Com013 } from "../../assets/vendor/duotone-icons/com/com013.svg";
import { ReactComponent as Abs028 } from "../../assets/vendor/duotone-icons/abs/abs028.svg";
import { ReactComponent as Abs026 } from "../../assets/vendor/duotone-icons/abs/abs026.svg";
import { ReactComponent as Abs027 } from "../../assets/vendor/duotone-icons/abs/abs027.svg";
import img4 from "../../assets/imgs/240x480/img4.jpg";
import img2 from "../../assets/imgs/240x480/img2.jpg";
import img1 from "../../assets/imgs/800x500/img1.jpg";
import { ReactComponent as Abs029 } from "../../assets/vendor/duotone-icons/abs/abs029.svg";
import { ReactComponent as Gra010 } from "../../assets/vendor/duotone-icons/gra/gra010.svg";
import { ReactComponent as Lay001 } from "../../assets/vendor/duotone-icons/lay/lay001.svg";
import { ReactComponent as Teh004 } from "../../assets/vendor/duotone-icons/teh/teh004.svg";
import { ReactComponent as Abs037 } from "../../assets/vendor/duotone-icons/abs/abs037.svg";
import { ReactComponent as Cod011 } from "../../assets/vendor/duotone-icons/cod/cod011.svg";
import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import ImageFourFeatures from "../../components/contents/ImageFourFeatures";

import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard2 from "../../components/cards/Dashboard2";
import Dashboard3 from "../../components/cards/Dashboard3";
import Dashboard4 from "../../components/cards/Dashboard4";
import Dashboard5 from "../../components/cards/Dashboard5";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";

import { Icon1, Icon2, Icon3 } from "../../components/icons";
import ImageWithText from "../../components/contents/ImageWithText";

const HomeTest = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const codaRes = new CodaResponse();
    codaRes.fetchTable("grid-EQPrufVXVa", "sboeU84ivk").then((res) => {
      let temp = res.items.map((e) => ({
        title: e.values["c-e-gmDpQooH"],
        description: e.values["c-zUM7PEHryA"],
        live: e.values["c-SFEd8Im_xA"],
      }));

      temp = temp.filter((e) => e.live && e.title.length > 1);

      setFaq(temp);
    });
  }, []);

  return (
    <>
      <div>
        {/* Hero */}
        <div className="bg-primary-dark">
          <div className="container position-relative zi-2 content-space-b-1 content-space-t-2 content-space-md-3">
            <div className="row justify-content-center align-items-md-center mb-7">
              <div className="col-md-6 mb-7 mb-md-0">
                {/* Heading */}
                <div className="pe-lg-3 mb-7">
                  <h1 className="display-3 text-white mb-3 mb-md-5">The most powerful platform for commerce</h1>
                  <p className="lead text-white-70">Built for ambitious merchants dealing with consumer goods, Paleo makes trade easy, reliable and secure.</p>
                  <p className="lead text-white-70">
                    Paleo allows you to keep trade of your product data in one place, manage your inventory, send purchase orders, create sales orders, create analytic dashboards, create “combined orders” to order from all your suppliers at once, create discount trade plans, what and share files with suppliers and customers instantly, and much more…</p>
                </div>
                {/* End Heading */}
                <div className="d-grid d-sm-flex gap-3">
                  <a className="btn btn-primary" href="./page-login.html">Start now <i className="bi-chevron-right small ms-1" /></a>
                  <a className="btn btn-light" href="https://www.youtube.com/watch?v=0qisGSwZym4" role="button" data-fslightbox="youtube-video">
                    Watch a demo <i className="bi-play-circle-fill ms-1" />
                  </a>
                </div>
              </div>
              {/* End Col */}
              <div className="col-sm-10 col-md-6">
                <Dashboard6 />
              </div>
              {/* End Col */}
            </div>

          </div>
          <div className="lines lines-light">
            <div className="lines-container">
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
              <div className="lines-item"></div>
            </div>
          </div>
        </div>
        {/* End Hero */}
        {/* Shape */}
        <div className="shape-container">
          <div className="shape shape-bottom zi-1">
            <svg
              viewBox="0 0 3000 600"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 600V350.234L3000 0V600H0Z" fill="#fff" />
            </svg>
          </div>
        </div>
        {/* End Shape */}
      </div>



      <div className="container content-space-t-1 content-space-t-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Take the best path forward</h3>
          <p className="fs-6">
            Actionable strategies and business marketing advice help you to grow
            faster.
          </p>
        </div>

        <div className="js-nav-scroller hs-nav-scroller-horizontal">
          <span
            className="hs-nav-scroller-arrow-prev"
            style={{ display: "none" }}
          >
            <a className="hs-nav-scroller-arrow-link" href="javascript:;">
              <i className="bi-chevron-left"></i>
            </a>
          </span>

          <span
            className="hs-nav-scroller-arrow-next"
            style={{ display: "none" }}
          >
            <a className="hs-nav-scroller-arrow-link" href="javascript:;">
              <i className="bi-chevron-right"></i>
            </a>
          </span>

          <ul className="nav nav-pills mb-7" id="featuresTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                href="#featuresOne"
                id="featuresOne-tab"
                data-bs-toggle="tab"
                data-bs-target="#featuresOne"
                role="tab"
                aria-controls="featuresOne"
                aria-selected="true"
                style={{ width: "22rem" }}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Abs028 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="nav-title">All-in-one workspace</h6>
                    <p className="nav-text text-body">
                      Create a business, whether you’ve got a fresh idea.
                    </p>
                  </div>
                </div>
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                href="#featuresTwo"
                id="featuresTwo-tab"
                data-bs-toggle="tab"
                data-bs-target="#featuresTwo"
                role="tab"
                aria-controls="featuresTwo"
                aria-selected="false"
                style={{ width: "22rem" }}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Abs026 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="nav-title">
                      Automation on a whole new level
                    </h6>
                    <p className="nav-text text-body">
                      Use automation to scale campaigns profitably and save time
                      doing it.
                    </p>
                  </div>
                </div>
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                href="#featuresThree"
                id="featuresThree-tab"
                data-bs-toggle="tab"
                data-bs-target="#featuresThree"
                role="tab"
                aria-controls="featuresThree"
                aria-selected="false"
                style={{ width: "22rem" }}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <Abs027 />
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="nav-title">
                      Solving problems for every team
                    </h6>
                    <p className="nav-text text-body">
                      One tool for your company to share knowledge and ship
                      projects.
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>



        <div className="tab-content" id="featuresTabContent">
          <div
            className="tab-pane fade show active"
            id="featuresOne"
            role="tabpanel"
            aria-labelledby="featuresOne-tab"
          >
            <div className="devices">
              <figure className="device-browser mx-auto">
                <div className="device-browser-header">
                  <div className="device-browser-header-btn-list">
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                  </div>
                  <div className="device-browser-header-browser-bar">
                    www.my.paleo-store.com
                  </div>
                </div>

                <div className="device-browser-frame">
                  <img
                    className="device-browser-img"
                    src={"../../analyticdashboard.svg"}
                    alt="Paleo"
                  />
                </div>
              </figure>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="featuresTwo"
            role="tabpanel"
            aria-labelledby="featuresTwo-tab"
          >
            <div className="devices">
              <figure className="device-mobile">
                <div className="device-mobile-frame">
                  <img className="device-mobile-img" src={img4} alt="Paleo" />
                </div>
              </figure>

              <figure className="device-browser mx-auto">
                <div className="device-browser-header">
                  <div className="device-browser-header-btn-list">
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                  </div>
                  <div className="device-browser-header-browser-bar">
                    www.my.paleo-store.com
                  </div>
                </div>

                <div className="device-browser-frame">
                  <img
                    className="device-browser-img"
                    src="../../analyticdashboard.svg"
                    alt="Paleo"
                  />
                </div>
              </figure>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="featuresThree"
            role="tabpanel"
            aria-labelledby="featuresThree-tab"
          >
            <div className="devices">
              <figure className="device-mobile">
                <div className="device-mobile-frame">
                  <img className="device-mobile-img" src={img2} alt="Paleo" />
                </div>
              </figure>

              <figure className="device-browser">
                <div className="device-browser-header">
                  <div className="device-browser-header-btn-list">
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                    <span className="device-browser-header-btn-list-btn"></span>
                  </div>
                  <div className="device-browser-header-browser-bar">
                    www.my.paleo-store.com
                  </div>
                </div>

                <div className="device-browser-frame">
                  <img className="device-browser-img" src={img1} alt="Paleo" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <ContentMarkupSection
        childOrder={1}
        subtitle="Combine and customize"
        heading="Create an on-site experience your customers will love"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <ImageBrowserBlock imgUrl={"../../analyticdashboard.svg"} />
      </ContentMarkupSection>


      <ContentMarkupSection
        childOrder={1}
        subtitle="Combine and customize"
        heading="Keep track of all your purchase orders in one place"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <ImageBrowserBlock imgUrl={"../../SVG-dashboard/sales-white.svg"} />
      </ContentMarkupSection>

      <ImageFourFeatures></ImageFourFeatures>

      <ImageWithText></ImageWithText>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Your entire commerce business in one place"
        description="Paleo is the most powerful platform for merchants. With Paleo, you can purchase, sell, chat with your customers and suppliers, access powerful analytics on your business, sell b2c and b2b, keep track of inventory, create discount trade plans and keep track of your inventory all one single place. "
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Create an on-site experience your customers will love"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard2 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Create an on-site experience your customers will love"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard3 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Map"
        description="We have taken all the guess work out of building your customer experience. You can easily create an interface that is on brand and intuitive for your customers to use."
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard4 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Combine and customize"
        heading="Manage e-commerce stores from a single data source"
        description="Paleo allows you to feed product and inventory data to multiple e-commerce and other applications."
        list={[
          {
            img: <Icon1 />,
            text: "Manage all your stores from a single catalog",
          },
          {
            img: <Icon2 />,
            text: "Track your inventory in one place",
          },
          {
            img: <Icon3 />,
            text: "---",
          },
        ]}
      >
        <Dashboard5 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Combined orders"

        childOrder="0"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/combined-order.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Combined orders"
        childOrder="1"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always pay in your own currency"
        childOrder="0"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/pay-suppliers-in-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="1"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Get paid in your local currency"
        childOrder="0"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/get-paid-single-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="1"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/data-up-to-date.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <div className="container content-space-1 content-space-md-3">
        <div className="row">
          <div className="col-sm-6 col-lg-4 mb-5">
            <span className="svg-icon text-primary mb-3">
              <Abs029 />
            </span>

            <h6>Sell</h6>
            <p>
              Sell your products to customers worldwide in a matter of minutes,
              no matter if they are on Paleo or not.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4 mb-5">
            <span className="svg-icon text-primary mb-3">
              <Gra010 />
            </span>

            <h6>Purchase</h6>
            <p>
              Purchase directly from partner merchants, make purchase requests,
              order from multiple merchants at once, and much more.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4 mb-5">
            <span className="svg-icon text-primary mb-3">
              <Lay001 />
            </span>

            <h6>Offer trade discounts and bulk purchase options</h6>
            <p>
              Create discount trade plans and assign them to your partners for
              trade and bulk discounting.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4 mb-5 mb-lg-0">
            <span className="svg-icon text-primary mb-3">
              <Teh004 />
            </span>

            <h6>Powerful e-commerce store</h6>
            <p>
              Set up your e-commerce store and start selling products with
              powerful features like increment ordering, bulk purchasing,
              combined order placing, saving to dedicated product lists,
              offering product customization, displaying product videos, and
              much more...
            </p>
          </div>

          <div className="col-sm-6 col-lg-4 mb-5 mb-sm-0">
            <span className="svg-icon text-primary mb-3">
              <Abs037 />
            </span>

            <h6>Centralized data</h6>
            <p>
              Upload your product data catalog and use it to feed e-commerce
              platforms and marketplaces, automatically alert partners when
              product information and prices change, control inventory from a
              single source, and much more.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <span className="svg-icon text-primary mb-3">
              <Cod011 />
            </span>

            <h6>In depth analytics</h6>
            <p>
              Analyze data on trade, partnerships, products and more in real
              time.
            </p>
          </div>
        </div>
      </div>

      <div className="container content-space-1 content-space-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>All about Space</h3>
          <p className="fs-6">Explore all Space guides and resources.</p>
        </div>

        <div className="row mb-5 mb-sm-5">
          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">Data insights</span>
                </div>
                <h4 className="card-title text-white mb-5">
                  State of product analytics report
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">
                    Ebooks &amp; reports
                  </span>
                </div>
                <h4 className="card-title text-white mb-5">
                  Space 2020 system architecture
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
            <a
              className="card card-lg card-transition bg-primary-dark"
              href="/"
              style={{ maxWidth: "22rem" }}
            >
              <div className="card-body">
                <div className="mb-3">
                  <span className="badge bg-soft-light">
                    Ebooks &amp; reports
                  </span>
                </div>
                <h4 className="card-title text-white mb-5">
                  A guide to Space product materials
                </h4>
                <span className="card-link link-light">
                  Learn more <i className="bi-chevron-right small ms-1"></i>
                </span>
                <div className="position-absolute bottom-0 start-0 end-0"></div>
              </div>
            </a>
          </div>
        </div>

        <div className="text-center">
          <p className="mb-0">Want to read more?</p>
          <a className="link" href="/">
            Explore all Space resources{" "}
            <i className="bi-chevron-right small ms-1"></i>
          </a>
        </div>
      </div>

      <div className="container content-space-t-1 content-space-t-md-3">
        <div className="w-lg-50 text-center mx-lg-auto mb-7">
          <h3>Frequently Asked Questions</h3>
        </div>

        <div className="w-md-75 w-lg-65 mx-md-auto">
          <div className="card card-lg">
            <div className="card-body">
              <div className="accordion accordion-flush" id="accordionFAQ">
                {faq.map((e, i) => (
                  <div key={i} className="accordion-item">
                    <div className="accordion-header" id={`heading-${i}`}>
                      <a
                        className="accordion-button collapsed"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${i}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${i}`}
                      >
                        {e.title}
                      </a>
                    </div>
                    <div
                      id={`collapse-${i}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading-${i}`}
                      data-bs-parent="#accordionFAQ"
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: e.description }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="card-footer bg-soft-info text-center">
              <p className="mb-0">Still have questions?</p>
              <a className="link" href="/contact">
                Contact our friendly support team{" "}
                <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="shape-container">
        <div className="shape shape-bottom text-primary-dark">
          <svg
            width="3000"
            height="400"
            viewBox="0 0 3000 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 400H3000V0L0 400Z" fill="#fff" />
          </svg>
        </div>
      </div>

      {/* <div className="bg-primary-dark">
        <div className="container content-space-b-1 content-space-t-2">
          <div className="text-center mb-4">
            <h5 className="text-white">
              Thousands of world’s leading companies trust Space
            </h5>
          </div>

          <div className="row">
            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={vidadoswhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={fitbitwhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={forbeswhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={mailchimpwhite}
                alt="Logo"
              />
            </div>

            <div className="col py-3">
              <img
                className="avatar avatar-xl avatar-4x3"
                src={layarwhite}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HomeTest;
