import React from "react";

import UseCaseHeader from "../../components/cards/UseCaseHeader";
import { Icon1, Icon2, Icon3 } from "../../components/icons";
import Cta from "../../components/contents/Cta";

import img33 from "../../assets/imgs/website-images/img58.jpg";

import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard7 from "../../components/cards/Dashboard7";
import AlternativeCta from "../../components/cards/AlternativeCta";
import FeatureDetails from "../../components/contents/FeatureDetails";
import FourFeaturesBlocks from "../../components/contents/FourFeaturesBlocks";

import Dashboard2 from "../../components/cards/Dashboard2";
import Dashboard3 from "../../components/cards/Dashboard3";
import Dashboard4 from "../../components/cards/Dashboard4";
import Dashboard6 from "../../components/cards/Dashboard6";

const Distributors = () => {
  return (
    <>
      <UseCaseHeader
        title="Paleo for Distributors"
        subtitle="Usecase"
        text={
          <p className="lead">
            We help distributors by making trade more accessible, faster, more
            effective, and more secure worldwide. Our core focus is to provide
            the most effective trade infrastructure for businesses of all sizes.
        
            
          </p>
        }
        img={img33}
      />

<FourFeaturesBlocks></FourFeaturesBlocks>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="1"
        description="Share and update product catalog data with your partners instantly to create a unified catalog network."
        list={[
          {
            img: <Icon1 />,
            text: "Automatically share product data with partners",
          },
          {
            img: <Icon2 />,
            text: "Alert partners on product data changes",
          },
          {
            img: <Icon3 />,
            text: "Keep track of how your products are sold by merchants",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        bg="bg-soft-info"
        childOrder={0}
        subtitle="Combine and customize"
        heading="Your entire commerce business in one place"
        description="Paleo is the most powerful platform for merchants. With Paleo, you can purchase, sell, chat with your customers and suppliers, access powerful analytics on your business, sell b2c and b2b, keep track of inventory, create discount trade plans and keep track of your inventory all one single place. "
        list={[
          {
            img: <Icon1 />,
            text: "All-in-one",
          },
          {
            img: <Icon2 />,
            text: "Less routine – more creativity",
          },
          {
            img: <Icon3 />,
            text: "Style your workflow how you want to fit your brand",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always pay in your own currency"
        childOrder="0"
        description="Pay for multiple orders at once, using a single payment account in your local currency. Each supplier is then paid out in their currency."
        list={[
          {
            img: <Icon1 />,
            text: "Use a single account for all your purchases",
          },
          {
            img: <Icon2 />,
            text: "Always pay in your local currency",
          },
          {
            img: <Icon3 />,
            text: "Pay with credit cards, Paypal or wire transfers powered by Paleo Payments",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/pay-suppliers-in-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Create detailed discount programs"
        bg="bg-soft-info"
        childOrder="1"
        description="Create flexible, detailed trade discount programs for your partners based on order quantity, minimum cart value, and much more..."
        list={[
          {
            img: <Icon1 />,
            text: "Easily create discount trade plans",
          },
          {
            img: <Icon2 />,
            text: "Add any discount conditions that suits your business",
          },
          {
            img: <Icon3 />,
            text: "Auto-assign trade plans based on merchant type and other factors",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Global reach"
        heading="Create an on-site experience your customers will love"
        description="Work with partner merchants all over the world, hassle-free. Paleo brings lets you manage global commerce from a single place."
        list={[
          {
            img: <Icon1 />,
            text: "Sell and purchase globally",
          },
          {
            img: <Icon2 />,
            text: "Manage everything in one place",
          },
          {
            img: <Icon3 />,
            text: "Sell online in minutes",
          },
        ]}
      >
        <Dashboard3 />
      </ContentMarkupSection>

      

      <AlternativeCta />

      <Cta
        title="Get started with Paleo for free"
        text={`Try the most powerful solution for Brands`}
        link={{
          href: "https://accounts.paleo-store.com/auth/sign-up",
          target: "_blank",
          text: "Start free trial",
        }}
      />
    </>
  );
};

export default Distributors;
