import React from "react";

import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import ContentWithInvoice from "../../components/contents/ContentWithInvoice";
import { Icon1, Icon2, Icon3 } from "../../components/icons";
import Cta from "../../components/contents/Cta";
import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard6 from "../../components/cards/Dashboard6";
import Dashboard7 from "../../components/cards/Dashboard7";
import AlternativeCta from "../../components/cards/AlternativeCta";
import UseCaseHeader from "../../components/cards/UseCaseHeader";
import FourFeaturesBlocks from "../../components/contents/FourFeaturesBlocks";

import img from "../../assets/imgs/website-images/img37.jpg";

const Manufacturers = () => {
  return (
    <>
      <UseCaseHeader
        title="Paleo for Manufacturers"
        subtitle="Usecase"
        text={
          <p className="lead">
            We help manufacturers by making trade more accessible, faster, more
            effective, and more secure worldwide. Our core focus is to provide
            the most effective trade infrastructure for businesses of all sizes.
      

          </p>
        }
        img={img}
      />

      <FourFeaturesBlocks></FourFeaturesBlocks>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder="1"
        subtitle="Combine and customize"
        heading="Your entire commerce business in one place"
        description="Paleo allows you to keep trade of your product data in one place, manage your inventory, send purchase orders, create sales orders, create analytic dashboards, create “combined orders” to order from all your suppliers at once, create discount trade plans, what and share files with suppliers and customers instantly, and much more…"
        list={[
          {
            img: <Icon1 />,
            text: "Safely work with suppliers and manufacturers all over the world",
          },
          {
            img: <Icon2 />,
            text: "Keep your retailers updated, at all times",
          },
          {
            img: <Icon3 />,
            text: "One catalog for all your sales channels",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Start selling"
        heading="Always pay in your own currency"
        childOrder="0"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/pay-suppliers-in-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Always stay up to date with catalog data from your suppliers"
        childOrder="1"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        subtitle="Start selling"
        bg="bg-soft-info"
        heading="Create detailed discount programs"
        childOrder="0"
        description="Create flexible, detailed trade discount programs for your partners based on order quantity, minimum cart value, and much more..."
        list={[
          {
            img: <Icon1 />,
            text: "Easily create discount trade plans",
          },
          {
            img: <Icon2 />,
            text: "Add any discount conditions that suits your business",
          },
          {
            img: <Icon3 />,
            text: "Auto-assign trade plans based on merchant type and other factors",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentWithInvoice subHeader="Global commerce" />

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images, and much more. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>

      <AlternativeCta />

      <Cta
        title="Get started with Paleo for free"
        text={`Try the most powerful solution for manufacturers`}
        link={{
          href: "https://accounts.paleo-store.com/auth/sign-up",
          target: "_blank",
          text: "Start free trial",
        }}
      />
    </>
  );
};

export default Manufacturers;
