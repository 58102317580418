import React from "react";
import logo from '../assets/imgs/logo.svg';
import Logo from "../components/logo/Logo";
import { NavLink } from "react-router-dom";

const SimpleHeader = ({ children }) => {

  return (
    <header id="header" className="navbar navbar-height navbar-light navbar-absolute-top bg-white mt-2">
      <div className="container">
        <NavLink className="navbar-brand mx-auto" to="/" aria-label="Paleo">
          <Logo className="navbar-brand-logo" height="22px" />
        </NavLink>
      </div>
    </header>
  );
};

export default SimpleHeader;
