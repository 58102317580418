export default class CodaResponse {
  // Fetch Any table Data Based on tableId and docId
  // eslint-disable-next-line class-methods-use-this
  async fetchTable(tableId, docId) {
    const response = await fetch(
      `https://coda.io/apis/v1/docs/${docId}/tables/${tableId}/rows`,
      {
        headers: {
          Authorization: "Bearer 46c86bf3-b741-4148-a9a3-12ad371ac8e3",
        },
        redirect: "follow",
        mode: "cors",
      }
    );
    // eslint-disable-next-line no-return-await
    return await response.json();
  }
  async CodaDataContent(
    columnIndex,
    tableId = "grid-8L9Rx2sTXd",
    docId = "wthAeZ266D"
  ) {
    const data = await this.fetchTable(tableId, docId);
    return data;
  }

  async fetchJobsPosition(tableId = "grid-WKOmDFiW-K", docId = "uFOzhM8tYk") {
    const data = await this.fetchTable(tableId, docId);
    const tempData = [];
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i].values["c-Z7K0Fps-yp"] === true)
        tempData.push(data.items[i].values);
    }
    return tempData;
  }

  async fetchQuestionStatus() {
    const docId = "m70Jz9gtm7";
    const tableStatus = "grid-n5Z3haOTn3";
    const queryTable = "grid-dXQKjFtDE3";
    const tabledata = await this.fetchTable(tableStatus, docId);
    const queryData = await this.fetchTable(queryTable, docId);
    return [tabledata.items[0].values, queryData];
  }

  async PricePlanCoda(tableId = "grid-rKl7DF0uhq", docId = "IfewOJPcTh") {
    const data = await this.fetchTable(tableId, docId);
    const items = data.items;
    const res = [];
    if (items.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        if (!items[i].values["c-zV1IGod_ik"]) {
          res.push(items[i]);
        }
      }
      res.sort(
        (a, b) =>
          parseInt(a.values["c-fetgCYNusB"], 10) -
          parseInt(b.values["c-fetgCYNusB"], 10)
      );
    }
    return res;
  }

  async fetchIntegration(tableId = "grid-nFiCGVEiC2", docId = "DPijFpJjBy") {
    const data = await this.fetchTable(tableId, docId);
    const statusData = await this.fetchTable("grid-GmcSeYMNbB", docId);
    const statusCount = [];
    for (let i = 0; i < statusData.items.length; i++) {
      if (
        statusData.items[i].values["c-W3-Hhapqyx"] == 5 ||
        statusData.items[i].values["c-W3-Hhapqyx"] == 2 ||
        statusData.items[i].values["c-W3-Hhapqyx"] == 3
      ) {
        statusCount.push(statusData.items[i].values);
      }
    }
    return [data.items, statusCount];
  }

  async alternativeOrg(tableId = "grid-rl3ONfyVt8", docId = "n1fpFIjIWm") {
    const data = await this.fetchTable(tableId, docId);
    return data.items;
  }
}
