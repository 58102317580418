

import logo from "../../assets/imgs/paleo-logo-purple.svg";
import paleoCodeSvg from "../../assets/svg/custom/paleo-code-dark-blue.svg";

const products = [
    ["Product 1", 125, 5],
    ["Product 2", 50, 2],
    ["Product 3", 30, 20],
    ["Product 4", 17, 50]
];

const subtotal = products.reduce((a, b) => a + (b[1] * b[2]), 1);
const tax = Math.round((subtotal / 100) * 5); // 5% tax
const shippingCharge = 100;
const total = shippingCharge + subtotal + tax;

const data = [
    ["SUBTOTAL", `$${subtotal}`],
    ["TOTAL", `$${total}`],
    ["AMOUNT DUE", `$${(total - (total / 100) * 30)}`],
];



const MiniInvoice = () => {
    return (
        <div className="card fs-08">
            <div className="card-body">
                <div className="row mt-3">
                    <div className="col-md-6">
                        <img style={{ width: "80px" }} src={logo} />
                    </div>
                    <div className="col-md-6">
                        <div className="fw-bold">Invoice # 3493-24</div>
                        <div className="fs-08">Date : {new Date().toDateString()}</div>
                    </div>
                </div>


                <div className="row mt-3 fs-08">
                    <div className="col-md-6">
                        <div><span className="fw-bold text-gray-500">Seller :</span> Lululemon</div>
                        <div><span className="fw-bold text-gray-500">Buyer :</span> Target</div>
                        <div><span className="fw-bold text-gray-500">Payment method :</span> Paleo payments</div>
                    </div>

                    <div className="col-md-6">
                        <div>Address : 735 S Figueroa St</div>
                        <div>Los Angeles, CA </div>
                        <div>90017, USA</div>
                    </div>
                </div>

                <div className="table-responsive-sm mt-3">
                    <table className="table table-sm">
                        <thead>
                            <tr className="table-align-middle">
                                <th scope="col"><small>Serial</small></th>
                                <th scope="col"><small>Name</small></th>
                                <th scope="col"><small>Price</small></th>
                                <th scope="col"><small>Qty</small></th>
                                <th scope="col"><small>Total</small></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                products.map((e, i) => <tr key={i} className="js-form-check-row-select form-check-row-select table-align-middle">
                                    <td><small>{i + 1}</small></td>
                                    <td><small>{e[0]}</small></td>
                                    <td><small>${e[1]}</small></td>
                                    <td><small>{e[2]}</small></td>
                                    <td className="fw-bold"><small>${Math.round(e[1] * e[2])}</small></td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>

                <div className="d-flex justify-content-end">
                    <div>
                        <table>
                            <tbody>
                                {
                                    data.map((e, i) => <tr key={i}>
                                        <td className="fs-08 text-gray-500">{e[0]}</td>
                                        <td className="text-end fw-bold">{e[1]}</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="row mt-3 jcsb aic border rounded p-2 py-3">
                    <div className="col-md-8">
                        <strong className="mb-0 f08">Customs / Forwarding</strong>
                        <div className="fs-08">
                            Enter the Paleo order number on <strong>www.paleodirectory.com</strong> or scan the QR code to view all customs and forwarding data and information for this order.
                        </div>
                    </div>
                    <div className="col-md-3 text-end">
                        <img style={{ width: "65px" }} src={paleoCodeSvg} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MiniInvoice;
