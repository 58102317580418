import React from "react";
import { NavLink } from "react-router-dom";

import ocerror from '../assets/svg/illustrations/oc-error.svg';
import { ReactComponent as Com006 } from '../assets/vendor/duotone-icons/com/com006.svg';
import { ReactComponent as Gen005 } from '../assets/vendor/duotone-icons/gen/gen005.svg';

const CareersOverview = () => {
    return (
        <>
            <div className="bg-soft-primary-light">
                <div className="container content-space-1">
                    <div className="w-lg-75 mx-lg-auto">
                        <div className="mb-5">
                            <NavLink to="/careers"><i className="bi-chevron-left small me-1"></i> Back to Listings</NavLink>
                        </div>
                        <div className="row align-items-md-center">
                            <div className="col-md-6 mb-5 mb-md-0">
                                <h1 className="h3">Android Engineer</h1>
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">London, UK</li>
                                    <li className="list-inline-item">Business Strategy</li>
                                    <li className="list-inline-item">Full-Time</li>
                                </ul>
                            </div>

                            <div className="col-md-6">
                                <div className="d-md-flex justify-content-md-end">
                                    <a className="js-apply-tab btn btn-primary" href="#">Apply now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-bottom">
                <div className="container py-3">
                    <div className="w-lg-75 mx-lg-auto">
                        <ul className="nav nav-pills" id="careersTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" href="#one" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" role="tab" aria-controls="one" aria-selected="true">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon svg-icon-xs nav-icon text-primary mt-0">
                                            <Com006 />
                                        </span>
                                        Role overview
                                    </div>
                                </a>
                            </li>

                            <li className="nav-item" role="presentation">
                                <a className="nav-link" href="#two" id="two-tab" data-bs-toggle="tab" data-bs-target="#two" role="tab" aria-controls="two" aria-selected="false">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon svg-icon-xs nav-icon text-primary mt-0">
                                            <Gen005 />
                                        </span>
                                        Application
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container content-space-2">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="tab-content" id="careersTabContent">
                        <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                            <div className="mb-5">
                                <p>Space is building an artificial intelligence platform to transform the modern workforce. Our Ai platform allows team members to access information and streamline processes. We are integrated with a who's who of apps including Google Drive, Box, Salesforce, Zenefits, Office 365, Tableau, DOMO and 40+ others. We support new ideas, collaboration, and the ownership of our product throughout the organization.</p>
                                <p>Space engineers enjoy the opportunity and benefits of working at an early-stage artificial intelligence startup, but in an environment much more akin to a later stage company given the experience of the founding team. The culture at Space encourages innovation, independent problem solving, and collaboration as we continue to mature our product in the ever-changing work of AI.</p>
                                <p>Space is funded by the EQUITY.com incubator. As part of our dedication to the St. Louis community, Space is partnered with Create a Loop, a nonprofit focused on teaching kids computer science.</p>
                            </div>
                            <div className="mb-5">
                                <h6>We have:</h6>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item">Employer-paid health care</li>
                                    <li className="list-checked-item">Casual and diverse workplace</li>
                                    <li className="list-checked-item">Free snacks</li>
                                    <li className="list-checked-item">Unlimited Vacation policy</li>
                                    <li className="list-checked-item">Stock options</li>
                                </ul>
                            </div>

                            <div className="mb-5">
                                <h6>Requirements:</h6>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item">Energized to join a startup</li>
                                    <li className="list-checked-item">Excited to mentor more junior developers</li>
                                    <li className="list-checked-item">Good at problem selection, problem solving, and course correcting</li>
                                    <li className="list-checked-item">Focused on best practices</li>
                                    <li className="list-checked-item">Version coHighly pragmatic and collaborativentrol and Git workflow</li>
                                </ul>
                            </div>

                            <div className="mb-5">
                                <h6>You have:</h6>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item">3+ years of work experience</li>
                                    <li className="list-checked-item">Proficient in Python or PHP</li>
                                    <li className="list-checked-item">Understanding of OOP principles and practices</li>
                                    <li className="list-checked-item">MySQL database design skills</li>
                                    <li className="list-checked-item">Version control and Git workflow</li>
                                </ul>
                            </div>
                            <div className="mb-5">
                                <h6>Extra awesome:</h6>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item">Experience building REST APIs</li>
                                    <li className="list-checked-item">Experience with Microservices</li>
                                    <li className="list-checked-item">Deployment and CI (AWS, Kubernetes, Docker, Jenkins)</li>
                                    <li className="list-checked-item">JavaScript, React.js</li>
                                    <li className="list-checked-item">Solr, Elasticsearch</li>
                                    <li className="list-checked-item">Machine Learning</li>
                                </ul>
                            </div>

                            <p>Demonstrate your initiative, intuition and results from whatever you've been working on in the past. Tell us what APIs you love. Tell us what makes you tick. Show us what you've been up to and we will do the same!</p>

                            <div className="d-grid text-center mt-7">
                                <a className="js-apply-tab btn btn-primary btn-lg" href="#two-tab">Apply now</a>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <form>
                                <div className="mb-5">
                                    <h6>Submit your application</h6>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="resumeCVCareersForm" className="col-sm-4 col-form-label">Resume/CV</label>
                                    <div className="col-sm-8">
                                        <input type="file" className="js-file-attach form-control" id="resumeCVCareersForm" name="resumeCVCareersFormName" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="fullNameCareersForm" className="col-sm-4 col-form-label">Full name</label>
                                    <div className="col-sm-8">
                                        <input type="password" autoComplete="current-password" className="form-control" id="fullNameCareersForm" name="fullNameCareersFormName" placeholder="Jacob Williams" aria-label="Jacob Williams" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="emailCareersForm" className="col-sm-4 col-form-label">Email</label>
                                    <div className="col-sm-8">
                                        <input type="email" className="form-control" id="emailCareersForm" name="emailCareersFormName" placeholder="example@site.com" aria-label="example@site.com" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="phoneCareersForm" className="col-sm-4 col-form-label">Phone</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="phoneCareersForm" name="phoneCareersFormName" placeholder="+1 (062) 109-9222" aria-label="+1 (062) 109-9222" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="currentCompanyCareersForm" className="col-sm-4 col-form-label">Current company</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="currentCompanyCareersForm" name="currentCompanyCareersFormName" placeholder="Google" aria-label="Google" />
                                    </div>
                                </div>

                                <hr className="my-5 my-sm-10" />

                                <div className="mb-5">
                                    <h6>Links</h6>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="linkedinURLCareersForm" className="col-sm-4 col-form-label">LinkedIn URL</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="linkedinURLCareersForm" name="linkedinURLCareersFormName" placeholder="www.linkedin.com/jacob" aria-label="www.linkedin.com/jacob" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="twitterURLCareersForm" className="col-sm-4 col-form-label">Twitter URL</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="twitterURLCareersForm" name="twitterURLCareersFormName" placeholder="www.twitter.com/jacob" aria-label="www.twitter.com/jacob" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="githubURLCareersForm" className="col-sm-4 col-form-label">GitHub URL</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="githubURLCareersForm" name="githubURLCareersFormName" placeholder="www.github.com/jacob" aria-label="www.github.com/jacob" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="portfolioURLCareersForm" className="col-sm-4 col-form-label">Portfolio URL</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="portfolioURLCareersForm" name="portfolioURLCareersFormName" placeholder="www.mysite.com/jacob" aria-label="www.mysite.com/jacob" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="otherWebsiteCareersForm" className="col-sm-4 col-form-label">Other website</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="otherWebsiteCareersForm" name="otherWebsiteCareersFormName" placeholder="www.site.com" aria-label="www.site.com" />
                                    </div>
                                </div>

                                <hr className="my-5 my-sm-10" />
                                <div className="mb-5">
                                    <h6>Before sending your application, please let us know...</h6>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="desiredSalaryCareersForm" className="col-sm-4 col-form-label">Desired Salary</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="desiredSalaryCareersForm" name="desiredSalaryCareersFormName" placeholder="Type your response" aria-label="Type your response" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="availableStartDateCareersForm" className="col-sm-4 col-form-label">Available Start Date</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="availableStartDateCareersForm" name="availableStartDateCareersFormName" placeholder="Type your response" aria-label="Type your response" />
                                    </div>
                                </div>
                                <hr className="my-5 my-sm-10" />

                                <div className="mb-5">
                                    <h6>Additional information</h6>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="additionalInfoCareersForm" className="form-label visually-hidden">Additional information</label>
                                    <textarea className="form-control" name="additionalInfoCareersFormName" id="additionalInfoCareersForm" placeholder="Add a cover letter or anything else you want to share." aria-label="Add a cover letter or anything else you want to share." rows="5"></textarea>
                                </div>
                                <div className="d-grid text-center mt-7">
                                    <a className="btn btn-primary btn-lg" href="#">Submit application</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CareersOverview;