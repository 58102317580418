import React from "react";
import occhatting from "../assets/svg/illustrations/oc-chatting.svg";

import img from "../assets/imgs/website-images/img44.jpg";

import { ReactComponent as Gra011 } from "../assets/vendor/duotone-icons/gra/gra011.svg";
import { ReactComponent as Abs014 } from "../assets/vendor/duotone-icons/abs/abs014.svg";
import { ReactComponent as Gen020 } from "../assets/vendor/duotone-icons/gen/gen020.svg";
import { NavLink } from "react-router-dom";

const Investors = () => {
  return (
    <>
      <div className="shape-container bg-soft-info">
        <div className="container content-space-1 content-space-md-2">
          <div className="row justify-content-center align-items-lg-center mb-10">
            <div className="col-md-6 mb-7 mb-md-0">
              <div className="mb-7">
                <h6 className="text-cap">Our mission</h6>
                <h1 className="display-6 heading-color">
                  Creating the most effective infrastructure for commerce
                </h1>
                <p className="lead">
                  We help merchants by making trade more accessible, faster,
                  more effective, and more secure worldwide. Our core focus is
                  to provide the most effective trade infrastructure for
                  businesses of all sizes. 
                  <br></br>
                  <br></br>
                  Sign-up below to receive email updates on investor relation
                  topics.
                </p>
              </div>
              <a
                target="_blank"
                className="btn btn-primary"
                href="https://coda.io/form/VC-Newsletter-form_dZMdrI_efL3"
              >
                Investor newsletter{" "}
                <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>

            <div className="col-md-5 col-md-6">
              <img className="img-fluid rounded shadow" src={img} alt="Paleo" />
            </div>
          </div>

          {/* <div className="row">
            <div className="col col-md py-3">
              <img className="avatar avatar-xl avatar-4x3" src={capsuleprimary} alt="Logo" />
            </div>
            
            <div className="col col-md py-3">
              <img className="avatar avatar-xl avatar-4x3" src={fitbitprimary} alt="Logo" />
            </div>

            <div className="col col-md py-3">
              <img className="avatar avatar-xl avatar-4x3" src={vidadosprimary} alt="Logo" />
            </div>
            
            <div className="col col-md py-3">
              <img className="avatar avatar-xl avatar-4x3" src={mailchimpprimary} alt="Logo" />
            </div>

            <div className="col col-md py-3">
              <img className="avatar avatar-xl avatar-4x3" src={layarprimary} alt="Logo" />
            </div>
          </div> */}
        </div>
      </div>

      <div className="container content-space-1 content-space-md-3">
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3>Why Paleo?</h3>
          <p>
            Paleo is building a global infrastructure for trade. We are on a
            mission to connect the consumer goods supply chain by helping
            merchants trade most effectively.
          </p>
        </div>

        <div className="row">
          <div className="col-md-4 mb-5 mb-md-0">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3"></span>

              <h5>Mission driven</h5>
              <p>
                Paleo's mission is to make trade easier for everyone, our
                products are what helps us acheive it.
              </p>
            </div>
          </div>

          <div className="col-md-4 mb-5 mb-md-0">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3"></span>

              <h5>Globale scale</h5>
              <p>
                Paleo's business modal is built around merchant and trade of all
                sizes at all stages of the supply chain.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="me-md-3">
              <span className="svg-icon text-primary mb-3"></span>

              <h5>Growth opportunity</h5>
              <p>
                We constantly think of products and features that would benefit
                trade, merchants, and the Paleo infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investors;
