import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import SimpleFooter from "./SimpleFooter";
import SimpleHeader from "./SimpleHeader";

const EmptyLayout = ({ children }) => {

  return (
    <Fragment>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">

        <SimpleHeader />

        <main id="content" role="main">
          {children}
          <Outlet />
        </main>

        <SimpleFooter />
      </div>
    </Fragment>
  );
};

export default EmptyLayout;
