import { useState } from "react";
import App from "./App";
import { codaContext } from "./context/codaContext";
import { alternativeContext } from "./context/alternativeContext";
const Context = () => {
  const [codadata, setCodadata] = useState([]);
  const [alternativedata, setAlternativeData] = useState([]);

  const dispatchCodaEvent = (actionType, payload) => {
    switch (actionType) {
      case "ADD_DATA":
        setCodadata(payload.data);
        return;
      default:
        return;
    }
  };

  const dispatchAlternativeEvent = (actionType, payload) => {
    switch (actionType) {
      case "ADD_DATA":
        setAlternativeData(payload.data);
        return;
      default:
        return;
    }
  };
  return (
    <codaContext.Provider value={{ codadata, dispatchCodaEvent }}>
      <alternativeContext.Provider
        value={{ alternativedata, dispatchAlternativeEvent }}
      >
        <App />
      </alternativeContext.Provider>
    </codaContext.Provider>
  );
};

export default Context;
