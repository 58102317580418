import React from "react";

import ContentMarkupSection from "../../components/sections/ContentMarkupSection";
import SideBySideContent from "../../components/contents/SideBySideContent";
import Cta from "../../components/contents/Cta";
import Quote from "../../components/contents/Quote";
import UseCaseHeader from "../../components/cards/UseCaseHeader";
import Dashboard1 from "../../components/cards/Dashboard1";
import Dashboard4 from "../../components/cards/Dashboard4";
import Dashboard5 from "../../components/cards/Dashboard5";
import Dashboard7 from "../../components/cards/Dashboard7";
import AlternativeCta from "../../components/cards/AlternativeCta";
import { Icon1, Icon2, Icon3 } from "../../components/icons";
import FourFeaturesBlocks from "../../components/contents/FourFeaturesBlocks";
import FeatureDetails from "../../components/contents/FeatureDetails";

import img33 from "../../assets/imgs/website-images/img48.jpeg";
import img2 from "../../assets/imgs/website-images/analytics-dark.svg";
import img from "../../assets/imgs/website-images/img33.jpg";

const sidebyside1 = {
  subtitle: "Seamless experience",
  title: "A Powertool for Brands",
  text: "Paleo is the most powerful platform for merchants. With Paleo, you can purchase, sell, chat with your customers and suppliers, access powerful analytics on your business, sell B2C and B2B, keep track of inventory, create discount trade plans and keep track of your inventory all one single place.",
  id: "stepFeatures",
  link: {
    href: "#none",
    text: "Learn more",
  },
  list: [
    {
      title: "Start selling B2C and B2B in seconds",
     
      img: img2,
    },
    {
      title: "A powerful, no-setup e-commerce store",
   
      img: img,
    },
    {
      title: "Accept orders and payments instantly",

      img: img2,
    },
  ],
};

const sidebyside2 = { ...sidebyside1 };

const Brands = () => {
  return (
    <>
      <UseCaseHeader
        title="Paleo for Brands"
        subtitle="Usecase"
        text={
          <p className="lead">
            We help brands by making trade more accessible, faster, more
            effective, and more secure worldwide. Our core focus is to provide
            the most effective trade infrastructure for businesses of all sizes.

          </p>
        }
        img={img33}
      />

      <FourFeaturesBlocks></FourFeaturesBlocks>

      <ContentMarkupSection
        childOrder={0}
        bg="bg-soft-info"
        subtitle="UNIFIED PLATFORM"
        heading="Your entire commerce business in one place"
        description="Paleo allows you to keep trade of your product data in one place, manage your inventory, send purchase orders, create sales orders, create analytic dashboards, create combined orders to order from all your suppliers at once, create discount trade plans, and share files with suppliers and customers instantly."
        list={[
          {
            img: <Icon1 />,
            text: "Safely work with suppliers and manufacturers all over the world",
          },
          {
            img: <Icon2 />,
            text: "Keep your retailers updated, at all times",
          },
          {
            img: <Icon3 />,
            text: "One catalog for all your sales channels",
          },
        ]}
      >
        <Dashboard1 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Create detailed discount programs"
        childOrder="1"
        description="Create flexible, detailed trade discount programs for your partners based on order quantity, minimum cart value..."
        list={[
          {
            img: <Icon1 />,
            text: "Easily create discount trade plans",
          },
          {
            img: <Icon2 />,
            text: "Add any discount conditions that suit your business's robust, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Auto-assign trade plans based on merchant type and other factors",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/trade-plan.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        bg="bg-soft-info"
        childOrder={0}
        subtitle="One data source"
        heading="Manage e-commerce stores from a single data source"
        description="Paleo allows you to feed product and inventory data to multiple e-commerce and other applications."
        list={[
          {
            img: <Icon1 />,
            text: "Manage all your stores from a single catalog",
          },
          {
            img: <Icon2 />,
            text: "Track your inventory in one place",
          },
          {
            img: <Icon3 />,
            text: "Update your retailers instantly",
          },
        ]}
      >
        <Dashboard5 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Always connected"
        heading="Keep your partners in the loop"
        childOrder="1"
        description="Share and update product catalog data with your partners instantly to create a unified catalog network."
        list={[
          {
            img: <Icon1 />,
            text: "Automatically share product data with partners",
          },
          {
            img: <Icon2 />,
            text: "Alert partners on product data changes",
          },
          {
            img: <Icon3 />,
            text: "Keep track of how your products are sold by merchants",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/data-up-to-date.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection

        bg="bg-soft-info"
        subtitle="CATALOGS"
        heading="Scalable, flexible product data"
        childOrder="0"
        description="Product data lives inside of the powerful Paleo universal database. You can create custom layouts for your product catalogs depending on your industry and products, and add your own data columns for more flexibility."
        list={[
          {
            img: <Icon1 />,
            text: "Add any data attributes to your products",
          },
          {
            img: <Icon2 />,
            text: "Easily share your catalog data with your partners",
          },
          {
            img: <Icon3 />,
            text: "Sell products B2B and B2C",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      {/* <div>
          <FeatureDetails
            title="Less overhead, more collaboration"
            lists={[
              {
                title: "Feature 1",
                list: [
                  "Corporate-based Business",
                  "Creative Services",
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                ],
              },
              {
                title: "Feature 2",
                list: [
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                  "Creative Services",
                  "New Business Innovation",
                ],
              },
              {
                title: "Feature 3",
                list: [
                  "Creative Services",
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                ],
              },
              {
                title: "Feature 4",
                list: [
                  "New Business Innovation",
                  "Online E-commerce",
                  "Real Estate",
                  "Corporate-based Business",
                  "Creative Services",
                ],
              },
            ]}
          />
        </div> */}

      <SideBySideContent {...sidebyside1} dark={true} img={img2} />

      <ContentMarkupSection
        childOrder={0}
        subtitle="Messaging"
        heading="Direct messaging and file sharing"
        description="Communicate with partners and merchants directly from your dashboard. Paleo’s powerful built-in messaging allows you to talk to members from other organizations in separate group channels, share files and images. Forget having to look through countless emails with your customers or suppliers; Paleo keeps it all in a single place and creates a unique chat for every order. "
        list={[
          {
            img: <Icon1 />,
            text: "Chat directly with all your suppliers and customers",
          },
          {
            img: <Icon2 />,
            text: "Share files, images and more directly on the chat",
          },
          {
            img: <Icon3 />,
            text: "Paleo creates a unique chat for each order to keep things organized",
          },
        ]}
      >
        <Dashboard7 />
      </ContentMarkupSection>
{/* 
      <Quote
        quote="Truly great template and great costumer support. Very precise documentation with many features well explained."
        author={{
          name: "Matt Powers",
          designation: "Director of Sales | Forbes",
        }}
      /> */}

      <AlternativeCta bg="bg-soft-info" />
    </>
  );
};

export default Brands;
