import MiniInvoice from "../cards/MiniInvoice";

const ContentWithInvoice = ({bg, subHeader}) => {
    return (
        <div className={bg}>
        <div className={`container content-space-1 content-space-t-md-3 `}>
            
            <div className="row align-items-md-center">
                <div className="col-md-12 col-sm-12 col-lg-7  order-md-2 d-md-block mb-10 mb-md-0">
                    <div className="row">

                        {/* End Col */}
                        <div className="col-md-12 col-sm-12 col-lg-9">
                            <MiniInvoice />
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>
                {/* End Col */}
                <div className="col-md-12 col-sm-12 col-lg-5">
                    <div className="ps-md-5">
                        {/* Heading */}
                        <div className="mb-7">
                        <span class="text-cap">{subHeader}</span>
                            <h3>Unmatched forwarding and fulfillment</h3>
                            <p>Orders placed on Paleo are automatically available on the Paleo public directory, easily accessible by forwarding and logistic companies, customs agencies, and more. </p>
                            <p>You can move orders far more efficiently, as you can skip having to share packing lists, commercial invoices, update your data files, lookup HS codes, and more. Each involved party has direct access to this information at all times.</p>
                        </div>
                        {/* End Heading */}

                    </div>
                </div>
                {/* End Col */}
            </div>
            {/* End Row */}
        </div>
        </div>
    );
};

export default ContentWithInvoice;
