import usePalette from "../../hooks/usePalette";

import Line from "../../components/charts/Line";
import LineStrong from "../../components/charts/LineStrong";
import BarSingle from "../../components/charts/BarSingle";
import Doughnut from "../../components/charts/Doughnut";

// lineChartData
const lineChartData = {
  colors: ["#377dff", "#00c9db"],
  legends: ["Sales", "Orders"],
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Sales",
      fill: true,
      backgroundColor: "transparent",
      borderColor: "#377dff",
      borderWidth: 1.5,
      pointRadius: 0,
      data: [
        2115, 1562, 1584, 1892, 1487, 2223, 2966, 2448, 2905, 3838, 2917, 3327,
      ],
    },
    {
      label: "Orders",
      fill: true,
      backgroundColor: "transparent",
      borderColor: "#00c9db",
      borderWidth: 1.5,
      pointRadius: 0,
      data: [958, 724, 629, 883, 915, 1214, 1476, 1212, 1554, 2128, 1466, 1827],
    },
  ],
};
// lineChartData

// lineGradientData
const lineGradientData = {
  colors: ["#377dff", "#00c9db"],
  legends: ["Sales", "Orders"],
  labels: [
    "Feb",
    "Jan",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      backgroundColor: ["rgba(55, 125, 255, .3)", "rgba(255, 255, 255, .0)"],
      borderColor: "#377dff",
      borderWidth: 2,
      pointRadius: 0,
      hoverBorderColor: "#377dff",
      pointBackgroundColor: "#377dff",
      pointBorderColor: "#fff",
      pointHoverRadius: 0,
      data: [18, 51, 60, 38, 88, 50, 40, 52, 88, 80, 60, 70],
    },
    {
      backgroundColor: ["rgba(0, 201, 219, .5)", "rgba(255, 255, 255, .0)"],
      borderColor: "#00c9db",
      borderWidth: 2,
      pointRadius: 0,
      hoverBorderColor: "#00c9db",
      pointBackgroundColor: "#00c9db",
      pointBorderColor: "#fff",
      pointHoverRadius: 0,
      data: [77, 40, 50, 49, 27, 38, 60, 42, 50, 29, 42, 27],
    },
  ],
};
// lineGradientData

// lineStrongData
const lineStrongData = {
  colors: ["#377dff"],
  legends: ["Sales"],
  labels: [
    "6:00 PM",
    "6:00 PM",
    "6:01 PM",
    "6:01 PM",
    "6:02 PM",
    "6:02 PM",
    "6:03 PM",
    "6:03 PM",
    "6:04 PM",
    "6:04 PM",
    "6:05 PM",
    "6:05 PM",
    "6:06 PM",
    "6:06 PM",
    "6:07 PM",
    "6:07 PM",
    "6:08 PM",
    "6:08 PM",
    "6:09 PM",
    "6:09 PM",
    "6:10 PM",
    "6:10 PM",
    "6:11 PM",
    "6:11 PM",
    "6:12 PM",
    "6:12 PM",
    "6:13 PM",
    "6:13 PM",
    "6:14 PM",
    "6:14 PM",
    "6:15 PM",
    "6:15 PM",
    "6:16 PM",
    "6:16 PM",
    "6:17 PM",
    "6:17 PM",
    "6:18 PM",
    "6:18 PM",
    "6:19 PM",
    "6:19 PM",
    "6:20 PM",
    "6:20 PM",
    "6:21 PM",
    "6:21 PM",
    "6:22 PM",
    "6:22 PM",
    "6:23 PM",
    "6:23 PM",
    "6:24 PM",
    "6:24 PM",
    "6:25 PM",
    "6:25 PM",
    "6:26 PM",
    "6:26 PM",
    "6:27 PM",
    "6:27 PM",
    "6:28 PM",
    "6:28 PM",
    "6:29 PM",
    "6:29 PM",
    "6:30 PM",
    "6:30 PM",
    "6:31 PM",
    "6:31 PM",
    "6:32 PM",
    "6:32 PM",
    "6:33 PM",
    "6:33 PM",
    "6:34 PM",
    "6:34 PM",
    "6:35 PM",
    "6:35 PM",
    "6:36 PM",
    "6:36 PM",
    "6:37 PM",
    "6:37 PM",
    "6:38 PM",
    "6:38 PM",
    "6:39 PM",
    "6:39 PM",
    "6:40 PM",
    "6:40 PM",
    "6:41 PM",
    "6:41 PM",
    "6:42 PM",
    "6:42 PM",
    "6:43 PM",
    "6:43 PM",
    "6:44 PM",
    "6:44 PM",
    "6:45 PM",
    "6:45 PM",
    "6:46 PM",
    "6:46 PM",
    "6:47 PM",
    "6:47 PM",
    "6:48 PM",
    "6:48 PM",
    "6:49 PM",
    "6:49 PM",
    "6:50 PM",
    "6:50 PM",
    "6:51 PM",
    "6:51 PM",
    "6:52 PM",
    "6:52 PM",
    "6:53 PM",
    "6:53 PM",
    "6:54 PM",
    "6:54 PM",
    "6:55 PM",
    "6:55 PM",
    "6:56 PM",
    "6:56 PM",
    "6:57 PM",
    "6:57 PM",
    "6:58 PM",
    "6:58 PM",
    "6:59 PM",
    "6:59 PM",
  ],
  datasets: [
    {
      label: "Sales",
      fill: true,
      backgroundColor: "transparent",
      borderColor: "#377dff",
      borderWidth: 1.5,
      pointRadius: 0,
      lineTension: 0,
      data: [
        8516, 8520, 8547, 8575, 8588, 8604, 8601, 8595, 8617, 8612, 8607, 8632,
        8636, 8641, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8662, 8670, 8667,
        8658, 8660, 8657, 8648, 8645, 8647, 8652, 8637, 8647, 8640, 8667, 8658,
        8660, 8713, 8703, 8705, 8747, 8620, 8635, 8680, 8675, 8667, 8620, 8645,
        8645, 8625, 8635, 8645, 8665, 8660, 8670, 8640, 8650, 8640, 8635, 8645,
        8695, 8703, 8705, 8640, 8650, 8640, 8620, 8610, 8640, 8630, 8610, 8620,
        8600, 8590, 8610, 8620, 8600, 8610, 8605, 8610, 8660, 8665, 8664, 8640,
        8645, 8650, 8610, 8620, 8630, 8670, 8675, 8674, 8660, 8665, 8650, 8610,
        8612, 8607, 8632, 8636, 8641, 8638, 8641, 8638, 8641, 8624, 8633, 8643,
        8630, 8646, 8662, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8632, 8636,
      ],
    },
  ],
};
// lineStrongData

// barDoubleData
const barDoubleData = {
  colors: ["#377dff", "#00c9db"],
  legends: ["Sales", "Orders"],
  labels: [
    "May 1",
    "May 2",
    "May 3",
    "May 4",
    "May 5",
    "May 6",
    "May 7",
    "May 8",
    "May 9",
    "May 10",
  ],
  datasets: [
    {
      data: [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
      backgroundColor: "#377dff",
      borderColor: "#377dff",
    },
    {
      data: [150, 230, 382, 204, 169, 290, 300, 100, 300, 225, 120],
      backgroundColor: "#e7eaf3",
      borderColor: "#e7eaf3",
    },
  ],
};
// barDoubleData

export default function TestCharts() {
  const palette = usePalette();

  // doughnutData
  const doughnutData = {
    colors: ["#377dff", "#00c9db", "#e7eaf3"],
    labels: ["Marketing", "Bills", "other"],
    datasets: [
      {
        data: [45, 25, 30],
        backgroundColor: ["#377dff", "#00c9db", "#e7eaf3"],
        borderColor: palette.white,
        borderWidth: 5,
        hoverBorderColor: palette["gray-200"],
      },
    ],
  };
  // doughnutData

  return (
    <>
      <div className="container">
        <div class="container content-space-1 content-space-md-3">
          <div class="w-lg-65 text-center mx-lg-auto mb-7">
            <h3>Charts</h3>
          </div>
          <div class="row mb-5 mb-sm-5">
            <div class="col-sm-12 col-lg-6 mb-3 mb-lg-5">
              <Line data={lineChartData} showLegends={false} />
            </div>

            <div class="col-sm-12 col-lg-6 mb-3 mb-lg-5">
              <Line
                data={lineGradientData}
                title="$7,431.14 USD"
                subTitle="25.3%"
                showLegends={false}
              />
            </div>

            <div class="col-sm-12 col-lg-6 mb-3 mb-lg-5">
              <LineStrong
                data={lineStrongData}
                title="$7,431.14 USD"
                subTitle="25.3%"
                showLegends={true}
              />
            </div>

            <div class="col-sm-12 col-lg-6 mb-3 mb-lg-5">
              <div className="card">
                <BarSingle
                  data={barDoubleData}
                  title="$7,431.14 USD"
                  subTitle="25.3%"
                  showLegends={true}
                />
              </div>
            </div>

            <div class="col-sm-12 col-lg-6 mb-3 mb-lg-5">
              <div className="card">
                <Doughnut
                  data={doughnutData}
                  title="$7,431.14 USD"
                  subTitle="25.3%"
                  half={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
