import React from "react";

import ImageBrowserBlock from "../../components/sections/ImageBrowserBlock";
import ContentMarkupSection from "../../components/sections/ContentMarkupSection";

import { Icon1, Icon2, Icon3 } from "../../components/icons";

import Dashboard6 from "../../components/cards/Dashboard6";

const Product = () => {
  return (
    <>
    <>
     <div className="bg-soft-info">
  <div className="container content-space-1">
    <div className="w-lg-75 mx-lg-auto">

      <div className="row align-items-md-center">
        <div className="col-md-6 mb-5 mb-md-0">
          <h1 className="h3">Sell</h1>
          <ul className="list-inline list-separator">
            <li className="list-inline-item">The most efficient sales platform for global merchants to sell anywhere</li>
          </ul>
        </div>
        {/* End Col */}
        <div className="col-md-6">
          <div className="d-md-flex justify-content-md-end">
          <a className="js-apply-tab btn btn-primary" target={"_blank"} href="https://accounts.paleo-store.com/auth/sign-up">Get started for free</a>
          </div>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
  </div>
</div>

<ContentMarkupSection
        childOrder={1}
        subtitle="Start selling"
        heading="All your sales orders in one place"
        description="Paleo allows you to sell your products efficiently anywhere in the world. Sell B2B and B2C at any time, accept custom order requests, set up detailed discount programs, etc. Paleo allows you to sell your products efficiently anywhere in the world. Sell B2B and B2C at any time, accept custom order requests, set up detailed discount programs, and much more."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <ImageBrowserBlock imgUrl={"../SVG-dashboard/sales-white.svg"} />
      </ContentMarkupSection>

      <div className="bg-soft-info">
        {/* Icon Blocks */}
<div className="container content-space-1">

  <div className="row">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Sell B2B and B2C</h5>
        </span>
        <p>Sell your products to customers worldwide in a matter of minutes, no matter if they are on Paleo or not.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Full control over your sales</h5>
        </span>
        <p>Set discount plans for partners, set up sales logic, and much more.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

  <div className="row mt-5">
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Sell privately and publically</h5>
        </span>
        <p>Sell privately through your merchant dashboard or allow customers to shop on your Paleo online store.</p>
   
      </div>
    </div>
    {/* End Col */}
    <div className="col-md-6 mb-5 mb-md-0">
      <div className="me-md-6">
        <span className="svg-icon svg-icon-lg text-primary mb-3">
        <h5>Powerful analytical data</h5>
        </span>
        <p>Access robust sales data or create your custom chart dashboards.</p>
   
      </div>
    </div>
    {/* End Col */}

  </div>
  {/* End Row */}

</div>
{/* End Icon Blocks */}
      </div>

      

      <ContentMarkupSection
        childOrder="1"
        subtitle="Start selling"
        heading="A powerful e-commerce store"
        description="Start selling in minutes with your powerful, easy-to-use e-commerce store. You can sell both B2B and B2C without additional apps or setups."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <Dashboard6 />
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        bg="bg-soft-info"
        heading="Full control over your product data"
        childOrder="1"
        description="Product data lives inside of the powerful Paleo universal database. You can create custom layouts for your product catalogs depending on your industry and products, and add your own data columns for more flexibility."
        list={[
          {
            img: <Icon1 />,
            text: "Start selling B2C and B2B in seconds",
          },
          {
            img: <Icon2 />,
            text: "A powerful, no-setup e-commerce store",
          },
          {
            img: <Icon3 />,
            text: "Accept orders and payments instantly",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../SVG-Graphics/set-order-qt.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

      <ContentMarkupSection
        childOrder={0}
        subtitle="Start selling"
        heading="Get paid in your local currency"
        childOrder="0"
        description="Allow customers to pay in their currency and get paid in yours—no matter where they are."
        list={[
          {
            img: <Icon1 />,
            text: "Allow customers to pay in their currency",
          },
          {
            img: <Icon2 />,
            text: "Get paid in your local currency",
          },
          {
            img: <Icon3 />,
            text: "All your payouts, in one place",
          },
        ]}
      >
        <div>
          <img
            className="device-browser-img"
            src={"../../SVG-Graphics/get-paid-single-currency.svg"}
            alt="Image Description"
          />
        </div>
      </ContentMarkupSection>

    </>

    </>
  );
};

export default Product;
