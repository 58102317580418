import MiniDashboard from "./MiniDashboard";

import Line from "../charts/Line";
import LineStrong from "../charts/LineStrong";
import Doughnut from "../charts/Doughnut";



// miniChart
const miniChart = {
    colors: ["#377dff"],
    legends: ["Sales"],
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 8633, 8643, 8630, 8646, 8662, 8670, 8667, 8658, 8660, 8657, 8648, 8645, 8647, 8652, 8637, 8647, 8640, 8667, 8658, 8660, 8713, 8703, 8705, 8747, 8620, 8635, 8680, 8675, 8667, 8620, 8645, 8645, 8625, 8635, 8645, 8665, 8660],
    datasets: [
        {
            label: "Sales",
            fill: true,
            backgroundColor: "transparent",
            borderColor: "#377dff",
            "borderWidth": 1.5,
            "pointRadius": 0,
            "lineTension": 0,
            data: [8516, 8550, 8547, 8575, 8598, 8644, 8601, 8595, 8617, 8612, 8607, 8662, 8636, 8641, 8638, 8641, 8624, 8633, 8643, 8630, 8646, 8662, 8670, 8667, 8658, 8660, 8657, 8648, 8645, 8647, 8652, 8637, 8647, 8640, 8667, 8658, 8660, 8713, 8703, 8705, 8747, 8620, 8635, 8680, 8675, 8667, 8620, 8645, 8645, 8625, 8635, 8645, 8665, 8660],
        }
    ],
};
// miniChart


// lineChartData
const lineChartData = {
    colors: ["#377dff", "#00c9db"],
    legends: ["Sales", "Orders"],
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",],
    datasets: [
        {
            label: "Sales",
            fill: true,
            backgroundColor: "transparent",
            borderColor: "#377dff",
            "borderWidth": 1.5,
            "pointRadius": 0,
            data: [2115, 1562, 1584, 1892, 1487, 2223, 2966, 2448, 2905, 3838, 2917, 3327,],
        },
        {
            label: "Orders",
            fill: true,
            backgroundColor: "transparent",
            borderColor: "#00c9db",
            "borderWidth": 1.5,
            "pointRadius": 0,
            data: [958, 724, 629, 883, 915, 1214, 1476, 1212, 1554, 2128, 1466, 1827,],
        },
    ],
};
// lineChartData


// doughnutData
const doughnutData = {
    colors: ["#377dff", "#00c9db", "#e7eaf3"],
    "labels": ["Sales", "Purchases", "other"],
    "datasets": [{
        "data": [45, 25, 30],
        "backgroundColor": ["#377dff", "#00c9db", "#e7eaf3"],
        "borderColor": "#fff",
        "borderWidth": 5,
        "hoverBorderColor": "#eee"
    }]
};
// doughnutData


const Dashboard2 = ({ mini = false }) => {
    return (
        <MiniDashboard>
            <div className="row mt-3 gx-2">

                {/*  left side panel */}
                <div className="col-md-6">
                    <div className="border rounded p-2">
                        <div className="text-gray-500 fs-09 fw-bold">TOTAL SALES</div>
                        <div className="fs-5 fw-bold">$26,045</div>
                        <Line data={lineChartData} mini={true} height="80px" />
                    </div>
                    <div className="border rounded mt-2 p-2">
                        {
                            !mini && <>
                                <div className="text-gray-500 fs-09 fw-bold">TOTAL PURCHASES</div>
                                <div className="fs-5 fw-bold">$15,045</div>
                            </>
                        }
                        <LineStrong data={miniChart} mini={true} height="80px" />
                    </div>
                </div>
                {/* END left side panel */}

                {/* right side panel */}
                <div className="col-md-6 mt-2 mt-md-0">
                    <div className="border p-2 rounded">
                        <div className="p-4">
                            <Doughnut data={doughnutData} height={mini ? "105px" : "156px"} />
                        </div>
                        <div className="mt-2 p-2">
                            {
                                doughnutData.labels.map((e, i) => <div key={i} className="d-flex aic">
                                    <div className="ic10 me-2" style={{ background: doughnutData.colors[i] }}></div>
                                    <div>{e}</div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
                {/* right side panel */}

            </div>
        </MiniDashboard>
    );
};

export default Dashboard2;
